import React, {FC, useCallback, useRef, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {useAppDispatch} from "../../../hooks/redux";
import ReactCrop, {Crop, PixelCrop} from "react-image-crop";
import userDummayImage from "../../../assets/images/users/user-dummy-img.jpg";

interface IEditOtherDescription {
    portfolio: any,
    setPortfolio(value: any): any,
    selectPagePortfolio: any,
    selectModalCropPhoto: boolean,
    setSelectModalCropPhoto(value: boolean): any,
    photoPathList: any,
    setPhotoListBase64(value: any): any,
    pathPhoto: any,
    setDownloadPhoto(value: any): any,
    downloadPhoto64: any,
    setDownloadPhoto64(value: any): any,
    cropPath: any,
    setCropPath(value: any): any,
    indexPhoto?: number,
    relationsParties: any,
    croppedImage: any,
}

const ModalCropPhoto: FC<IEditOtherDescription> = ({ portfolio, setPortfolio, selectModalCropPhoto, setSelectModalCropPhoto, selectPagePortfolio,
    photoPathList, setPhotoListBase64, pathPhoto, setDownloadPhoto, downloadPhoto64, setDownloadPhoto64, cropPath, setCropPath, croppedImage, indexPhoto = 0, relationsParties}) => {
    const dispatch = useAppDispatch();

    const [crop, setCrop] = useState<Crop>();
    const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);

    const onCropComplete = useCallback((newCrop: PixelCrop) => {
        if (imageRef && newCrop.width && newCrop.height) {
            const canvas = document.createElement('canvas');
            const scaleX = imageRef.naturalWidth / imageRef.width;
            const scaleY = imageRef.naturalHeight / imageRef.height;
            canvas.width = newCrop.width;
            canvas.height = newCrop.height;
            const ctx = canvas.getContext('2d');

            if (ctx) {
                ctx.drawImage(
                    imageRef,
                    newCrop.x * scaleX,
                    newCrop.y * scaleY,
                    newCrop.width * scaleX,
                    newCrop.height * scaleY,
                    0,
                    0,
                    newCrop.width,
                    newCrop.height
                );
            }
            canvas.toBlob((blob) => {
                if (blob) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setPhotoListBase64((prevArrList: any) => {
                            const newArrList = [...prevArrList];
                            newArrList[indexPhoto] = reader.result;
                            return newArrList;
                        });
                        setDownloadPhoto64(reader.result);
                        setCropPath(reader.result as string);
                    };
                    reader.readAsDataURL(blob);
                    setDownloadPhoto(blob);
                }
            });
        }
    }, [imageRef]);

    const onImageLoad = useCallback(
        (event: React.SyntheticEvent<HTMLImageElement>) => {
            const img = event.currentTarget as HTMLImageElement;
            setImageRef(img);

            const initialCrop = {
                unit: '%',
                width: 50,
                aspect: relationsParties,
            };

            const canvas = document.createElement('canvas');
            const scaleX = img.naturalWidth / img.width;
            const scaleY = img.naturalHeight / img.height;
            const x = (img.width - img.width * initialCrop.width / 100) / 2;
            const y = (img.height - img.height * initialCrop.width / (100 * initialCrop.aspect)) / 2;
            canvas.width = img.naturalWidth * initialCrop.width / 100;
            canvas.height = canvas.width / initialCrop.aspect;

            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.drawImage(
                    img,
                    x * scaleX,
                    y * scaleY,
                    canvas.width,
                    canvas.height,
                    0,
                    0,
                    canvas.width,
                    canvas.height
                );
            }

            canvas.toBlob((blob: Blob |null) => {
                if (blob) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setPhotoListBase64((prevArrList: any) => {
                            const newArrList = [...prevArrList];
                            newArrList[indexPhoto] = reader.result;
                            return newArrList;
                        });
                        setDownloadPhoto64(reader.result);
                        setCropPath(reader.result as string);
                    };
                    reader.readAsDataURL(blob);

                    setDownloadPhoto(blob);
                }
            });
        },
        []
    );

    return (
        <>
            <Modal id="createProjectModal" isOpen={selectModalCropPhoto}
                   toggle={() => setSelectModalCropPhoto(!selectModalCropPhoto)}
                   modalClassName="zoomIn"  size="lg" tabIndex={-1} centered >
                <ModalHeader toggle={() => setSelectModalCropPhoto(!selectModalCropPhoto)}
                             className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Обрезать фото
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            header: ''
                        }}
                        onSubmit={(values) => {
                            setSelectModalCropPhoto(false);
                        }}
                    >
                        {
                            ({ errors, touched, setFieldValue }) => (
                                <Form>
                                    <Row className="my-2">
                                        {downloadPhoto64 && (
                                            <Col>
                                                <label className="w-100">Выберите область для обрезки:
                                                    <ReactCrop
                                                        crop={crop}
                                                        aspect={relationsParties}
                                                        onChange={(newCrop: any) => {
                                                            setCrop(newCrop)
                                                        }}
                                                        onComplete={onCropComplete}
                                                    >
                                                        <img src={pathPhoto} alt="Crop" onLoad={onImageLoad} />
                                                    </ReactCrop>
                                                </label>
                                            </Col>
                                        )}
                                    </Row>
                                    <div className="hstack gap-2">
                                        <SubmitWithLoading text={'Обрезать'} />
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    );
};

export default ModalCropPhoto;
