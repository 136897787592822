import React, {MouseEvent, useEffect, useState} from 'react';
import {Col, Container, Row} from "reactstrap";
import AxiosClient from "../../api/AxiosClient";
import CardGameTeacher from "./CardGameTeacher";
import {Link} from "react-router-dom";

const ListGames = () => {
    const [games, setGames] = useState<{ id: number, name: string, file: string | null }[]>([]);
    useEffect(() => {
        AxiosClient.get('/game')
            .then((r) => setGames(r.data))
    }, []);

    const removeGame = (event: MouseEvent<HTMLElement, globalThis.MouseEvent>, id: number) => {
        event.preventDefault()
        AxiosClient.post(`/game/delete/${id}`)
            .then((r) => setGames((games) => games.filter((game) => game.id !== id)))
    }

    return (
        <div className="page-content">
            <Container fluid>
                <div>
                    <Row>
                        <Col>
                            <Link to={'/games/create'} className="btn btn-primary">Создать игру</Link>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        {
                            games.map(el => (
                                <Col xxl={3} xl={4} lg={6} key={el.id}>
                                    <CardGameTeacher id={el.id} name={el.name} path={el.file} removeGame={(event, id) => removeGame(event, id)}/>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            </Container>
        </div>
    );
};

export default ListGames;
