import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import AxiosClient from "../../api/AxiosClient";
import bg from '../../assetsGame/icons/bgStartEnd.jpg'
import btn from '../../assetsGame/b_3.png'
import {Col, Row} from "reactstrap";
import {IShot} from "../../models/game/IShot";
import {IGame} from "../../models/game/IGame";
import Shot from "./play/Shot";
import {useStateContext} from "../../hooks/useStateContext";
import './style.scss'
import cn from "classnames";
const OpenGame = () => {
    const {id} = useParams()
    const {user} = useStateContext();
    const [start, setStart] = useState(true);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [end, setEnd] = useState(false);
    const [game, setGame] = useState<IGame | null>(null);
    const [shots, setShots] = useState<IShot[]>([]);
    const [shotIndex, setShotIndex] = useState(-1);
    const [nextSelectShot, setNextSelectShot] = useState<IShot | null>(null);
    const [selectShot, setSelectShot] = useState<IShot | null>(null);
    const [result, setResult] = useState({
        all: 0,
        true: 0,
        error: 0
    });

    useEffect(() => {
        id &&
        AxiosClient.get<IGame>(`/game/game/${id}`)
            .then((r) => {
                setGame(r.data)
                setShots(r.data.shots)
                setResult((prevState) => ({
                    ...prevState,
                    all: r.data.shots.length
                }))
            })
    }, [id]);

    const nextShot = () => {
        if (shotIndex === shots.length - 1){
            setSelectShot(null)
            setEnd(true)
        } else {
            setShotIndex(prevState => prevState + 1)
            setSelectShot(shots[shotIndex + 1])
            setNextSelectShot(shots[shotIndex + 2] && null)
        }
    }

    const onChangeTrue = () => {
        setResult((prevState) => ({
            ...prevState,
            true: prevState.true + 1
        }))
    }

    const onChangeError = () => {
        setResult((prevState) => ({
            ...prevState,
            error: prevState.error + 1
        }))
    }

    return (
        <>
            <div className={cn({normal: !isFullScreen, fullScreen: isFullScreen})} id="window">
                {
                    start &&
                    <div className="h-100 w-100">
                        <div className="position-absolute end-0 m-2 cursor-pointer" style={{zIndex: 10}} onClick={() => {
                            setIsFullScreen((prevState) => {
                                if (!prevState) {
                                    document.body.requestFullscreen()
                                    return !prevState
                                }
                                document.exitFullscreen()
                                return !prevState
                            })
                        }}>
                            <i className="bx bx-fullscreen fs-1 text-black fw-bold"></i>
                        </div>
                        <div className="h-100 w-100 d-flex justify-content-center align-items-center position-relative"
                             style={{backgroundImage: `url(${bg})`, backgroundSize: '100% 100%', backgroundPosition: 'center'}}>
                            <Col xs={7} sm={3} className="position-relative cursor-pointer" onClick={() => {
                                setStart(false)
                                nextShot()
                            }}>
                                <img src={btn} className="w-100" style={{position: 'relative'}} />
                                <div className="position-absolute top-50 start-50 translate-middle text-center">
                                    <h1 className="m-0">Начать</h1>
                                </div>
                            </Col>
                        </div>
                    </div>
                }
                {
                    selectShot && game &&
                    <Shot nextSelectShot={nextSelectShot} height={game?.height} width={game.width} shot={selectShot} nextShot={nextShot} onChangeError={onChangeError} onChangeTrue={onChangeTrue}/>
                }
                {
                    end &&
                    <div className="h-100 w-100 gap-5 justify-content-center align-items-end position-relative"
                         style={{backgroundImage: `url(${bg})`, backgroundSize: '100% 100%', backgroundPosition: 'center'}}>
                        <div className="position-absolute end-0 m-2 cursor-pointer" style={{zIndex: 10}} onClick={() => {
                            setIsFullScreen((prevState) => {
                                if (!prevState) {
                                    document.body.requestFullscreen()
                                    return !prevState
                                }
                                document.exitFullscreen()
                                return !prevState
                            })
                        }}>
                            <i className="bx bx-fullscreen fs-1 text-black fw-bold"></i>
                        </div>
                        <Row className="mx-3 justify-content-end">
                            <Col>
                                <div className="mt-2 fs-2">
                                    <h3 className="text-center">Результаты</h3>
                                    <div>
                                        <h4>Всего вопросов: {result.all}</h4>
                                    </div>
                                    <div>
                                        <h4>Всего правильных ответов: {result.true}</h4>
                                    </div>
                                    <div>
                                        <h4>Всего ошибок: {result.error}</h4>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} lg={4} xl={3} xxl={3}>
                                <Row className="justify-content-center">
                                    <Col xs={8} sm={12} className="position-relative cursor-pointer my-5">
                                        <img src={btn} className="w-100" style={{position: 'relative'}} />
                                        <div className="position-absolute top-50 start-50 translate-middle text-center">
                                            <h1 className="m-0" onClick={() => {
                                                setSelectShot(null)
                                                setShotIndex(-1)
                                                setEnd(false)
                                                setStart(true)
                                                setResult((prevState) => ({
                                                    ...prevState,
                                                    true: 0,
                                                    error: 0
                                                }))
                                            }}>Играть заново</h1>
                                        </div>
                                    </Col>
                                    <Col xs={8} sm={12} className="position-relative cursor-pointer my-5">
                                        <img src={btn} className="w-100" style={{position: 'relative'}} />
                                        <div className="position-absolute top-50 start-50 translate-middle text-center" onClick={() => {
                                            user.term.id === 2 ? window.location.href = '/games/teacher' : window.location.href = '/games'
                                        }}>
                                            <h1 className="m-0">Все игры</h1>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        </>
    );
};

export default OpenGame;
