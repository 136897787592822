import React, {FC, useEffect, useState} from 'react';
import {Button, TabContent, TabPane} from "reactstrap";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import AxiosClient from "../../../api/AxiosClient";

interface INavigateMenuPortfolio{
    setAddTheme(value: boolean): any,
    setAddType(value: boolean): any,
    setAddPattern(value: boolean): any,
    setAddPhotoPattern(value: boolean): any,
    setSelectTheme(value: any): any,
    setSelectType(value: any): any,
    setSelectPattern(value: any): any,
}

const NavigateMenuPortfolio: FC <INavigateMenuPortfolio> = ({setAddTheme, setAddType, setAddPattern, setAddPhotoPattern,
                                                            setSelectTheme, setSelectType, setSelectPattern}) => {
    const [customActiveTab, setCustomActiveTab] = useState<string>("1");
    const [activeTopic, setActiveTopic] = useState<number | null>(1);

    const [listTheme, setListTheme] = useState<any>([]);
    const [listType, setListType] = useState<any>([]);
    const [listPattern, setListPattern] = useState<any>([]);

    const toggleCustom = (tab: string) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };


    useEffect(() => {
        AxiosClient.get(`theme_all/parent/portfolio/1`, {params: {}})
            .then(r => {
                setListTheme(r.data)
            })
            .catch(() => console.log('error'))
        AxiosClient.get(`type_all/parent/portfolio`, {})
            .then(r => {
                setListType(r.data)
            })
            .catch(() => console.log('error'))
        AxiosClient.get(`pattern_all/parent/portfolio`, {params: {}})
            .then(r => {
                setListPattern(r.data)
            })
            .catch(() => console.log('error'))
    }, [])

    const handleTopicClick = (id: number) => {
        setActiveTopic(activeTopic === id ? null : id);
    };

    return (
        <div className="chat-leftsidebar">
            <TabContent activeTab={customActiveTab} className="text-muted h-100">
                <TabPane tabId="1" id="chats" className="h-100">
                    <SimpleBar className="pt-3 h-100" style={{ margin: "-16px 0px 0px" }}>
                        <div className="d-flex align-items-center px-4 mb-2">
                            <div className="flex-grow-1">
                                <h4 className="mb-0 mt-3 fs-11 text-muted text-uppercase">
                                    Выберите необходимый блок
                                </h4>
                            </div>
                        </div>

                        <div className="chat-message-list">
                            <ul className="list-unstyled chat-list chat-user-list users-list" id="userList">

                                    <li key={1}>
                                        <Link to="#" onClick={() => handleTopicClick(1)}>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-grow-1 overflow-hidden">
                                                    <p className="text-truncate mb-0">Темы</p>
                                                </div>
                                                <Button
                                                    color=""
                                                    id="addcontact"
                                                    className="btn btn-soft-primary btn-sm"
                                                    onClick={() => {
                                                        setSelectTheme({id: null, name: ''})
                                                        setAddTheme(true)
                                                        setAddType(false)
                                                        setAddPattern(false)
                                                    }}
                                                >
                                                    <i className="ri-add-line align-bottom"></i>
                                                </Button>
                                            </div>

                                        </Link>


                                        {activeTopic === 1 && (
                                            <ul className="list-unstyled chat-list chat-user-list users-list ms-5">
                                                {listTheme.map((theme: any, index: number) => (
                                                    <li key={index}>
                                                        <Link to="#" onClick={() => {
                                                            setSelectTheme({id: theme.id, name: theme.name})
                                                            setAddTheme(true)
                                                            setAddType(false)
                                                            setAddPattern(false)
                                                        }}>
                                                            {theme?.name}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </li>

                                <li key={2}>
                                    <Link to="#" onClick={() => handleTopicClick(2)}>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1 overflow-hidden">
                                                <p className="text-truncate mb-0">Типы</p>
                                            </div>
                                            <Button
                                                color=""
                                                id="addcontact"
                                                className="btn btn-soft-primary btn-sm"
                                                onClick={() => {
                                                    setSelectType({id: null, name: '', theme_page_portfolio_id: ''})
                                                    setAddTheme(false)
                                                    setAddType(true)
                                                    setAddPattern(false)

                                                }}
                                            >
                                                <i className="ri-add-line align-bottom"></i>
                                            </Button>
                                        </div>

                                    </Link>


                                    {activeTopic === 2 && (
                                        <ul className="list-unstyled chat-list chat-user-list users-list ms-5">
                                            {listType.map((type: any, index: number) => (
                                                <li key={'type' + index}>
                                                    <Link to="#" onClick={() => {
                                                        setSelectType({id: type.id, name: type.name, theme_page_portfolio_id: type.theme_page_portfolio_id})
                                                        setAddTheme(false)
                                                        setAddType(true)
                                                        setAddPattern(false)
                                                    }}>
                                                        {type.name}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>

                                <li key={3}>
                                    <Link to="#" onClick={() => handleTopicClick(3)}>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1 overflow-hidden">
                                                <p className="text-truncate mb-0">Шаблоны</p>
                                            </div>
                                            <Button
                                                color=""
                                                id="addcontact"
                                                className="btn btn-soft-primary btn-sm"
                                                onClick={() => {
                                                    setSelectPattern({id: null, name: ''})
                                                    setAddTheme(false)
                                                    setAddType(false)
                                                    setAddPattern(true)
                                                }}
                                            >
                                                <i className="ri-add-line align-bottom"></i>
                                            </Button>
                                        </div>

                                    </Link>


                                    {activeTopic === 3 && (
                                        <ul className="list-unstyled chat-list chat-user-list users-list ms-5">
                                            {listPattern.map((pattern: any, index: number) => (
                                                <li key={index}>
                                                    <Link to="#" onClick={() => {
                                                        setSelectPattern({id: pattern.id, name: pattern.name})
                                                        setAddTheme(false)
                                                        setAddType(false)
                                                        setAddPattern(true)
                                                    }}>
                                                        {pattern.name}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>


                            </ul>
                        </div>
                    </SimpleBar>
                </TabPane>
            </TabContent>
        </div>
    );
};

export default NavigateMenuPortfolio;
