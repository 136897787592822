import React, {FC} from 'react';
import {Row} from "reactstrap";
import Shot from "./Shot";

interface IProps {
    handleOpenSettings: () => void
    listShot: any[],
    selectShot: (shot: any) => void
}
const ListShots: FC<IProps> = ({handleOpenSettings, listShot, selectShot}) => {
    return (
        <Row>
            {
                listShot.map((shot, index) => <Shot key={index} selectShot={() => selectShot(shot)} handleOpenSettings={handleOpenSettings} shotName={shot.name}/>)
            }
        </Row>
    );
};

export default ListShots;
