import React, {FC, useRef, useState} from 'react';
import {useAppDispatch} from "../../../../hooks/redux";
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {setIsSend} from "../../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../../layouts/UI/SubmitWithLoading";
import BigDescriptionPage from "../portfolioPage/BigDescriptionPage";
import OtherDescription from "../portfolioPage/OtherDescription";

interface IEditOtherDescription {
    portfolio: any,
    setPortfolio(value: any): any,
    selectPagePortfolio: any,
    selectEditPagePortfolio: boolean,
    setSelectEditPagePortfolio(value: boolean): any
}

const EditOtherDescription: FC<IEditOtherDescription> = ({ portfolio, setPortfolio, selectEditPagePortfolio, setSelectEditPagePortfolio, selectPagePortfolio }) => {
    const dispatch = useAppDispatch();
    const [editPagePortfolio, setEditPagePortfolio] = useState<any>();
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [maxCharsPerLine, setMaxCharsPerLine] = useState<number>(0);

    return (
        <>
            <Modal id="createProjectModal" isOpen={selectEditPagePortfolio}
                   toggle={() => setSelectEditPagePortfolio(!selectEditPagePortfolio)}
                   modalClassName="zoomIn" tabIndex={-1} centered >
                <ModalHeader toggle={() => setSelectEditPagePortfolio(!selectEditPagePortfolio)}
                             className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Изменение страницы «{selectPagePortfolio?.type_page_portfolio.name}»
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            header: selectPagePortfolio?.content ? JSON.parse(selectPagePortfolio?.content)?.header : '',
                            description: selectPagePortfolio?.content ? JSON.parse(selectPagePortfolio?.content)?.description : '',
                        }}
                        validationSchema={
                            Yup.object({
                                description: Yup.string()
                                // .max(maxLength, `Описание не должно превышать ${maxLength} символов`),
                            })
                        }
                        onSubmit={async (values) => {
                            dispatch(setIsSend(true));
                            await setEditPagePortfolio({
                                header: values.header,
                                description: values.description,
                                uid: portfolio?.child_uid,
                                selectPage: selectPagePortfolio,
                                id_pattern: portfolio?.pattern_page_portfolio_id,
                            });

                            document.getElementById('handleCombineImagesOtherDescription')?.click();
                        }}
                    >
                        {
                            ({ errors, touched, setFieldValue }) => (
                                <Form>
                                    <Row>
                                        <Col>
                                            <label className="w-100">
                                                Заголовок:
                                                <Field name="header" className={`form-control ${touched.header && errors.header ? 'is-invalid' : ''}`} />
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">
                                                Описание:
                                                <Field
                                                    name="description"
                                                    as="textarea"
                                                    className={`form-control ${touched.description && errors.description ? 'is-invalid' : ''}`}
                                                    rows={6}
                                                />
                                            </label>
                                        </Col>
                                    </Row>
                                    <div className="hstack gap-2">
                                        <SubmitWithLoading text={'Готово'} />
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>
                </ModalBody>
            </Modal>
            <canvas ref={canvasRef} width={2480} height={3508} className="display-none" />
            <OtherDescription
                dispatch={(value) => dispatch(value)}
                page={editPagePortfolio}
                setPortfolio={(value) => setPortfolio(value)}
                setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}
            />
        </>
    );
};

export default EditOtherDescription;
