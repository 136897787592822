import React, {FC, useEffect, useState} from 'react';
import {Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import {IGame} from "../../../models/game/IGame";

interface IProps {
    toggle: () => void
    isOpen: boolean,
    dataGame: Omit<IGame, 'id'> | null,
    toggleSave: (game: Omit<IGame, 'id'>) => void
}
const ModalNameGame: FC<IProps> = ({toggle, isOpen, toggleSave, dataGame}) => {
    const [game, setGame] = useState(dataGame);
    useEffect(() => {
        setGame(dataGame)
    }, [dataGame]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
            <ModalHeader toggle={toggle}>Название игры</ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: game?.name ?? '',
                        fio: game?.fio ?? '',
                        ageGroup: game?.ageGroup ?? '',
                        objective: game?.objective ?? '',
                        integrationEducationRegion: game?.integrationEducationRegion ?? '',
                        educationTask: game?.educationTask ?? '',
                        evolution: game?.evolution ?? '',
                        education: game?.education ?? '',
                        gameTask: game?.gameTask ?? '',
                        wordsWork: game?.wordsWork ?? '',
                        planResult: game?.planResult ?? '',
                        preparatoryWork: game?.preparatoryWork ?? '',
                        file: null
                    }}
                    onSubmit={(values) => {
                        const element = document.getElementById('window')
                        if (element) {
                            const width = element?.offsetWidth
                            const height = element?.offsetHeight

                            toggleSave({...game, ...values, width, height, shots: game?.shots ?? []})
                        }

                    }}
                >
                    {
                        ({setFieldValue}) => (
                            <Form>
                                <Row>
                                    <Col sm={12}>
                                        <Row>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="name" className="form-label">Название игры</label>
                                                <Field type="text" id="name" name="name" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="fio" className="form-label">ФИО</label>
                                                <Field type="text" id="fio" name="fio" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="ageGroup" className="form-label">Возрастная группа</label>
                                                <Field as="textarea" id="ageGroup" name="ageGroup" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="objective" className="form-label">Цель игры</label>
                                                <Field as="textarea" id="objective" name="objective" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="integrationEducationRegion" className="form-label">Интеграция образовательных областей</label>
                                                <Field as="textarea" id="integrationEducationRegion" name="integrationEducationRegion" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="educationTask" className="form-label">Образовательная задача</label>
                                                <Field as="textarea" id="educationTask" name="educationTask" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="evolution" className="form-label">Развивающая задача</label>
                                                <Field as="textarea" id="evolution" name="evolution" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="education" className="form-label">Воспитательная задача</label>
                                                <Field as="textarea" id="education" name="education" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="gameTask" className="form-label">Игровая задача</label>
                                                <Field as="textarea" id="gameTask" name="gameTask" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="wordsWork" className="form-label">Словарная работа</label>
                                                <Field as="textarea" id="wordsWork" name="wordsWork" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="planResult" className="form-label">Планируемый результат</label>
                                                <Field as="textarea" id="planResult" name="planResult" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="preparatoryWork" className="form-label">Подготовительная работа</label>
                                                <Field as="textarea" id="preparatoryWork" name="preparatoryWork" className="form-control"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label htmlFor="textureLabel"
                                                   className="form-label">Изображение игры</Label>
                                            <div>
                                                <input id="textureLabel" name="file" type="file" className="form-control"
                                                       onChange={(event) => {
                                                           setFieldValue("file", event.currentTarget.files ? event.currentTarget.files[0] : null);
                                                       }}/>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="mt-1 gap-2 d-flex">
                                            <button className="btn btn-primary" type="submit">
                                                Сохранить
                                            </button>
                                            <button className="btn btn-primary" type="button" onClick={toggle}>
                                                Отмена
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalNameGame;
