import React, {FC, useState} from 'react';
import {Col} from "reactstrap";
import cn from "classnames";

interface IProps {
    handleOpenSettings: () => void
    shotName: string,
    selectShot: () => void
}
const Shot: FC<IProps> = ({handleOpenSettings, shotName, selectShot}) => {
    const [isHoverUpdate, setIsHoverUpdate] = useState(false);
    const [isHoverCopy, setIsHoverCopy] = useState(false);
    return (
        <Col xxl={3}>
            <div className="bg-white p-1 rounded d-flex justify-content-between align-items-center shadow">
                <div className="d-flex align-items-center">
                    <i className={cn("bx bx-pencil fs-3 cursor-pointer", {
                           'text-primary': isHoverUpdate
                       })}
                       onMouseEnter={() => setIsHoverUpdate(true)}
                       onMouseLeave={() => setIsHoverUpdate(false)}
                       onClick={() => {
                           handleOpenSettings()
                           selectShot()
                       }}
                    ></i>
                </div>
                <h3 className="m-0">{shotName}</h3>
                <div className="d-flex align-items-center">
                    <i className={cn("bx bx-copy fs-3 cursor-pointer", {
                           'text-primary': isHoverCopy
                       })}
                       onMouseEnter={() => setIsHoverCopy(true)}
                       onMouseLeave={() => setIsHoverCopy(false)}
                    ></i>
                </div>
            </div>
        </Col>
    );
};

export default Shot;
