import React, {FC, useCallback, useEffect, useState} from 'react';
import Draggable, {DraggableEvent} from "react-draggable";
import {ISettingsCountTextures, ISettingsShot, IShot} from "../../../models/game/IShot";
import {TypeSettings} from "../../../models/game/typesSettings/TypeSettings";
import {
    ISettingsAnswer,
    ISettingsCollect,
    ISettingsSelectAnswer,
    ISettingsSelectCollect,
    ISettingsTexture
} from "../../../models/game/ISettingsTexture";
import {TypeSettingsSelectAnswer} from "../../../models/game/typesSettings/TypeSettingsSelectAnswer";
import {ITexture} from "../../../models/game/ITexture";
import Recorder from 'recorder-js';
import AxiosClient from "../../../api/AxiosClient";
import cn from 'classnames'
import {TypeAnswer} from "../../../models/game/typesSettings/TypeAnswer";
import {Badge, Col, Row} from "reactstrap";
import {TypeSettingsShot} from "../../../models/game/typesSettings/TypeSettingsShot";
import {motion} from 'framer-motion';

interface IProps {
    shot: IShot,
    nextSelectShot: IShot | null,
    nextShot: () => void,
    onChangeError: () => void,
    onChangeTrue: () => void,
    height: number,
    width: number
}

const Shot: FC<IProps> = ({shot, nextShot, onChangeTrue, onChangeError, width, height, nextSelectShot}) => {
    const [countTrue, setCountTrue] = useState(0);
    const [toNext, setToNext] = useState(0);
    const [bgAudio, setBgAudio] = useState<HTMLAudioElement | null>(null);
    const [field, setField] = useState(false);
    const [fieldValue, setFieldValue] = useState('');
    const [texture, setTexture] = useState<ITexture | null>(null);
    const [recorder, setRecorder] = useState<Recorder | null>(null);
    const [isRecording, setIsRecording] = useState(false);
    const [isKeyboard, setIsKeyboard] = useState(false);
    const [keyboardValue, setKeyboardValue] = useState('');
    useEffect(() => {
        const audioContext = new (window.AudioContext)();

        navigator.mediaDevices.getUserMedia({audio: true})
            .then(stream => {
                const rec = new Recorder(audioContext);
                rec.init(stream);
                setRecorder(rec);
            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
            });
    }, []);

    useEffect(() => {

        if (shot.soundStartText) {
            const audio = new Audio(process.env.REACT_APP_API_BASE_URL + shot.soundStartText)
            audio.play()
        }

        if (shot.background_sound) {
            const audio = new Audio(process.env.REACT_APP_API_BASE_URL + shot.background_sound.path)
            audio.loop = true;
            audio.volume = 0.1
            setBgAudio(audio)
            audio.play()
        }


        let trueQues = 0;

        shot.textures.forEach((el) => {
            if ((JSON.parse(el.pivot.settings as any) as ISettingsTexture).type === TypeSettings.ANSWER) {
                if (((JSON.parse(el.pivot.settings as any) as ISettingsTexture).settings as ISettingsAnswer).type === TypeAnswer.ANSWER) {
                    trueQues += 1
                }
            }
            if ((JSON.parse(el.pivot.settings as any) as ISettingsTexture).type === TypeSettings.SELECT_ANSWER) {
                if (((JSON.parse(el.pivot.settings as any) as ISettingsTexture).settings as ISettingsSelectAnswer).select === TypeSettingsSelectAnswer.TRUE) {
                    trueQues += 1
                }
            }
            if ((JSON.parse(el.pivot.settings as any) as ISettingsTexture).type === TypeSettings.COLLECT) {
                trueQues += 1
            }
        })

        if (shot.settings as any !== 'null') {
            const settings = ((JSON.parse(shot.settings as any) as ISettingsShot))
            if (settings.type === TypeSettingsShot.COUNT_TEXTURE) {
                trueQues += 1
            }
        }

        setCountTrue(trueQues)
        setToNext(0)
        setIsKeyboard(false)
    }, [shot]);

    const toggleRecording = () => {
        if (!isRecording) {
            recorder?.start()
                .then(() => {
                    setIsRecording(true);
                    console.log('Recording started');
                })
                .catch(err => console.error('Recording error: ', err));
        } else {
            // Остановить запись
            recorder?.stop()
                .then(({blob}) => {
                    setIsRecording(false);
                    console.log('Recording stopped');
                    sendAudioToBackend(blob);
                })
                .catch(err => console.error('Stop recording error: ', err));
        }
    };

    const sendAudioToBackend = (blob: Blob) => {
        const formData = new FormData();
        formData.append('audio', blob, 'recording.wav');
        AxiosClient.post('game/mess_gpt', formData, {headers: {"Content-Type": "multipart/form-data"}})
            .then((r) => {
                const audio = new Audio(process.env.REACT_APP_API_BASE_URL + r.data)
                audio.play()
                    .then(() => {
                        AxiosClient.post('game/mess_gpt/delete', {path: r.data})
                            .catch(() => console.log('error'))
                    })
            })
            .catch(() => {
                console.log('error')
            })
    }

    const defaultPosition = useCallback(
        (percentX: number, percentY: number) => {
            const elem = document.getElementById('window')
            const onePercentX = elem?.offsetWidth ? elem.offsetWidth / 100 : 0;
            const onePercentY = elem?.offsetHeight ? elem.offsetHeight / 100 : 0;
            const x = onePercentX * percentX
            const y = onePercentY * percentY
            return {x, y}
        },
        [shot],
    );


    const playStartSound = () => {
        if (shot.soundStartText) {
            const audio = new Audio(process.env.REACT_APP_API_BASE_URL + shot.soundStartText)
            audio.play()
        }
    }

    const playSoundTexture = (path: string) => {
        const audio = new Audio(process.env.REACT_APP_API_BASE_URL + path)
        audio.play()

        return audio
    }

    const stopBgSoundToNextShot = () => {
        if (nextSelectShot) {
            if (nextSelectShot.backgroundSound?.id === shot.backgroundSound?.id) {
                return false
            }
        }
        return true
    }
    const checkNext = (texture: ITexture) => {
        const settings = ((JSON.parse(texture.pivot.settings as any) as ISettingsTexture).settings as ISettingsSelectAnswer)

        if (settings.select === TypeSettingsSelectAnswer.TRUE) {
            const trueBadge = document.getElementById(`texture-success-${texture.id}`)
            if (trueBadge) {
                trueBadge.classList.remove('d-none')
            }
            const audio = playSoundTexture(settings.path)
            audio.addEventListener('ended', () => {
                if (toNext + 1 === countTrue) {
                    onChangeTrue()
                    nextShot();
                    if (stopBgSoundToNextShot()) {
                        bgAudio?.pause()
                        setBgAudio(null)
                    }
                }
            });
            setToNext(prevState => prevState + 1)
        }
        if (settings.select === TypeSettingsSelectAnswer.FALSE) {
            const failBadge = document.getElementById(`texture-fail-${texture.id}`)
            if (failBadge) {
                failBadge.classList.remove('d-none')
            }
            onChangeError()
        }

        playSoundTexture(settings.path)

    }

    const isOverlapping = (rect1: DOMRect, rect2: DOMRect) => {
        return !(
            rect1.right < rect2.left ||
            rect1.left > rect2.right ||
            rect1.bottom < rect2.top ||
            rect1.top > rect2.bottom
        );
    };

    const checkCountTexture = () => {
        const settings = ((JSON.parse(shot.settings as any) as ISettingsShot).settings as ISettingsCountTextures)
        if (Number(settings.answer) === Number(keyboardValue)) {
            onChangeTrue()
            const audio = new Audio(process.env.REACT_APP_API_BASE_URL + settings.pathTrue)
            audio.play()
            audio.addEventListener('ended', () => {
                if (toNext + 1 === countTrue) {
                    nextShot();
                    if (stopBgSoundToNextShot()) {
                        bgAudio?.pause()
                        setBgAudio(null)
                    }
                }
            });
        } else {
            onChangeError()
            const audio = new Audio(process.env.REACT_APP_API_BASE_URL + settings.pathFalse)
            audio.play()
            setKeyboardValue('')
        }
    }

    const checkAllCollect = (e: DraggableEvent, el: ITexture) => {
        const type = (JSON.parse(el.pivot.settings as any) as ISettingsTexture).type
        let parentId: null | number = null;
        let audio: HTMLAudioElement = new Audio();
        if (type === TypeSettings.COLLECT) {
            parentId = el.id
        }
        if (type === TypeSettings.SELECT_COLLECT) {
            const parent = ((JSON.parse(el.pivot.settings as any) as ISettingsTexture).settings as ISettingsSelectCollect)
            parentId = parent.parent.id
        }
        if (type === TypeSettings.SELECT_COLLECT) {
            const parentElem = document.querySelector(`#texture-${parentId}`) as HTMLElement;
            const parentRect = parentElem.getBoundingClientRect();
            if (parentId) {
                const parentTexture = shot.textures.find(el => el.id === parentId)
                let texturesCount = 0
                if (parentTexture) {
                    texturesCount = ((JSON.parse(parentTexture.pivot.settings as any) as ISettingsTexture).settings as ISettingsCollect).textures.length
                }
                let overlapping = 0
                if (parentTexture) {
                    const isIssetInParent = ((JSON.parse(parentTexture.pivot.settings as any) as ISettingsTexture).settings as ISettingsCollect).textures.find((otherEl) => otherEl.id === el.id);
                    if (isIssetInParent) {
                        ((JSON.parse(parentTexture.pivot.settings as any) as ISettingsTexture).settings as ISettingsCollect).textures.forEach((otherEl) => {
                            const otherElement = document.querySelector(`#texture-${otherEl.id}`) as HTMLElement;
                            if (otherElement) {
                                const otherRect = otherElement.getBoundingClientRect();
                                if (isOverlapping(parentRect, otherRect)) {
                                    overlapping += 1
                                    if (otherEl.id === el.id) {
                                        const audioPath = ((JSON.parse(parentTexture.pivot.settings as any) as ISettingsTexture).settings as ISettingsCollect).pathTextTrue
                                        audio = new Audio(process.env.REACT_APP_API_BASE_URL + audioPath)
                                        audio.play()
                                    }
                                } else {
                                    audioErrorTexture(el)
                                }
                            }
                        });
                    }
                }
                if (texturesCount === overlapping) {
                    audio.addEventListener('ended', () => {
                        if (toNext + 1 === countTrue) {
                            onChangeTrue()
                            nextShot();
                            if (stopBgSoundToNextShot()) {
                                bgAudio?.pause()
                                setBgAudio(null)
                            }
                        }
                    });
                    setToNext(prevState => prevState + 1)
                }
            }
        }
    }

    const audioErrorTexture = (el: ITexture) => {
        let audio: HTMLAudioElement = new Audio();
        const parentTextures = shot.textures.filter(e => (JSON.parse(e.pivot.settings as any) as ISettingsTexture).type === TypeSettings.COLLECT)
        const otherElem = document.querySelector(`#texture-${el.id}`) as HTMLElement;
        const otherRect = otherElem.getBoundingClientRect();
        let p = true
        parentTextures.forEach((texture) => {
            const parentElem = document.querySelector(`#texture-${texture.id}`) as HTMLElement;
            const parentRect = parentElem.getBoundingClientRect();
            if (isOverlapping(parentRect, otherRect)) {
                const audioPath = ((JSON.parse(texture.pivot.settings as any) as ISettingsTexture).settings as ISettingsCollect).pathTextFalse
                audio = new Audio(process.env.REACT_APP_API_BASE_URL + audioPath)
                audio.play()
                onChangeError()
                console.log(2)
                p = false
            }
        })
        return p
    }
    const showKeyboard = () => {
        if (shot.settings as any !== 'null') {
            if ((JSON.parse(shot.settings as any) as ISettingsShot).type === TypeSettingsShot.COUNT_TEXTURE) {
                return <div className="d-flex gap-1 position-absolute end-0 bottom-0 w-100 p-1">
                    <button onClick={() => setIsKeyboard(!isKeyboard)} className="btn btn-primary fs-3">
                        <i className='bx bxs-keyboard'></i>
                    </button>
                </div>
            }
        }

        return <></>
    }

    const soundHint = () => {
        const audio = new Audio(process.env.REACT_APP_API_BASE_URL + shot.pathHint)
        audio.play()
    }

    const sized = (startWidth: number, startHeight: number) => {
        const element = document.getElementById('window');
        let newWidth = 0
        let newHeight = 0
        if (element) {
            const wElem = element.offsetWidth
            const hElem = element.offsetHeight
            let scaleW = wElem > width ? wElem / width : width / wElem
            let scaleH = hElem > height ? hElem / height : height / hElem

            newWidth = wElem > width ? scaleW * startWidth : startWidth / scaleW
            newHeight = hElem > height ? scaleH * startHeight : startHeight / scaleH
        }
        return {width: newWidth, height: newHeight}
    }

    const sendAnswer = () => {
        if (texture) {
            const settings = (JSON.parse(texture.pivot.settings as any) as ISettingsTexture).settings as ISettingsAnswer
            if (settings.isGPTChat) {
                AxiosClient.post('game/check_answer_gpt', {answer: fieldValue, answerResult: settings.textAnswer})
                    .then((r) => {
                        if (r.data.result) {
                            const audio = new Audio(process.env.REACT_APP_API_BASE_URL + settings.path)
                            audio.play()
                            audio.addEventListener('ended', () => {
                                if (toNext + 1 === countTrue) {
                                    onChangeTrue()
                                    nextShot();
                                    if (stopBgSoundToNextShot()) {
                                        bgAudio?.pause()
                                        setBgAudio(null)
                                    }
                                }
                            });
                            setFieldValue('')
                            setField(false)
                        } else {
                            onChangeError()
                            const audio = new Audio(process.env.REACT_APP_API_BASE_URL + settings.pathFalse)
                            audio.play()
                        }
                    })
                    .catch(() => console.log('error'))
            } else {
                if (settings.textAnswer.toLowerCase() === fieldValue.toLowerCase()) {
                    const audio = new Audio(process.env.REACT_APP_API_BASE_URL + settings.path)
                    audio.play()
                    audio.addEventListener('ended', () => {
                        if (toNext + 1 === countTrue) {
                            onChangeTrue()
                            nextShot();
                            if (stopBgSoundToNextShot()) {
                                bgAudio?.pause()
                                setBgAudio(null)
                            }
                        }
                    });
                    setFieldValue('')
                    setField(false)
                } else {
                    onChangeError()
                    const audio = new Audio(process.env.REACT_APP_API_BASE_URL + settings.pathFalse)
                    audio.play()
                }
            }
        }
    }

    const animationElem = (el: ITexture) => {
        const animation = (JSON.parse(el.pivot.settings as any) as ISettingsTexture).typeAnimation;

        if (animation === "pulse") {
            return <motion.div
                animate={{scale: [1, 0.9, 1]}} // Анимация
                transition={{
                    duration: 1, // Длительность одного цикла анимации
                    ease: "easeInOut", // Плавность перехода
                    repeat: Infinity, // Повторять бесконечно
                    repeatType: "loop", // Циклическая анимация
                }}
            >
                <img src={process.env.REACT_APP_API_BASE_URL + el.path} alt=""
                     style={sized((JSON.parse(el.pivot.settings as any) as ISettingsTexture).width, (JSON.parse(el.pivot.settings as any) as ISettingsTexture).height)}/>
            </motion.div>
        }
        if (animation === "shadow") {
            return <motion.div
                animate={{filter: ['drop-shadow(5px 5px 5px #fff)', 'drop-shadow(0px 0px 0px #000)', 'drop-shadow(5px 5px 5px #fff)',]}} // Анимация
                transition={{
                    duration: 1.5, // Длительность одного цикла анимации
                    ease: "easeInOut", // Плавность перехода
                    repeat: Infinity, // Повторять бесконечно
                    repeatType: "loop", // Циклическая анимация
                }}
            >
                <img src={process.env.REACT_APP_API_BASE_URL + el.path} alt=""
                     style={sized((JSON.parse(el.pivot.settings as any) as ISettingsTexture).width, (JSON.parse(el.pivot.settings as any) as ISettingsTexture).height)}/>
            </motion.div>
        }

        return <img src={process.env.REACT_APP_API_BASE_URL + el.path} alt=""
                    style={sized((JSON.parse(el.pivot.settings as any) as ISettingsTexture).width, (JSON.parse(el.pivot.settings as any) as ISettingsTexture).height)}/>
    }

    return (
        <div className="position-absolute top-0 start-0 h-100 w-100 bg-white col-xxl-3" style={shot.background ? {
            backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL + shot.background.path})`,
            backgroundSize: '100% 100%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
        } : {}}>
            {shot.textures.map((el, index) => (
                <Draggable
                    key={`${shot.id}-${index}`}
                    enableUserSelectHack={false}
                    defaultPosition={defaultPosition(el.pivot.percentX, el.pivot.percentY)}
                    bounds="parent"
                    onStop={(e, data) => {
                        checkAllCollect(e, el)
                    }}
                    onMouseDown={() => {
                        if ((JSON.parse(el.pivot.settings as any) as ISettingsTexture).type !== TypeSettings.NO_SETTINGS) {
                            if ((JSON.parse(el.pivot.settings as any) as ISettingsTexture).type === TypeSettings.SELECT_ANSWER) {
                                checkNext(el)
                            }
                            if ((JSON.parse(el.pivot.settings as any) as ISettingsTexture).type === TypeSettings.ANSWER) {
                                setField(true)
                                setTexture(el)
                            }
                        }
                    }}
                >
                    <div className="position-absolute"
                         id={`texture-${el.id}`}
                         style={{
                             zIndex: (JSON.parse(el.pivot.settings as any) as ISettingsTexture).type === TypeSettings.COLLECT ? 10 : "auto",
                         }}
                    >
                        <Badge color="success" style={{width: 40, height: 40, zIndex: 10}}
                               id={`texture-success-${el.id}`}
                               className="rounded-circle d-flex justify-content-center align-items-center position-absolute top-50 start-50 translate-middle d-none"><i
                            className="bx bx-check fs-1"></i></Badge>
                        <Badge color="danger" style={{width: 40, height: 40, zIndex: 10}}
                               id={`texture-fail-${el.id}`}
                               className="rounded-circle d-flex justify-content-center align-items-center position-absolute top-50 start-50 translate-middle d-none"><i
                            className="bx bx-x fs-1"></i></Badge>
                        {
                            animationElem(el)
                        }
                    </div>
                </Draggable>
            ))}
            {shot.personages.map((el, index) => (
                <Draggable
                    key={`${shot.id}-${index}`}
                    bounds="parent"
                    disabled
                    enableUserSelectHack={true}
                    defaultPosition={defaultPosition(el.pivot.percentX, el.pivot.percentY)}
                    onMouseDown={playStartSound}
                >
                    <div className="position-absolute"
                    >
                        <img src={process.env.REACT_APP_API_BASE_URL + el.path} alt=""
                             style={sized(el.pivot.width, el.pivot.height)}/>
                    </div>
                </Draggable>
            ))}
            {
                Boolean(shot.isGPTChat) && !field &&
                <button className={cn('position-absolute end-0 bottom-0 m-5 btn-rounded btn btn-info', {
                    'btn-danger': isRecording
                })} onClick={toggleRecording}>
                    <i className="bx bx-microphone fs-1"></i>
                </button>
            }
            {
                field &&
                <div className="d-flex gap-1 position-absolute end-0 bottom-0 w-100 p-1">
                    <input type="text" className="form-control fs-3" value={fieldValue}
                           onChange={(event) => setFieldValue(event.target.value)}/>
                    <button className="btn btn-primary p-3 m-0" onClick={() => {
                        setTexture(null)
                        setField(false)
                    }}>
                        <i className="bx bx-x fs-3 m-0 p-0"></i>
                    </button>
                    <button className="btn btn-primary p-3 m-0" onClick={sendAnswer}>
                        <i className="bx bx-send fs-3 m-0 p-0"></i>
                    </button>
                </div>
            }
            {
                !isKeyboard &&
                showKeyboard()
            }
            {
                isKeyboard &&
                <div className="d-flex position-absolute end-0 bottom-0 w-100 p-1">
                    <Row>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((number) => (
                            <Col key={number} className="mt-1">
                                <button
                                    style={{width: '75px', height: '75px'}}
                                    className={cn('rounded-circle fs-3 btn', {
                                        'btn-light': !keyboardValue.includes(String(number)),
                                        'btn-danger': keyboardValue.includes(String(number))
                                    })}
                                    onClick={() => setKeyboardValue((prevState) => prevState + String(number))}
                                >
                                    {number}
                                </button>
                            </Col>
                        ))}
                        <Col className="mt-1">
                            <button className="rounded-circle btn btn-success" style={{width: '75px', height: '75px'}}
                                    onClick={() => checkCountTexture()}>
                                <i className="bx bx-send fs-3 "></i>
                            </button>
                        </Col>
                        <Col className="mt-1">
                            <button className="rounded-circle btn btn-danger" style={{width: '75px', height: '75px'}}
                                    onClick={() => {
                                        setIsKeyboard(false)
                                        setKeyboardValue('')
                                    }}>
                                <i className="bx bxs-keyboard fs-3"></i>
                            </button>
                        </Col>
                    </Row>
                </div>
            }
            {
                shot.pathHint &&
                <div className="d-flex position-absolute justify-content-end w-100 p-1">
                    <button className="rounded-circle btn btn-info d-flex justify-content-center align-items-center"
                            style={{width: '50px', height: '50px'}} onClick={() => {
                        soundHint()
                    }}>
                        <i className="bx bx-question-mark fs-3"></i>
                    </button>
                </div>
            }
        </div>
    );
};

export default Shot;
