import React, {FC} from 'react';
import jsPDF from 'jspdf';
import AxiosClient from "../../../api/AxiosClient";
import {setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {useAppDispatch} from "../../../hooks/redux";

interface IImageToPDF {
    pathPage: any;
}

const ImageToPDF: FC<IImageToPDF> = ({ pathPage }) => {
    const dispatch = useAppDispatch();

    const downloadPDF = () => {
        dispatch(setIsSend(true));
        const params = {
            pathPage: pathPage,
        }
        AxiosClient.get('/download/document/portfolio', {responseType: 'blob', params})
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'portfolio.pdf');
                document.body.appendChild(link);
                link.click();
                link.remove();
                dispatch(setIsSend(false));
            })
            .catch(() => {
                console.log('error')
            })
    }

    return (
        <SubmitWithLoading text={'Скачать PDF'} onClick={downloadPDF} className="btn btn-light view-btn"/>
    );
};

export default ImageToPDF;
