import React, {FC, useState} from 'react';
import {Card, CardBody, Col, Row, Spinner} from "reactstrap";
import DataTable from "react-data-table-component";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import Notification from "../../widgets/Notification";
import {useAppDispatch} from "../../../hooks/redux";
import AxiosClient from "../../../api/AxiosClient";
import {setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";

interface ITableConfirmationExportClassNutritionClildren {
    listImport: any
}

const TableConfirmationExportClassNutritionClildren: FC<ITableConfirmationExportClassNutritionClildren> = ({listImport}) => {
    const dispatch = useAppDispatch();
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');
    const daysInMonth = listImport.daysInMonth;

    const columns = [...Array(daysInMonth)].map((_, index) => ({
        name: <span className='font-weight-bold fs-10 mx-2'>{index + 1}</span>,
        cell: (row: any, indexRow: number) => <span
            className='m-auto fs-10' style={{borderRight: index === 5 ? 'none' : '1px solid #ccc'}}>{row.count_nutrion[index] === 0 ? '-' : row.count_nutrion[index]}</span>,
        compact: true,
        'width': '30px',
        wrap: false,
    }));
    columns.unshift({
            name: <span className='font-weight-bold fs-10 mx-1'>Класс</span>,
            cell: (row: any, index: number) => <span className='px-1 fs-10'>{row.name}</span>,
            compact: true,
            'width': '164px',
            wrap: true

        },
        {
            name: <span className='font-weight-bold fs-10 mx-1'>Всего</span>,
            cell: (row: any, index: number) => <span
                className='m-auto fs-10'>{row.count_sum === 0 ? '-' : row.count_sum}</span>,
            compact: true,
            'width': '45px',
            wrap: true
        });

    const test = () => {
        const params = {
            date: listImport.date,
            school: listImport.school,
            build: listImport.build,
            class: listImport.class,
            noBenefits: "false",
            groupBy: "default"
        }
        AxiosClient.get('request_for_nutrition/count_in_class/export/file', {responseType: 'blob', params})
            .then((r: any) => {
                dispatch(setIsSend(false))
                const url = window.URL.createObjectURL(new Blob([r.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Количество питающихся.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(() => {
                console.log('error')
                dispatch(setIsSend(false))
                setNotificationType('error');
                setNotificationMessage('Ошибка, не удалось экспортировать!');
                setShowNotification(true);
            })
    }

    return (
        <Row>
            <Col>
                <Card className="mt-3">
                    <CardBody>
                        <DataTable
                            title="Количество питающихся"
                            columns={columns}
                            data={listImport.groupedStudents}
                            noDataComponent={'Нет данных'}
                            progressComponent={<Spinner></Spinner>}
                            pagination
                            paginationRowsPerPageOptions={[20, 50, 100]}
                            paginationPerPage={20}
                            paginationComponentOptions={
                                {
                                    rowsPerPageText: 'Видно классов:',
                                    rangeSeparatorText: 'из'
                                }
                            }
                            highlightOnHover
                            customStyles={{
                                headCells: {
                                    style: {
                                        borderRight: '1px solid #ccc',
                                    },
                                },
                                rows: {
                                    highlightOnHoverStyle: {
                                        backgroundColor: "#ffebb5"
                                    },
                                },
                            }}
                            fixedHeader={true}
                        />
                        <div className="hstack gap-2">
                            <SubmitWithLoading onClick={() => test()} text={"Скачать отчет"}/>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            {showNotification && <Notification type={notificationType} message={notificationMessage}
                                               setShowNotification={(value: boolean) => setShowNotification(value)}/>}
        </Row>
    );
};

export default TableConfirmationExportClassNutritionClildren;
