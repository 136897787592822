import React, {FC, useEffect, useState} from 'react';
import Draggable, {DraggableData, DraggableEvent} from 'react-draggable';
import {Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import SettingsTexture from "./SettingsTexture";
import {IShot} from "../../../models/game/IShot";
import {ITexture} from "../../../models/game/ITexture";
import {IPersonage} from "../../../models/game/IPersonage";
import {TypeSettings} from "../../../models/game/typesSettings/TypeSettings";
import {ISettingsCollect} from "../../../models/game/ISettingsTexture";
import SettingsPersonage from "./SettingsPersonage";
import {ResizableBox} from "react-resizable";
import "react-resizable/css/styles.css";
import './style.scss'
interface IProps {
    shot: IShot,
    openFullShot: boolean,
    handleCloseShot: (shot: IShot) => void,
    openSettings: boolean
}

const FullShot: FC<IProps> = ({shot, openFullShot, handleCloseShot, openSettings}) => {
    const [updateShot, setUpdateShot] = useState(shot);
    const [openSettingsTexture, setOpenSettingsTexture] = useState(false);
    const [openSettingsPersonage, setOpenSettingsPersonage] = useState(false);
    const [selectTexture, setSelectTexture] = useState<ITexture | null>(null);
    const [selectPersonage, setSelectPersonage] = useState<IPersonage | null>(null);

    useEffect(() => {
        setUpdateShot(shot)
    }, [shot]);


    const handleStopTexture = (e: DraggableEvent, data: DraggableData, texture: ITexture) => {
        const elem = document.getElementById('window')
        const onePercentX = elem?.offsetWidth ? elem.offsetWidth / 100 : 0;
        const onePercentY = elem?.offsetHeight ? elem.offsetHeight / 100 : 0;


        setUpdateShot((shot) => ({
            ...shot,
            textures: shot.textures.map((el) => {
                if (el.id === texture.id) {
                    return {
                        ...el,
                        pivot: {
                            ...el.pivot,
                            x: data.x,
                            y: data.y,
                            percentX: +(data.x / onePercentX).toFixed(0),
                            percentY: +(data.y / onePercentY).toFixed(0)
                        }
                    }
                }
                return el;
            })
        }))
    };

    const handleStopPersonage = (e: DraggableEvent, data: DraggableData, personage: IPersonage) => {
        const elem = document.getElementById('window')
        const onePercentX = elem?.offsetWidth ? elem.offsetWidth / 100 : 0;
        const onePercentY = elem?.offsetHeight ? elem.offsetHeight / 100 : 0;

        setUpdateShot((shot) => ({
            ...shot,
            personages: shot.personages.map((el) => {
                if (el.id === personage.id) {
                    return {
                        ...el,
                        pivot: {
                            ...el.pivot,
                            x: data.x,
                            y: data.y,
                            percentX: +(data.x / onePercentX).toFixed(0),
                            percentY: +(data.y / onePercentY).toFixed(0)
                        }
                    }
                }
                return el;
            })
        }))
    };

    const handleOpenSettingsTexture = () => {
        setOpenSettingsTexture(!openSettingsTexture)
    }

    const handleOpenSettingsPersonage = () => {
        setOpenSettingsPersonage(!openSettingsPersonage)
    }

    const saveSettingsTexture = (texture: ITexture) => {
        if (texture.pivot.settings.type === TypeSettings.COLLECT) {
            const textures = (texture.pivot.settings.settings as ISettingsCollect).textures.map(el => ({
                ...el,
                pivot: {
                    ...el.pivot,
                    settings: {
                        ...el.pivot.settings,
                        type: TypeSettings.SELECT_COLLECT,
                        settings: {
                            parent: texture
                        }
                    }
                }
            } as ITexture))
            setUpdateShot((shot) => ({
                ...shot,
                textures: shot.textures.map((el) => {
                    if (el.id === texture.id) {
                        return texture
                    }
                    const t = textures.find((t) => t.id === el.id)
                    if (t) {
                        return t
                    }
                    return el
                })
            }))
        } else {
            setUpdateShot((shot) => ({
                ...shot,
                textures: shot.textures.map((el) => {
                    if (el.id === texture.id) {
                        return texture
                    }
                    return el
                })
            }))
        }
    }

    const saveSettingsPersonage = (personage: IPersonage) => {
        setUpdateShot((shot) => ({
            ...shot,
            personages: shot.personages.map((el) => {
                if (el.id === personage.id) {
                    return personage
                }
                return el
            })
        }))
    }

    const removeTexture = (texture: ITexture) => {
        setUpdateShot((shot) => ({
            ...shot,
            textures: shot.textures.filter(el => el.id !== texture.id)
        }))
    }

    const removePersonage = (personage: IPersonage) => {
        setUpdateShot((shot) => ({
            ...shot,
            personages: shot.personages.filter(el => el.id !== personage.id)
        }))
    }

    const defaultPosition = (percentX: number, percentY: number) => {
        const elem = document.getElementById('window')
        const onePercentX = elem?.offsetWidth ? elem.offsetWidth / 100 : 0;
        const onePercentY = elem?.offsetHeight ? elem.offsetHeight / 100 : 0;

        const x = onePercentX * percentX
        const y = onePercentY * percentY

        return {x, y}
    }

    return (
        openFullShot && !openSettings ? (
            <div className="position-absolute top-0 start-0 h-100 w-100 bg-white col-xxl-3"
                 style={shot.backgroundImage ? {
                     backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL + shot.backgroundImage.path})`,
                     backgroundSize: '100% 100%',
                     backgroundPosition: 'center'
                 } : {}}>
                {
                    !openSettingsTexture &&
                    <Badge color="primary" className='fs-20 cursor-pointer'
                           style={{position: 'absolute', top: '-25px', left: '10px'}}
                           onClick={() => handleCloseShot(updateShot)}>
                        Сохранить кадр
                    </Badge>
                }
                {updateShot.textures.map((el, index) => (
                    <Draggable
                        key={index}
                        defaultPosition={defaultPosition(el.pivot.percentX, el.pivot.percentY)}
                        bounds="parent"
                        onStop={(e, data) => handleStopTexture(e, data, el)}
                    >
                        <div className="position-absolute">
                            <UncontrolledDropdown
                                className="cursor-pointer position-absolute top-0 end-0"
                                direction="end"
                                style={{zIndex: 10}}
                            >
                                <DropdownToggle
                                    color="primary"
                                >
                                    <i className="bx bx-cog fs-20"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => {
                                        handleOpenSettingsTexture()
                                        setSelectTexture(el)
                                    }}>
                                        Редактировать
                                    </DropdownItem>
                                    <DropdownItem onClick={() => removeTexture(el)}>
                                        Удалить
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <ResizableBox
                                width={el.pivot.settings.width}
                                height={el.pivot.settings.width}
                                minConstraints={[100, 100]} // Минимальные размеры
                                maxConstraints={[500, 500]} // Максимальные размеры
                                className="custom-resizable-box"
                                onResize={(w, h) => {
                                    setUpdateShot((shot) => ({
                                        ...shot,
                                        textures: shot.textures.map((t) => {
                                            if (el.id === t.id) {
                                                return {
                                                    ...t,
                                                    pivot: {
                                                        ...t.pivot,
                                                        settings: {
                                                            ...t.pivot.settings,
                                                            width: h.size.width,
                                                            height: h.size.height
                                                        }
                                                    }
                                                }
                                            }
                                            return t
                                        })
                                    }))
                                }}
                                resizeHandles={['nw', 'n', 'w']} // Изменение размера за правый нижний угол
                            >
                                <img src={process.env.REACT_APP_API_BASE_URL + el.path} alt=""
                                     style={{transform: `scale(${el.pivot.size})`, width: "100%", height: "100%"}}/>
                            </ResizableBox>
                        </div>
                    </Draggable>
                ))}
                {updateShot.personages.map((el, index) => (
                    <Draggable
                        key={index}
                        bounds="parent"
                        defaultPosition={defaultPosition(el.pivot.percentX, el.pivot.percentY)}
                        onStop={(e, data) => handleStopPersonage(e, data, el)}
                    >
                        <div className="position-absolute"
                        >
                            <UncontrolledDropdown
                                className="cursor-pointer position-absolute top-0 end-0"
                                direction="end"
                                style={{zIndex: 10}}
                            >
                                <DropdownToggle
                                    color="primary"
                                >
                                    <i className="bx bx-cog fs-20"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => {
                                        handleOpenSettingsPersonage()
                                        setSelectPersonage(el)
                                    }}>
                                        Редактировать
                                    </DropdownItem>
                                    <DropdownItem onClick={() => removePersonage(el)}>
                                        Удалить
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <ResizableBox
                                width={el.pivot.width}
                                height={el.pivot.width}
                                minConstraints={[100, 100]} // Минимальные размеры
                                maxConstraints={[500, 500]} // Максимальные размеры
                                className="custom-resizable-box"
                                onResize={(w, h) => {
                                    setUpdateShot((shot) => ({
                                        ...shot,
                                        personages: shot.personages.map((t) => {
                                            if (el.id === t.id) {
                                                return {
                                                    ...t,
                                                    pivot: {
                                                        ...t.pivot,
                                                        width: h.size.width,
                                                        height: h.size.height
                                                    }
                                                }
                                            }
                                            return t
                                        })
                                    }))
                                }}
                                resizeHandles={['nw', 'n', 'w']} // Изменение размера за правый нижний угол
                            >
                                <img src={process.env.REACT_APP_API_BASE_URL + el.path} alt=""
                                     style={{transform: `scale(${el.pivot.size})`, width: "100%", height: "100%"}}/>
                            </ResizableBox>
                        </div>
                    </Draggable>
                ))}
                {
                    selectTexture &&
                    <SettingsTexture shot={updateShot} openSettingsTexture={openSettingsTexture}
                                     handleOpenSettingsTexture={handleOpenSettingsTexture} selectTexture={selectTexture}
                                     saveSettingsTexture={(texture) => saveSettingsTexture(texture)}/>
                }
                {
                    selectPersonage &&
                    <SettingsPersonage openSettingsPersonage={openSettingsPersonage}
                                       handleOpenSettingsPersonage={handleOpenSettingsPersonage}
                                       selectPersonage={selectPersonage}
                                       saveSettingsPersonage={(personage) => saveSettingsPersonage(personage)}/>
                }
            </div>
        ) : <></>
    );
};

export default FullShot;
