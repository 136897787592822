import React, {FC, useState} from 'react';
import {Alert, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import {Russian} from "flatpickr/dist/l10n/ru";
import Flatpickr from "react-flatpickr";
import AxiosClient from "../../../api/AxiosClient";
import downloadPDF from "../../../functions/downloadPDF";
import MonthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

interface IModalGetTabel {
    modalSign: boolean,
    setModalSign: (value: boolean) => void,
    group_id: string
}

const ModalGetTabel: FC<IModalGetTabel> = ({setModalSign, modalSign, group_id}) => {

    const [date, setDate] = useState(new Date());
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    async function getTabel() {
        setLoading(true)
        const params = {
            group_id,
            date
        }
        AxiosClient.get('/get_file_tabel', {params})
            .then(d => {
                setLoading(false)
                downloadPDF(d.data.base64, d.data.filename)
            })
            .catch(() => {
                setError(true)
                setLoading(false)
            })
    }

    return (
        <Modal id="createProjectModal" isOpen={modalSign}
               toggle={() => {
                   setModalSign(!modalSign)
               }}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => {
                setModalSign(!modalSign)
            }} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Получить табель
            </ModalHeader>
            <ModalBody>
                <div>
                    {error && <Alert color="danger">Не удалось получить табель</Alert>}
                    {
                        loading ?
                            <div className="d-sm-flex text-center align-items-center justify-content-center">
                                <Spinner
                                    color="success">
                                    Loading...
                                </Spinner>
                                <div className="mx-2 fs-5">Подождите. Скоро начнется загрузка табеля.</div>
                            </div>
                            :
                            <div className="col-auto mb-2">
                                <label>Дата:</label>
                                <div className="col mb-2">
                                    <div className="input-group">
                                        <Flatpickr
                                            className="form-control border-0 dash-filter-picker shadow"
                                            value={date}
                                            options={{
                                                locale: Russian,
                                                dateFormat: "m Y",
                                                maxDate: new Date(),
                                                disableMobile: true,
                                                plugins: [MonthSelectPlugin({
                                                    shorthand: true, //defaults to false
                                                    dateFormat: "F y", //defaults to "F Y"
                                                    altFormat: "F Y", //defaults to "F Y"
                                                })]
                                            }}
                                            onChange={(date) => setDate(date[0])}
                                        />
                                    </div>
                                </div>
                            </div>

                    }
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="hstack gap-2 justify-content-end mt-2">
                    {
                        loading
                            ?
                            <button type="button" className="btn btn-primary" id="addNewProject">
                                <div className="d-flex align-items-center">
                                    <Spinner
                                        size="sm"
                                        className="mx-2"
                                    >
                                        Loading...
                                    </Spinner>
                                    Подождите
                                </div>
                            </button>
                            :
                            <button type="submit" className="btn btn-primary" id="addNewProject"
                                    onClick={getTabel}>Получить табель</button>
                    }
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default ModalGetTabel;
