import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getPages, getTerms} from "../../store/reducers/admin/ActionCreator";
import {Alert, Col, Container, Nav, NavItem, Row, TabContent, TabPane} from "reactstrap";
import NavigateMenuPortfolio from "../../components/admin/portfolio/NavigateMenuPortfolio";
import AddThemePortfolio from "../../components/admin/portfolio/AddThemePortfolio";
import AddTypePortfolio from "../../components/admin/portfolio/AddTypePortfolio";
import AddPatternPortfolio from "../../components/admin/portfolio/AddPatternPortfolio";
import AddPhotoPatternPortfolio from "../../components/admin/portfolio/AddPhotoPatternPortfolio";

const SettingsPortfolio = () => {
    const dispatch = useAppDispatch();
    const {isError, terms} = useAppSelector(state => state.AdminReducer);

    const [addTheme, setAddTheme] = useState<boolean>(false);
    const [addType, setAddType] = useState<boolean>(false);
    const [addPattern, setAddPattern] = useState<boolean>(false);
    const [addPhotoPattern, setAddPhotoPattern] = useState<boolean>(false);

    const [selectTheme, setSelectTheme] = useState<any>({id: null, name: ''});
    const [selectType, setSelectType] = useState<any>({id: null, name: '', theme_page_portfolio_id: ''});
    const [selectPattern, setSelectPattern] = useState<any>({id: null, name: ''});

    useEffect(() => {
        dispatch(getTerms()).then()
        dispatch(getPages()).then()
    }, [dispatch]);



    return (
        <div className="page-content">
            {isError && <Alert className="alert-danger mb-4">{isError}</Alert>}
            <Container fluid>
                <div className="d-lg-flex gap-1 mx-n4 mt-n4 p-1">
                    <NavigateMenuPortfolio setAddTheme={(value: boolean) => setAddTheme(value)}
                                           setAddType={(value: boolean) => setAddType(value)}
                                           setAddPattern={(value: boolean) => setAddPattern(value)}
                                           setAddPhotoPattern={(value: boolean) => setAddPhotoPattern(value)}
                                           setSelectTheme={(value: any) => setSelectTheme(value)}
                                           setSelectType={(value: any) => setSelectType(value)}
                                           setSelectPattern={(value: any) => setSelectPattern(value)}
                    />

                    <div className="w-100 overflow-hidden">
                        <div className="d-lg-flex">
                            <div className="w-100">
                                <div>
                                    <div className="mt-1 mt-sm-1 mt-lg-0 mt-xl-0 mt-xxl-0" id="users-chat">
                                        <Row >
                                            <Col>
                                                {addTheme && <AddThemePortfolio selectTheme={selectTheme}/>}
                                                {addType && <AddTypePortfolio selectType={selectType}/>}
                                                {addPattern && <AddPatternPortfolio selectPattern={selectPattern}/>}
                                                {addPattern && <AddPhotoPatternPortfolio selectPattern={selectPattern}/>}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </Container>
        </div>
    );
};

export default SettingsPortfolio;
