import React, {FC} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {
    Col,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";
import * as Yup from 'yup'

interface IProps {
    isAddTextureCategory: boolean,
    toggleIsAddTextureCategory: () => void,
    addTextureCategory: (name: string) => void
}
const ModalTextureCategoryAdd: FC<IProps> = ({toggleIsAddTextureCategory, isAddTextureCategory, addTextureCategory}) => {
    return (
        <>
            <Modal isOpen={isAddTextureCategory} toggle={toggleIsAddTextureCategory} centered>
                <ModalHeader toggle={toggleIsAddTextureCategory}>Добавление категории</ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={
                            {
                                name: '',
                            }
                        }
                        validationSchema={
                            Yup.object(
                                {
                                    name: Yup.string().required('Введите название категории'),
                                }
                            )
                        }
                        onSubmit={
                            (values, {resetForm}) => {
                                addTextureCategory(values.name)
                                resetForm()
                            }
                        }
                    >
                        <Form>
                            <Row>
                                <Col>
                                    <div className="mb-3">
                                        <Label htmlFor="nameInput"
                                               className="form-label">Имя категории</Label>
                                        <Field type="text" className="form-control" id="nameInput"
                                               name="name"
                                               placeholder="Введите имя категории"/>
                                        <ErrorMessage name="name">{(msg) => <div
                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} className="mt-3">
                                    <div className="hstack gap-2 justify-content-end">
                                        <button type="submit"
                                                className="btn btn-primary">Добавить
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    );
};

export default ModalTextureCategoryAdd;
