import React, { FC, useState, useRef, useEffect } from 'react';
import { Col, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { setIsSend } from "../../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../../layouts/UI/SubmitWithLoading";
import { useAppDispatch } from "../../../../hooks/redux";
import BigDescriptionPage from "../portfolioPage/BigDescriptionPage";
import TestImageHTML from "../portfolioPage/TestImageHTML";

interface IEditBigDescriptionPage {
    portfolio: any,
    setPortfolio(value: any): any,
    selectPagePortfolio: any,
    selectEditPagePortfolio: boolean,
    setSelectEditPagePortfolio(value: boolean): any
}

const EditBigDescriptionPage: FC<IEditBigDescriptionPage> = ({ portfolio, setPortfolio, selectEditPagePortfolio, setSelectEditPagePortfolio, selectPagePortfolio }) => {
    const dispatch = useAppDispatch();
    const [editPagePortfolio, setEditPagePortfolio] = useState<any>();
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [maxCharsPerLine, setMaxCharsPerLine] = useState<number>(0);

    // useEffect(() => {
    //     if (canvasRef.current) {
    //         const canvas = canvasRef.current;
    //         const ctx = canvas.getContext('2d');
    //         if (ctx) {
    //             const maxWidth = 2280;
    //             const font = "164px 'domino'";
    //             const charsPerLine = calculateMaxCharsPerLine(ctx, font, maxWidth);
    //             setMaxCharsPerLine(charsPerLine);
    //         }
    //     }
    // }, []);

    // const calculateMaxCharsPerLine = (ctx: CanvasRenderingContext2D, font: string, maxWidth: number): number => {
    //     ctx.font = font;
    //     let text = 'A';
    //     let metrics = ctx.measureText(text);
    //     let textWidth = metrics.width;
    //
    //     while (textWidth < maxWidth) {
    //         text += 'A';
    //         metrics = ctx.measureText(text);
    //         textWidth = metrics.width;
    //     }
    //     return text.length - 1;
    // };
    // const maxLength = maxCharsPerLine * 18;

    return (
        <>
            <Modal id="createProjectModal" isOpen={selectEditPagePortfolio}
                   toggle={() => setSelectEditPagePortfolio(!selectEditPagePortfolio)}
                   modalClassName="zoomIn" tabIndex={-1} centered >
                <ModalHeader toggle={() => setSelectEditPagePortfolio(!selectEditPagePortfolio)}
                             className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Изменение страницы «{selectPagePortfolio?.type_page_portfolio.name}»
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            description: selectPagePortfolio?.content ? JSON.parse(selectPagePortfolio?.content)?.description : '',
                        }}
                        validationSchema={
                            Yup.object({
                                description: Yup.string()
                                    // .max(maxLength, `Описание не должно превышать ${maxLength} символов`),
                            })
                        }
                        onSubmit={async (values) => {
                            dispatch(setIsSend(true));
                            await setEditPagePortfolio({
                                description: values.description,
                                uid: portfolio?.child_uid,
                                selectPage: selectPagePortfolio,
                                id_pattern: portfolio?.pattern_page_portfolio_id,
                            });

                            document.getElementById('handleCombineImagesBigDescriptionPage1')?.click();
                        }}
                    >
                        {
                            ({ errors, touched, setFieldValue }) => (
                                <Form>
                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">
                                                Описание:
                                                <Field
                                                    name="description"
                                                    as="textarea"
                                                    className={`form-control ${touched.description && errors.description ? 'is-invalid' : ''}`}
                                                    rows={6}
                                                />
                                            </label>
                                        </Col>
                                    </Row>
                                    <div className="hstack gap-2">
                                        <SubmitWithLoading text={'Готово'} />
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>
                </ModalBody>
            </Modal>
            <canvas ref={canvasRef} width={2480} height={3508} className="display-none" />
            <BigDescriptionPage
                dispatch={(value) => dispatch(value)}
                page={editPagePortfolio}
                setPortfolio={(value) => setPortfolio(value)}
                setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}
            />
        </>
    );
};

export default EditBigDescriptionPage;
