import React, {FC, useState} from 'react';
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

interface IProps {
    toggle: () => void
    isOpen: boolean,
    toggleSave: (name: string) => void
}
const ModalAddShot: FC<IProps> = ({toggle, isOpen, toggleSave}) => {
    const [nameShot, setNameShot] = useState('');
    return (
        <Modal isOpen={isOpen} toggle={toggle} centered >
            <ModalHeader toggle={toggle}>Название кадра</ModalHeader>
            <ModalBody>
                <Input
                    value={nameShot}
                    onChange={(event) => setNameShot(event.target.value)}
                    type="text"
                    placeholder="Название кадра"
                />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => {
                    toggleSave(nameShot)
                    setNameShot('')
                }}>
                    Сохранить
                </Button>{' '}
                <Button color="secondary" onClick={toggle}>
                    Отмена
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalAddShot;
