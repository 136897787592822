import React, {FC} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import AxiosClient from "../../../api/AxiosClient";

interface IModalConfirmPattern{
    idPattern: any,
    idPortfolio: any,
    showModalConfirmPattern: boolean,
    setShowModalConfirmPattern(value: boolean): any,
    portfolio: any,
    setPortfolio(value: any): any
}

const ModalConfirmPattern: FC <IModalConfirmPattern> = ({idPattern, idPortfolio, showModalConfirmPattern, setShowModalConfirmPattern, portfolio, setPortfolio}) => {

    function updatePattern () {
        AxiosClient.post(`update/pattern/parent/portfolio/${idPattern}`, {idPortfolio: idPortfolio})
            .then(r => {
                setPortfolio(r.data);
                setShowModalConfirmPattern(false);
            })
            .catch(() => console.log('error'))
    }

    function updateAllPage () {
        portfolio?.portfolio_pages.map((page: any) => {
            if (page.photo_page){

            }
        })

    }

    return (
        <>
            <Modal id="createProjectModal" isOpen={showModalConfirmPattern}
                   toggle={() => setShowModalConfirmPattern(!showModalConfirmPattern)}
                   modalClassName="zoomIn" tabIndex={-1} centered>
                <ModalHeader toggle={() => setShowModalConfirmPattern(!showModalConfirmPattern)}
                             className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Изменение шаблона портфолио
                </ModalHeader>
                <ModalBody>
                        <Row>
                            <p>Применить шаблон ко всем созданным страницам?</p>
                        </Row>
                        <Row>
                            <Col xxl={3} xl={3} lg={3} sm={6}>
                                <button className="btn btn-primary w-100 mt-1"
                                        onClick={() => {
                                            updateAllPage()
                                        }}
                                >Да</button>
                            </Col>
                            <Col xxl={3} xl={3} lg={3} sm={6}>
                                <button className="btn btn-primary w-100 mt-1"
                                        onClick={() => {
                                            updatePattern()
                                        }}
                                >Нет</button>
                            </Col>
                        </Row>
                </ModalBody>
            </Modal>
            
        </>
    );
};

export default ModalConfirmPattern;
