import React, {FC, useEffect, useState} from 'react'
import {Alert, Col, Collapse, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap"
import {Form, Formik} from "formik"
import {
    setDateAdd,
    setIsSend,
    setModalUpdateEmployee
} from "../../../store/reducers/widgets/WidgetsSlice"
import {useAppDispatch, useAppSelector} from "../../../hooks/redux"
import classNames from "classnames"
import Flatpickr from "react-flatpickr"
import {Russian} from "flatpickr/dist/l10n/ru"
import AxiosClient from "../../../api/AxiosClient"
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import downloadPDF from "../../../functions/downloadPDF";
import {convertDate} from "../../../functions/ConvertDate";

interface IModalUpdateEmployee {
    dates: any,
    setData: (data: any) => void,
    setDataUpdate: (data: any) => void
}

const ModalUpdateEmployee: FC<IModalUpdateEmployee> = ({dates, setData, setDataUpdate}) => {
    const dispatch = useAppDispatch()
    const {modalUpdateEmployee, dateAdd, date} = useAppSelector(state => state.WidgetReducer)
    const [collapse, setCollapse] = useState<string>('0')
    const [collapseSelect, setCollapseSelect] = useState<any[]>([])
    const [rezult, setRezult] = useState<any>()
    const [errorsIndicators, setErrorsIndicators] = useState<any[]>([]);
    const [modalContinue, setModalContinue] = useState(false);

    useEffect(() => {
        const rez = {
            date: dates?.data,
            uid: dates?.uid,
            employee_uid: dates?.employee_uid,
            employee_name: dates?.employee_name,
            position_name: dates?.position_name,
            position_uid: dates?.position_uid,
            indicators: dates?.indicators.map((indicator: any) => {
                return {
                    ...indicator,
                    marks: indicator.marks?.find((mark: any) => mark.uid === indicator.mark_uid) ? indicator.marks?.filter((mark: any) => mark.uid === indicator.mark_uid) : [],
                    documents: []
                }
            })
        }

        const select = dates?.indicators?.map((indicator: any) => {
            return {
                uid: indicator.uid,
                select: indicator.mark_uid === '00000000-0000-0000-0000-000000000000' || indicator.mark_uid === '' ? '' : indicator.mark_uid
            }
        })
        setCollapseSelect(select)

        setRezult(rez)
    }, [dates])

    function addRezult(indicatorUid: string, indicatorName: string, markUid: string) {

        const oldInd = rezult?.indicators?.length > 0 ? rezult?.indicators : []

        let marks: any[] = []
        if (markUid !== '') {
            marks = dates.indicators.find((ind: any) => ind.uid === indicatorUid).marks?.filter((mark: any) => mark.uid === markUid)
        }
        const settingsCount = dates.indicators.find((ind: any) => ind.uid === indicatorUid).settingsCount

        let rez = {
            date: dates?.data,
            uid: dates.uid,
            employee_uid: dates.employee_uid,
            employee_name: dates.employee_name,
            position_name: dates.position_name,
            position_uid: dates.position_uid,
            indicators:
                rezult?.indicators?.find((indicator: any) => indicator.uid === indicatorUid)
                    ?
                    rezult?.indicators?.map((indicator: any) => {
                        if (indicator.uid === indicatorUid) {
                            return {
                                ...indicator,
                                marks: marks
                            }
                        } else
                            return indicator
                    })
                    :
                    [...oldInd, {
                        uid: indicatorUid,
                        name: indicatorName,
                        settingsCount,
                        Count: settingsCount ? '' : 0,
                        description: '',
                        documents: [],
                        marks: marks
                    }]
        }

        let rez2 = {
            ...dates,
            indicators:
                dates?.indicators?.find((indicator: any) => indicator.uid === indicatorUid)
                    ?
                    dates?.indicators?.map((indicator: any) => {
                        if (indicator.uid === indicatorUid) {
                            return {
                                ...indicator,
                                mark_uid: markUid
                        }
                        } else
                            return indicator
                    })
                    :
                    dates?.indicators

        }

        setDataUpdate(rez2)

        setRezult(rez)
    }

    function addRezultFile(files: File[], indicatorUid: string, indicatorName: string, e: any) {
        files = [...files]
        files.forEach(file => {
            if(file.size > 5 * 1024 * 1024) { // Ограничение размера до 5МБ
                alert(`Файл ${file.name} превышает допустимый размер для загрузки. \nДопустимый размер файла, 5 МБ`);
                e.target.value = ''
                return
            }
        })
        const oldInd = rezult?.indicators?.length > 0 ? rezult?.indicators : []
        let documents: { name: string; url: string | ArrayBuffer | null; uidIndicator: string }[] = []


        files.forEach((file) => {
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = function () {
                documents.push({
                    name: `${file.name}[${indicatorUid}]`,
                    url: (reader.result as string)!.split(',')[1].toString(),
                    uidIndicator: indicatorUid
                })
            }
        })
        const settingsCount = dates.indicators.find((ind: any) => ind.uid === indicatorUid).settingsCount
        let rez = {
            date: dates?.data,
            uid: dates?.uid,
            employee_uid: dates.employee_uid,
            employee_name: dates.employee_name,
            position_name: dates.position_name,
            position_uid: dates.position_uid,
            indicators:
                rezult?.indicators?.find((indicator: any) => indicator.uid === indicatorUid)
                    ?
                    rezult?.indicators?.map((indicator: any) => {
                        if (indicator.uid === indicatorUid) {
                            return {
                                ...indicator,
                                documents: documents
                            }
                        } else
                            return indicator
                    })
                    :
                    [...oldInd, {
                        uid: indicatorUid,
                        name: indicatorName,
                        settingsCount,
                        Count: settingsCount ? '' : 0,
                        documents: documents,
                        marks: []
                    }]

        }

        setRezult(rez)
    }

    function addRezultDescription(value: string, indicatorUid: string, indicatorName: string) {
        const oldInd = rezult?.indicators?.length > 0 ? rezult?.indicators : []
        const settingsCount = dates.indicators.find((ind: any) => ind.uid === indicatorUid).settingsCount
        let rez = {
            date: dates?.data,
            uid: dates.uid,
            employee_uid: dates.employee_uid,
            employee_name: dates.employee_name,
            position_name: dates.position_name,
            position_uid: dates.position_uid,
            indicators:
                rezult?.indicators?.find((indicator: any) => indicator.uid === indicatorUid)
                    ?
                    rezult?.indicators?.map((indicator: any) => {
                        if (indicator.uid === indicatorUid) {
                            return {
                                ...indicator,
                                description: value
                            }
                        } else
                            return indicator
                    })
                    :
                    [...oldInd, {
                        uid: indicatorUid,
                        name: indicatorName,
                        description: value,
                        settingsCount,
                        Count: settingsCount ? '' : 0,
                        documents: [],
                        marks: []
                    }]

        }

        let rez2 = {
            ...dates,
            indicators:
                dates?.indicators?.find((indicator: any) => indicator.uid === indicatorUid)
                    ?
                    dates?.indicators?.map((indicator: any) => {
                        if (indicator.uid === indicatorUid) {
                            return {
                                ...indicator,
                                description: value
                            }
                        } else
                            return indicator
                    })
                    :
                    dates.indicators

        }

        setDataUpdate(rez2)

        setRezult(rez)
    }


    function addRezultCount(value: string, indicatorUid: string, indicatorName: string) {

        const oldInd = rezult?.indicators?.length > 0 ? rezult?.indicators : []
        const settingsCount = dates.indicators.find((ind: any) => ind.uid === indicatorUid).settingsCount

        let rez = {
            date: convertDate(dateAdd),
            uid: '',
            employee_uid: dates.employee_uid,
            employee_name: dates.employee_name,
            position_name: dates.position_name,
            position_uid: dates.position_uid,
            indicators:
                rezult?.indicators?.find((indicator: any) => indicator.uid === indicatorUid)
                    ?
                    rezult?.indicators?.map((indicator: any) => {
                        if (indicator.uid === indicatorUid) {
                            return {
                                ...indicator,
                                Count: value
                            }
                        } else
                            return indicator
                    })
                    :
                    [...oldInd, {
                        uid: indicatorUid,
                        name: indicatorName,
                        description: '',
                        settingsCount,
                        Count: value,
                        documents: [],
                        marks: []
                    }]

        }

        setRezult(rez)
    }

    function checkRezultIndicators() {
        let indicators: any[]
        if (rezult?.indicators) {
            indicators = dates?.indicators.map((i: any) => {
                if (rezult.indicators.find((k: any) => k.uid === i.uid)) {
                    return rezult.indicators.find((k: any) => k.uid === i.uid)
                } else {
                    return {
                        uid: i.uid,
                        name: i.name,
                        settingsCount: i.settingsCount,
                        Count: 0,
                        description: '',
                        documents: [],
                        marks: []
                    }
                }
            })
            const rez = {
                date: dates?.data,
                uid: dates?.uid,
                employee_uid: dates.employee_uid,
                employee_name: dates.employee_name,
                position_name: dates.position_name,
                position_uid: dates.position_uid,
                indicators: indicators
            }
            setRezult(rez)
            return rez
        } else {
            indicators = dates?.indicators?.map((i: any) => (
                {
                    uid: i.uid,
                    name: i.name,
                    description: '',
                    settingsCount: i.settingsCount,
                    Count: 0,
                    documents: [],
                    marks: []
                }
            ))
            const rez = {
                date: dates?.data,
                uid: dates?.uid,
                employee_uid: dates.employee_uid,
                employee_name: dates.employee_name,
                position_name: dates.position_name,
                position_uid: dates.position_uid,
                indicators
            }
            setRezult(rez)
            return rez
        }
    }

    function setSelect(indicatorUid: string, value: string): any[] {
        if (collapseSelect.find((i: any) => i.uid === indicatorUid)) {
            return collapseSelect.map((i: any) => {
                if (i.uid === indicatorUid) {
                    return {
                        uid: indicatorUid,
                        select: value
                    }
                }
                return i
            })
        } else {
            return [...collapseSelect, {uid: indicatorUid, select: value}]
        }
    }

    const print = () => {
        dispatch(setIsSend(true))
        AxiosClient.get('/print_employee_perfomance', {params: {uid: dates?.uid}})
            .then((d) => {
                dispatch(setIsSend(false))
                downloadPDF(d.data.base64, d.data.filename)
            })
            .catch(() => {
                console.log('error')
                dispatch(setIsSend(false))
            })
    }

    const checkSum = (indicatorUid: string, marks: any[]): string | number => {
        if (rezult?.indicators?.find((ind: any) => ind.uid === indicatorUid)?.marks?.length <= 0){
            return 0
        }
        return rezult?.indicators?.find((ind: any) => ind.uid === indicatorUid)?.Count * Math.max.apply(null, marks.map((i: any) => i.max))
    }

    const continueSave = () => {
        const rez = checkRezultIndicators()
        setModalContinue(false)
        dispatch(setIsSend(true))
        AxiosClient.post('/set_save_rezult_employee', {rezult: rez})
            .then(() => {
                dispatch(setIsSend(false))
                setErrorsIndicators([])
                AxiosClient.get('/get_rezult_employee_list', {params: {date}})
                    .then(r => {
                        setData(r.data)
                    })
                dispatch(setModalUpdateEmployee(false))
            })
            .catch((err) => {
                dispatch(setIsSend(false))
            })
    }

    const subscribe = () => {
        AxiosClient.post('/employee_potency_sign', {docId: dates?.uid})
            .then(() => {

            })
            .catch(() => {

            })
    }

    return (
        <Modal id="createProjectModal" isOpen={modalUpdateEmployee}
               toggle={() => dispatch(setModalUpdateEmployee(!modalUpdateEmployee))}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => dispatch(setModalUpdateEmployee(!modalUpdateEmployee))}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Обновить результаты сотрудника
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={
                        {}
                    }

                    onSubmit={() => {
                        const rez = checkRezultIndicators()
                        const errors = rez.indicators.map(el => {
                            if (el.settingsCount) {
                                if (el.marks.length > 0) {
                                    if (+el.Count === 0) {
                                        return {
                                            message: `В "${el.name}" укажите мероприятий больше 0`
                                        }
                                    }
                                    if (el.Count === '') {
                                        return {
                                            message: `В "${el.name}" укажите количество мероприятий`
                                        }
                                    }
                                }
                            }
                        }).filter(item => item)
                        if (errors.length > 0) {
                            setErrorsIndicators(errors)
                            setModalContinue(true)
                        } else {
                            dispatch(setIsSend(true))
                            AxiosClient.post('/set_save_rezult_employee', {rezult: rez})
                                .then(() => {
                                    dispatch(setIsSend(false))
                                    setErrorsIndicators([])
                                    AxiosClient.get('/get_rezult_employee_list', {params: {date}})
                                        .then(r => {
                                            setData(r.data)
                                        })
                                        .catch(() => console.log('error'))
                                    dispatch(setModalUpdateEmployee(false))
                                })
                                .catch(() => dispatch(setIsSend(false)))
                        }
                    }}
                >
                    <Form>
                        <div className="col-auto">
                            <label>Дата:</label>
                            <div className="col mb-3">
                                <div className="input-group col-auto">
                                    <Flatpickr
                                        className="form-control border-0 dash-filter-picker shadow"
                                        value={dateAdd}
                                        options={{
                                            locale: Russian, dateFormat: "d M Y", disableMobile: true
                                        }}
                                        onChange={(date) => dispatch(setDateAdd(date[0]))}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            errorsIndicators.length > 0 &&
                            <Alert color="danger">
                                {
                                    errorsIndicators.map(er => (
                                        <div>{er.message}</div>
                                    ))
                                }
                            </Alert>
                        }
                        <Col lg={12}>
                            <Row>
                                <Col>
                                    <div className="accordion accordion-border-box custom-scroll-news"
                                         id="genques-accordion" style={{height: '38rem', overflow: 'auto'}}>
                                        <div className="accordion-item shadow position-relative">
                                            <h2 className="accordion-header" id="genques-headingTwo">
                                                <button
                                                    className={classNames(
                                                        "accordion-button",
                                                        "fw-medium",
                                                        {collapsed: `notation` === collapse}
                                                    )}
                                                    type="button"
                                                    onClick={() => collapse === `notation` ? setCollapse('') : setCollapse(`notation`)}
                                                    style={{cursor: "pointer"}}
                                                >
                                                    Обозначения цветов
                                                </button>
                                            </h2>
                                            <Collapse isOpen={`notation` === collapse}
                                                      className="accordion-collapse">
                                                <div className="accordion-body fs-16">
                                                    <div className="d-flex align-items-center gap-4">
                                                        <div className="bg-primary rounded-circle" style={{width: 10, height: 10}}>
                                                        </div>
                                                        <p className="m-0 fw-medium text-black fs-14">
                                                            Раз в квартал
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-4">
                                                        <div className="bg-secondary rounded-circle" style={{width: 10, height: 10}}>
                                                        </div>
                                                        <p className="m-0 fw-medium text-black fs-14">
                                                            По итогам четверти
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-4">
                                                        <div className="bg-success rounded-circle" style={{width: 10, height: 10}}>
                                                        </div>
                                                        <p className="m-0 fw-medium text-black fs-14">
                                                            Раз в год
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-4">
                                                        <div className="bg-danger rounded-circle" style={{width: 10, height: 10}}>
                                                        </div>
                                                        <p className="m-0 fw-medium text-black fs-14">
                                                            По факту
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-4">
                                                        <div className="bg-warning rounded-circle" style={{width: 10, height: 10}}>
                                                        </div>
                                                        <p className="m-0 fw-medium text-black fs-14">
                                                            Ежемесячно
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-4">
                                                        <div className="bg-info rounded-circle" style={{width: 10, height: 10}}>
                                                        </div>
                                                        <p className="m-0 fw-medium text-black fs-14">
                                                            Раз в пол года
                                                        </p>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                        {
                                            dates?.indicators?.map((indicator: any) => {
                                                return <div className="accordion-item shadow position-relative" key={indicator.uid}>
                                                    <div className={classNames('position-absolute rounded-start h-100', {
                                                        'bg-primary': indicator?.period === 'Раз в квартал',
                                                        'bg-secondary': indicator?.period === 'По итогам четверти',
                                                        'bg-success': indicator?.period === 'Раз в год',
                                                        'bg-danger': indicator?.period === 'По факту',
                                                        'bg-warning': indicator?.period === 'Ежемесячно',
                                                        'bg-info': indicator?.period === 'Раз в пол года',
                                                    })} style={{width: 5}}>
                                                    </div>
                                                    <h2 className="accordion-header" id="genques-headingTwo">
                                                        <button
                                                            className={classNames(
                                                                "accordion-button",
                                                                "fw-medium",
                                                                {collapsed: `${indicator.uid}` === collapse}
                                                            )}
                                                            type="button"
                                                            onClick={() => collapse === `${indicator.uid}` ? setCollapse('') : setCollapse(`${indicator.uid}`)}
                                                            style={{cursor: "pointer"}}
                                                        >
                                                            <i className="bx bx-question-mark fs-3 bg-white mx-2 rounded-circle d-none d-sm-block" title={indicator?.period}></i>
                                                            {indicator.name}
                                                        </button>
                                                    </h2>
                                                    <Collapse isOpen={`${indicator.uid}` === collapse}
                                                              className="accordion-collapse">
                                                        <div className="accordion-body fs-16">
                                                            <select name="" id="" className="form-select"
                                                                    value={collapseSelect?.find((i: any) => i.uid === indicator.uid)?.select ?? ''}
                                                                    onChange={(e) => {
                                                                        setCollapseSelect(setSelect(indicator.uid, e.target.value))
                                                                        addRezult(indicator.uid, indicator.name, e.target.value)
                                                                    }}>
                                                                <option value=''>Выберите значение</option>
                                                                {
                                                                    indicator.marks.map((mark: any) => {
                                                                        return <option key={mark.uid}
                                                                                       value={mark.uid}>{mark.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            {
                                                                indicator.marks.map((mark: any) => collapseSelect &&
                                                                    (<Collapse
                                                                            isOpen={!!collapseSelect.find((i: any) => i.select === mark.uid && indicator.uid === i.uid && i.select)}
                                                                            className="accordion-collapse" name="marks"
                                                                            key={mark.uid}>
                                                                            <div
                                                                                className="accordion-body fs-16">
                                                                                <div className="d-flex gap-1">
                                                                                    <label>
                                                                                        Min
                                                                                        <input type="text"
                                                                                               className="form-control"
                                                                                               placeholder='Min'
                                                                                               name='min'
                                                                                               disabled
                                                                                               defaultValue={Math.min.apply(null, indicator.marks.map((i: any) => i.min))}/>
                                                                                    </label>
                                                                                    <label>
                                                                                        Max
                                                                                        <input type="text"
                                                                                               className="form-control"
                                                                                               placeholder='Max'
                                                                                               disabled
                                                                                               defaultValue={Math.max.apply(null, indicator.marks.map((i: any) => i.max))}
                                                                                        />
                                                                                    </label>
                                                                                </div>
                                                                                <div className="d-flex gap-1">
                                                                                    <label>
                                                                                        Принятый балл
                                                                                        <input type="text"
                                                                                               className="form-control"
                                                                                               placeholder='Принятый балл'
                                                                                               disabled
                                                                                               defaultValue={indicator.depend_score + indicator.independ_score}
                                                                                        />
                                                                                    </label>
                                                                                    <label>
                                                                                        Балл
                                                                                        <input type="text"
                                                                                               className="form-control"
                                                                                               placeholder='Балл'
                                                                                               disabled
                                                                                               defaultValue={mark.max}
                                                                                        />
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </Collapse>
                                                                    ))
                                                            }
                                                            {
                                                                indicator.settingsCount &&
                                                                <>
                                                                    <div>
                                                                        <label>
                                                                            Количество мероприятий
                                                                        </label>
                                                                        <input
                                                                            type={"number"}
                                                                            name={indicator.uid}
                                                                            className="form-control"
                                                                            placeholder='Количество мероприятий'
                                                                            defaultValue={indicator.Count}
                                                                            style={{resize: "none"}}
                                                                            onChange={(e) => addRezultCount(e.target.value, indicator.uid, indicator.name)}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <label>
                                                                            Сумма баллов
                                                                        </label>
                                                                        <input
                                                                            type={"number"}
                                                                            name={indicator.uid}
                                                                            className="form-control"
                                                                            placeholder='Сумма баллов'
                                                                            style={{resize: "none"}}
                                                                            value={checkSum(indicator.uid, indicator.marks)}
                                                                        />
                                                                    </div>
                                                                </>

                                                            }
                                                            <div>
                                                                <label>
                                                                    Описание
                                                                </label>
                                                                <textarea
                                                                    rows={4}
                                                                    value={indicator.description}
                                                                    className="form-control"
                                                                    placeholder='Описание к показателю'
                                                                    style={{resize: "none"}}
                                                                    onChange={(e) => addRezultDescription(e.target.value, indicator.uid, indicator.name)}
                                                                />
                                                            </div>
                                                            <div className="mb-3">
                                                                <Label className="form-label">Список
                                                                    документов</Label>
                                                                <input type="file" className="form-control" multiple
                                                                       onChange={(e: any) => {
                                                                           addRezultFile(e.target.files, indicator.uid, indicator.name, e)
                                                                       }}/>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            })
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <div className="hstack gap-2 justify-content-end mt-2">
                            <SubmitWithLoading text={"Подписать"} onClick={() => subscribe()}/>
                            <SubmitWithLoading text={"Печать"} onClick={() => print()}/>
                            <SubmitWithLoading text={'Сохранить и отправить в 1С'}/>
                        </div>
                    </Form>
                </Formik>
                <Modal id="createProjectModal" isOpen={modalContinue}
                       toggle={() => setModalContinue(!modalContinue)}
                       modalClassName="zoomIn" tabIndex={-1} centered>
                    <ModalHeader toggle={() => setModalContinue(!modalContinue)} className="p-3 bg-soft-primary"
                                 id="createProjectModalLabel">
                        У вас {errorsIndicators.length} ошибок
                    </ModalHeader>
                    <ModalBody>
                        {
                            errorsIndicators.length > 0 &&
                            <Alert color="danger">
                                {
                                    errorsIndicators.map(er => (
                                        <div>{er.message}</div>
                                    ))
                                }
                            </Alert>
                        }
                        <div className="fs-5">
                            Все равно отправить результативность сотрудника в 1С?
                        </div>
                        <div className="d-flex justify-content-end gap-2">
                            <div className="hstack gap-2 justify-content-end mt-2">
                                <button className="btn btn-primary" onClick={() => setModalContinue(false)}>Нет</button>
                            </div>
                            <div className="hstack gap-2 justify-content-end mt-2">
                                <button className="btn btn-primary" onClick={() => continueSave()}>Отправить</button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </ModalBody>
        </Modal>
    )
}

export default ModalUpdateEmployee
