import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import AxiosClient from "../../../../api/AxiosClient";
import {toPng} from "html-to-image";
import {setIsSend} from "../../../../store/reducers/widgets/WidgetsSlice";

interface IMyFriends{
    dispatch(value: any): any,
    page: any,
    setPortfolio(value: any): any,
    setSelectEditPagePortfolio(value: boolean) : any
}

const MyFriends:  FC <IMyFriends> = ({dispatch, page, setPortfolio, setSelectEditPagePortfolio}) => {
    const [images, setImages] = useState<{img1: any, img2: any, img3: any, img4: any, img5: any}>({ img1: '', img2: '', img3: '', img4: '', img5: ''});
    const ref = useRef<HTMLDivElement>(null);

    const handleCombineImagesMyFriends = () => {
        AxiosClient.post('get/pattern/portfolio', { nameImage: 'treeFamily.png',  id_type: page?.selectPage?.type_page_portfolio_id, id_pattern: page?.id_pattern})
            .then(r => {
                let img2 = page?.photo_download_base64?.[0];
                let img3 = page?.photo_download_base64?.[1];
                let img4 = page?.photo_download_base64?.[2];
                let img5 = page?.photo_download_base64?.[3];

                if (img2 && !img2.startsWith('data:image/png;base64,')) {
                    img2 = 'data:image/png;base64,' + img2;
                }
                if (img3 && !img3.startsWith('data:image/png;base64,')) {
                    img3 = 'data:image/png;base64,' + img3;
                }
                if (img4 && !img4.startsWith('data:image/png;base64,')) {
                    img4 = 'data:image/png;base64,' + img4;
                }
                if (img5 && !img5.startsWith('data:image/png;base64,')) {
                    img5 = 'data:image/png;base64,' + img5;
                }

                setImages({ img1: r.data.img1, img2: img2, img3: img3, img4: img4, img5: img5});
            })
            .catch(() => console.log('error'));
    };

    useEffect(() => {
        onButtonClick();
    }, [images])

    const onButtonClick = useCallback(() => {
        const image = new Image();
        image.src = `data:image/png;base64,${images.img1}`;
        image.onload = () => {
            if (ref.current === null || !images.img1 || !images.img2 || !images.img3 || !images.img4 || !images.img5) {
                return;
            }
            toPng(ref.current, { cacheBust: true })
                .then((dataUrl) => {
                    fetch(dataUrl)
                        .then(res => res.blob())
                        .then(blob => {
                            const formData = new FormData();
                            formData.append('photo_page', blob);
                            formData.append('id_portfolio', page?.selectPage.portfolio_child_id);
                            formData.append('uid', page?.uid);
                            const content = {
                                name1: page.name1,
                                name2:  page.name2,
                                name3:  page.name3,
                                name4:  page.name4,
                            };
                            formData.append('content', JSON.stringify(content));
                            formData.append('photo1', page.photo);
                            formData.append('photo2', page.photo2);
                            formData.append('photo3', page.photo3);
                            formData.append('photo4', page.photo4);
                            formData.append('countPhoto', (page.photo || page.photo2 || page.photo3 || page.photo4) ? '4' : '0');
                            formData.append('list_path_photo', page?.path_photo);
                            formData.append('path_photo_page', page?.path_photo_page);

                            AxiosClient.post(`update/page/parent/portfolio/${page.selectPage.id}`, formData, { headers: { "Content-Type": 'multipart/form-data' } })
                                .then(r => {
                                    setPortfolio(r.data);
                                    setSelectEditPagePortfolio(false);
                                    dispatch(setIsSend(false))
                                })
                                .catch(error => {
                                    console.error("Ошибка при выгрузке страницы:", error);
                                    dispatch(setIsSend(false))
                                });
                        })
                        .catch(error => console.error('Ошибка при создании Blob:', error));
                })
                .catch((err) => {
                    console.log(err);
                });
        };
    }, [ref, images, page, dispatch, setPortfolio, setSelectEditPagePortfolio]);


    return (
        < div
            ref={ref}
            style={{
                position: 'relative',
                height: '3508px',
                width: '2480px',
                zIndex: 1,
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    marginLeft: '50px',
                    marginTop: '678px',
                    width: '935px',
                    height: '835px',
                    backgroundImage: `url('${images.img2}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    zIndex: 2,
                }}
            ></div>
            <div
                style={{
                    position: 'absolute',
                    zIndex: 4,
                    fontSize: '72px',
                    fontFamily: 'domino',
                    textAlign: 'center',
                    color: '#491584',
                    marginLeft: '105px',
                    marginTop: '1440px',
                    width: '825px',
                    padding: '20px',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    borderRadius: '20px',
                    boxShadow: '0 0 30px rgba(0, 0, 0, 0.7)',
                }}
            >
                <p>{page?.name1}</p>
            </div>
            <div
                style={{
                    position: 'absolute',
                    marginLeft: '1110px',
                    marginTop: '485px',
                    width: '935px',
                    height: '935px',
                    backgroundImage: `url('${images.img3}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    zIndex: 2,
                }}
            ></div>
            <div
                style={{
                    position: 'absolute',
                    zIndex: 4,
                    fontSize: '72px',
                    fontFamily: 'domino',
                    textAlign: 'center',
                    color: '#491584',
                    marginLeft: '1165px',
                    marginTop: '1265px',
                    width: '825px',
                    padding: '20px',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    borderRadius: '20px',
                    boxShadow: '0 0 30px rgba(0, 0, 0, 0.7)',
                }}
            >
                <p>{page?.name2}</p>
            </div>
            <div
                style={{
                    position: 'absolute',
                    marginLeft: '390px',
                    marginTop: '1640px',
                    width: '935px',
                    height: '935px',
                    backgroundImage: `url('${images.img4}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    zIndex: 2,
                }}
            ></div>
            <div
                style={{
                    position: 'absolute',
                    zIndex: 4,
                    fontSize: '72px',
                    fontFamily: 'domino',
                    textAlign: 'center',
                    color: '#491584',
                    marginLeft: '445px',
                    marginTop: '2420px',
                    width: '825px',
                    padding: '20px',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    borderRadius: '20px',
                    boxShadow: '0 0 30px rgba(0, 0, 0, 0.7)',
                }}
            >
                <p>{page?.name3}</p>
            </div>
            <div
                style={{
                    position: 'absolute',
                    marginLeft: '1465px',
                    marginTop: '1480px',
                    width: '935px',
                    height: '935px',
                    backgroundImage: `url('${images.img5}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    zIndex: 2,
                }}
            ></div>
            <div
                style={{
                    position: 'absolute',
                    zIndex: 4,
                    fontSize: '72px',
                    fontFamily: 'domino',
                    textAlign: 'center',
                    color: '#491584',
                    marginLeft: '1520px',
                    marginTop: '2235px',
                    width: '825px',
                    padding: '20px',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    borderRadius: '20px',
                    boxShadow: '0 0 30px rgba(0, 0, 0, 0.7)',
                }}
            >
                <p>{page?.name4}</p>
            </div>

            <div
                ref={ref}
                style={{
                    position: 'absolute',
                    padding: '10px',
                    height: '3508px',
                    width: '2480px',
                    backgroundImage: `url('data:image/png;base64,${images.img1}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    zIndex: 3,
                }}
            >
            </div>
            <button onClick={handleCombineImagesMyFriends} id='handleCombineImagesMyFriends'>Click me</button>
        </div>
    );
};

export default MyFriends;
