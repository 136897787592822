import React, {FC, useEffect, useState} from 'react';
import {Col, Input, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classNames from "classnames";
import {IPersonage} from "../../../models/game/IPersonage";

interface IProps {
    openSettingsPersonage: boolean
    handleOpenSettingsPersonage: () => void
    selectPersonage: IPersonage
    saveSettingsPersonage: (personage: IPersonage) => void,
}
const SettingsPersonage: FC<IProps> = ({openSettingsPersonage, handleOpenSettingsPersonage, selectPersonage, saveSettingsPersonage}) => {
    const [personage, setPersonage] = useState<IPersonage>(selectPersonage);
    const [activeTab, setActiveTab] = useState('1');

    useEffect(() => {
        setPersonage(selectPersonage)
    }, [selectPersonage]);

    const save = () => {
        saveSettingsPersonage(personage)
        handleOpenSettingsPersonage()
    }

    return (
        openSettingsPersonage ?
            <div className="position-absolute top-0 start-0 h-100 w-100 bg-white col-xxl-3">
                <div>
                    <i className="bx bx-x fs-1 cursor-pointer"
                       onClick={handleOpenSettingsPersonage}>
                    </i>
                </div>
                <div className="mt-3">
                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                         role="tablist">
                        <NavItem>
                            <NavLink
                                className={classNames('p-2', {active: activeTab === "1"})}
                                onClick={() => {
                                    setActiveTab("1");
                                }}
                                type="button">
                                <i className="fas fa-home"></i>
                                Основная информация
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <div>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <div className="p-2">
                                <div className="mt-2">
                                    <Row>
                                        <Col sm={12}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label htmlFor="name" className="form-label">Название
                                                        текстуры</label>
                                                    <input type="text" id="name" value={personage.pivot.name}
                                                           onChange={(event) => setPersonage((texture) => ({
                                                               ...texture,
                                                               pivot: {...texture.pivot, name: event.target.value}
                                                           }))} className="form-control"/>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </TabPane>
                    </TabContent>
                    <div className="mx-2">
                        <button className="btn btn-primary" onClick={save}>Сохранить</button>
                    </div>
                </div>
            </div> : <></>
    )
};

export default SettingsPersonage;
