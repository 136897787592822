import React, {FC} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {
    Col,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";
import * as Yup from 'yup'

interface IProps {
    isAddSound: boolean,
    toggleIsAddSound: () => void,
    addSound: (data: FormData) => void
    categoryBackgroundSounds: {id: number, name: string}[]
}
const ModalBackgroundSoundAdd: FC<IProps> = ({toggleIsAddSound, isAddSound, addSound, categoryBackgroundSounds}) => {
    return (
        <>
            <Modal isOpen={isAddSound} toggle={toggleIsAddSound} centered>
                <ModalHeader toggle={toggleIsAddSound}>Добавление звука заднего фона</ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={
                            {
                                name: '',
                                file: null,
                                category_background_sound_id: 'null'
                            }
                        }
                        validationSchema={
                            Yup.object(
                                {
                                    name: Yup.string().required('Введите название заднего фона'),
                                    file: Yup.mixed().required('Загрузите файл заднего фона')
                                }
                            )
                        }
                        onSubmit={
                            (values, {resetForm}) => {
                                const formData = new FormData();
                                if (values.file) {
                                    formData.append('backgroundAudio', values.file)
                                    formData.append('name', values.name);
                                    formData.append('category_background_sound_id', values.category_background_sound_id);
                                }
                                addSound(formData)
                                resetForm()
                            }
                        }
                    >
                        {
                            ({setFieldValue}) => (
                                <Form>
                                    <Row>
                                        <Col>
                                            <div className="mb-3">
                                                <Label htmlFor="textureLabel"
                                                       className="form-label">Файл звука заднего фона</Label>
                                                <div>
                                                    <input id="textureLabel" name="file" type="file" className="form-control"
                                                           onChange={(event) => {
                                                               setFieldValue("file", event.currentTarget.files ? event.currentTarget.files[0] : null);
                                                           }}/>
                                                </div>
                                                <ErrorMessage name="file">{(msg) => <div
                                                    className="text-danger">{msg}</div>}</ErrorMessage>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="mb-3">
                                                <Label htmlFor="categoryInput"
                                                       className="form-label">Категория</Label>
                                                <Field as="select" className="form-control" id="categoryInput"
                                                       name="category_background_sound_id">
                                                    <option value={'null'}>Без категории</option>
                                                    {
                                                        categoryBackgroundSounds.map((el) => (
                                                            <option key={el.id} value={el.id}>{el.name}</option>
                                                        ))
                                                    }
                                                </Field>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="mb-3">
                                                <Label htmlFor="nameInput"
                                                       className="form-label">Имя звука заднего фона</Label>
                                                <Field type="text" className="form-control" id="nameInput"
                                                       name="name"
                                                       placeholder="Введите имя звука заднего фона"/>
                                                <ErrorMessage name="name">{(msg) => <div
                                                    className="text-danger">{msg}</div>}</ErrorMessage>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className="mt-3">
                                            <div className="hstack gap-2 justify-content-end">
                                                <button type="submit"
                                                        className="btn btn-primary">Добавить
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            )
                        }
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    );
};

export default ModalBackgroundSoundAdd;
