import React, {FC, useEffect, useState} from 'react';
import {Alert, Badge, Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classNames from "classnames";
import {ITexture} from "../../../models/game/ITexture";
import AxiosClient from "../../../api/AxiosClient";
import {IPersonage} from "../../../models/game/IPersonage";
import {IBackgroundImage} from "../../../models/game/IBackgroundImage";
import {IBackgroundSound} from "../../../models/game/IBackgroundSound";
import {IShot} from "../../../models/game/IShot";
import {TypeSettings} from "../../../models/game/typesSettings/TypeSettings";
import {TypeSettingsSelectAnswer} from "../../../models/game/typesSettings/TypeSettingsSelectAnswer";
import {TypeSettingsShot} from "../../../models/game/typesSettings/TypeSettingsShot";
import TextureList from "../UploadTexture/settingTexture/TextureList";
import ModalTextureAdd from "../UploadTexture/settingTexture/ModalTextureAdd";
import PersonageList from "../UploadTexture/settingPersonage/PersonageList";
import ModalPersonageAdd from "../UploadTexture/settingPersonage/ModalPersonageAdd";
import BackgroundList from "../UploadTexture/settingBackground/BackgroundList";
import ModalBackgroundAdd from "../UploadTexture/settingBackground/ModalBackgroundAdd";
import BackgroundSoundList from "../UploadTexture/settingsBackgroundSound/BackgroundSoundList";
import ModalBackgroundSoundAdd from "../UploadTexture/settingsBackgroundSound/ModalBackgroundSoundAdd";
import ModalBackgroundCategoryAdd from "../UploadTexture/settingBackground/ModalBackgroundCategoryAdd";
import ModalBackgroundUpdate from "../UploadTexture/settingBackground/ModalBackgroundUpdate";
import ModalBackgroundSoundCategoryAdd from "../UploadTexture/settingsBackgroundSound/ModalBackgroundSoundCategoryAdd";
import ModalBackgroundSoundUpdate from "../UploadTexture/settingsBackgroundSound/ModalBackgroundSoundUpdate";
import ModalPersonageCategoryAdd from "../UploadTexture/settingPersonage/ModalPersonageCategoryAdd";
import ModalPersonageUpdate from "../UploadTexture/settingPersonage/ModalPersonageUpdate";
import ModalTextureCategoryAdd from "../UploadTexture/settingTexture/ModalTextureCategoryAdd";
import ModalTextureUpdate from "../UploadTexture/settingTexture/ModalTextureUpdate";
import {IGame} from "../../../models/game/IGame";
import {Editor, EditorState, RichUtils} from 'draft-js';
import 'draft-js/dist/Draft.css'; // Стили для Draft.js
interface IProps {
    openSettings: boolean,
    handleOpenSettings: () => void,
    selectedShot: IShot,
    game: Omit<IGame, 'id'>,
    saveSettingsShot: (shot: IShot) => void
    setGame: (game: Omit<IGame, 'id'>) => void
}

const SettingsShot: FC<IProps> = ({openSettings, handleOpenSettings, selectedShot, saveSettingsShot, game, setGame}) => {
    const [shot, setShot] = useState<IShot>(selectedShot);
    const [wTexture, setWTexture] = useState(0);
    const [hTexture, setHTexture] = useState(0);
    const [activeTab, setActiveTab] = useState('1');
    const [textures, setTextures] = useState<ITexture[]>([]);
    const [personages, setPersonages] = useState<IPersonage[]>([]);
    const [backgroundImages, setBackgroundImages] = useState<IBackgroundImage[]>([]);
    const [backgroundSounds, setBackgroundSounds] = useState<IBackgroundSound[]>([]);
    const [categoryBackgrounds, setCategoryBackgrounds] = useState<{ id: number, name: string, backgrounds_count: number }[]>([]);
    const [backgroundsFilter, setBackgroundsFilter] = useState<IBackgroundImage[]>([]);
    const [backgroundsSoundFilter, setBackgroundsSoundFilter] = useState<IBackgroundSound[]>([]);
    const [personagesFilter, setPersonagesFilter] = useState<IPersonage[]>([]);
    const [texturesFilter, setTexturesFilter] = useState<ITexture[]>([]);
    const [categoryBackgroundSounds, setCategoryBackgroundSounds] = useState<{ id: number, name: string, background_sounds_count: number }[]>([]);
    const [categoryTextures, setCategoryTextures] = useState<{ id: number, name: string, textures_count: number }[]>([]);
    const [categoryPersonages, setCategoryPersonages] = useState<{ id: number, name: string,  personages_count: number }[]>([]);
    const [activeTabSettings, setActiveTabSettings] = useState('0');
    const [activeTabUploadTexture, setActiveTabUploadTexture] = useState("1");
    const [isAddTexture, setIsAddTexture] = useState(false);
    const [error, setError] = useState({show: false, message: ''});
    const [isAddPersonage, setIsAddPersonage] = useState(false);
    const [isAddBackground, setIsAddBackground] = useState(false);
    const [isAddBackgroundSound, setIsAddBackgroundSound] = useState(false);
    const [isAddCategoryBackground, setIsAddCategoryBackground] = useState(false);
    const [selectBackground, setSelectBackground] = useState<IBackgroundImage | null>(null);
    const [isUpdateBackground, setIsUpdateBackground] = useState(false);
    const [isAddCategoryBackgroundSound, setIsAddCategoryBackgroundSound] = useState(false);
    const [selectBackgroundSound, setSelectBackgroundSound] = useState<IBackgroundSound | null>(null);
    const [isUpdateBackgroundSound, setIsUpdateBackgroundSound] = useState(false);
    const [isAddCategoryPersonage, setIsAddCategoryPersonage] = useState(false);
    const [selectPersonage, setSelectPersonage] = useState<IPersonage | null>(null);
    const [isUpdatePersonage, setIsUpdatePersonage] = useState(false);
    const [isAddCategoryTexture, setIsAddCategoryTexture] = useState(false);
    const [selectTexture, setSelectTexture] = useState<ITexture | null>(null);
    const [isUpdateTexture, setIsUpdateTexture] = useState(false);
    const [editorState, setEditorState] = useState<EditorState>(
        () => EditorState.createEmpty()
    );

    // Обработчик изменения состояния редактора
    const handleEditorChange = (state: EditorState) => {
        setEditorState(state);
    };
    useEffect(() => {
        AxiosClient.get<ITexture[]>('/game/textures')
            .then((r) => {
                setTextures(r.data)
                setTexturesFilter(r.data)
            })
        AxiosClient.get<IPersonage[]>('/game/personages')
            .then((r) => {
                setPersonages(r.data)
                setPersonagesFilter(r.data)
            })
        AxiosClient.get<IBackgroundImage[]>('/game/backgroundImage')
            .then((r) => {
                setBackgroundImages(r.data)
                setBackgroundsFilter(r.data)
            })
        AxiosClient.get<IBackgroundSound[]>('/game/backgroundAudio')
            .then((r) => {
                setBackgroundSounds(r.data)
                setBackgroundsSoundFilter(r.data)
            })
        AxiosClient.get<{ id: number, name: string, backgrounds_count: number }[]>('/game/category_backgrounds')
            .then((r) => {
                setCategoryBackgrounds(r.data)
            })
        AxiosClient.get<{ id: number, name: string, background_sounds_count: number }[]>('/game/category_background_sounds')
            .then((r) => setCategoryBackgroundSounds(r.data))
        AxiosClient.get<{ id: number, name: string, textures_count: number }[]>('/game/category_textures')
            .then((r) => setCategoryTextures(r.data))
        AxiosClient.get<{ id: number, name: string, personages_count: number }[]>('/game/category_personages')
            .then((r) => setCategoryPersonages(r.data))
    }, []);

    useEffect(() => {
        setWTexture(selectedShot.widthTextures)
        setHTexture(selectedShot.heightTextures)
        setShot(selectedShot)
    }, [selectedShot]);

    const save = () => {
        saveSettingsShot(shot)
        handleOpenSettings()
    }

    const handleAddPersonage = (personage: IPersonage) => {
        if (shot.personages.find(el => el.id === personage.id)) {
            setShot((shot) => ({
                ...shot,
                personages: shot.personages.filter(el => el.id !== personage.id)
            }))
        } else {
            setShot((shot) => ({
                ...shot, personages: [...shot.personages,
                    {
                        ...personage,
                        pivot: {
                            ...personage.pivot,
                            x: 0,
                            y: 0,
                            percentX: 0,
                            percentY: 0,
                            name: 'Персонаж' + shot.textures.length + 1,
                            size: 1,
                            width: 200,
                            height: 200
                        }
                    }]
            }))
        }
    }

    const handleAddTexture = (texture: ITexture) => {
        if (shot.textures.find(el => el.id === texture.id)) {
            setShot((shot) => ({
                ...shot,
                textures: shot.textures.filter(el => el.id !== texture.id)
            }))
        } else {
            setShot((shot) => ({
                ...shot, textures: [...shot.textures,
                    {
                        ...texture,
                        pivot: {
                            ...texture.pivot,
                            x: 0,
                            y: 0,
                            percentX: 0,
                            percentY: 0,
                            name: 'Текстура' + shot.textures.length + 1,
                            description: '',
                            size: 1,
                            settings: {
                                type: TypeSettings.NO_SETTINGS,
                                settings: {
                                    select: TypeSettingsSelectAnswer.TRUE,
                                    text: '',
                                    path: ''
                                },
                                width: shot.widthTextures,
                                height: shot.heightTextures,
                                typeAnimation: 'none',
                            }
                        }
                    }]
            }))
        }
    }

    const updateWidthHeightTextures = () => {
        setShot((shot) => ({
            ...shot,
            widthTextures: wTexture,
            heightTextures: hTexture,
            textures: shot.textures.map((texture) => (
                {
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: {
                            ...texture.pivot.settings,
                            width: wTexture,
                            height: hTexture
                        }
                    }
                }
            ))
        }))
    }

    const handleAddBackgroundImage = (backgroundImage: IBackgroundImage) => {
        if (shot.backgroundImage) {
            setShot((shot) => ({
                ...shot,
                backgroundImage: null
            }))
        } else {
            setShot((shot) => ({...shot, backgroundImage: backgroundImage}))
        }
    }

    const handleAddBackgroundSound = (backgroundSound: IBackgroundSound) => {
        if (shot.backgroundSound) {
            setShot((shot) => ({
                ...shot,
                backgroundSound: null
            }))

            const indexSelectShot = game.shots.findIndex(el => el.name === shot.name)
            let stop = false;
            const newGame: Omit<IGame, 'id'> = {
                ...game,
                shots: game.shots.map((el, index) => {
                    if (index > indexSelectShot && !stop) {
                        if (el.useSoundBack) {
                            return {
                                ...el,
                                backgroundSound: null
                            }
                        } else {
                            stop = true
                        }
                    }
                    return el
                })
            }
            setGame(newGame)
        } else {
            setShot((shot) => ({...shot, backgroundSound: backgroundSound}))
            const indexSelectShot = game.shots.findIndex(el => el.name === shot.name)
            let stop = false;
            const newGame: Omit<IGame, 'id'> = {
                ...game,
                shots: game.shots.map((el, index) => {
                    if (index > indexSelectShot && !stop) {
                        if (el.useSoundBack) {
                            return {
                                ...el,
                                backgroundSound: backgroundSound
                            }
                        } else {
                            stop = true
                        }
                    }
                    return el
                })
            }
            setGame(newGame)
        }
    }

    const handleActiveTabSettings = (value: string) => {
        const settingsCountTexture = {
            type: TypeSettingsShot.COUNT_TEXTURE,
            settings: {
                textTrue: '',
                pathTrue: '',
                textFalse: '',
                pathFalse: '',
                answer: ''
            }
        }
        if (value === "0") {
            setShot((shot) => ({...shot, settings: null}))
        }
        if (value === "1") {
            setShot((shot) => ({...shot, settings: settingsCountTexture}))
        }

        setActiveTabSettings(value)
    }

    const removeTexture = (id: number) => {
        AxiosClient.post(`/game/textures/delete/${id}`)
            .then((r) => setTextures((prevState) => prevState.filter((el) => el.id !== id)))
            .catch((reason) => {
                setError({
                    show: true,
                    message: reason.response.data.message
                })
            })
    }

    const onFilterBackgrounds = (id: number | null | 'all') => {
        if(id === 'all') {
            setBackgroundsFilter(backgroundImages)
        } else {
            setBackgroundsFilter(backgroundImages.filter(el => el.category_background_id === id))
        }
    }

    const onFilterBackgroundsSound = (id: number | null | 'all') => {
        if(id === 'all') {
            setBackgroundsSoundFilter(backgroundSounds)
        } else {
            setBackgroundsSoundFilter(backgroundSounds.filter(el => el.category_background_sound_id === id))
        }
    }

    const onFilterPersonages = (id: number | null | 'all') => {
        if(id === 'all') {
            setPersonagesFilter(personages)
        } else {
            setPersonagesFilter(personages.filter(el => el.category_personage_id === id))
        }
    }

    const onFilterTextures = (id: number | null | 'all') => {
        if(id === 'all') {
            setTexturesFilter(textures)
        } else {
            setTexturesFilter(textures.filter(el => el.category_texture_id === id))
        }
    }

    const addTexture = (data: FormData) => {
        AxiosClient.post(`/game/upload_texture_game`, data, {headers: {"Content-Type": "multipart/form-data"}})
            .then((r) => {
                setTextures((prevState) => [...prevState, r.data])
                setIsAddTexture(false)
            })
    }

    const removePersonage = (id: number) => {
        AxiosClient.post(`/game/personages/delete/${id}`)
            .then((r) => setPersonages((prevState) => prevState.filter((el) => el.id !== id)))
            .catch((reason) => {
                setError({
                    show: true,
                    message: reason.response.data.message
                })
            })
    }

    const addPersonage = (data: FormData) => {
        AxiosClient.post(`/game/upload_personage_game`, data, {headers: {"Content-Type": "multipart/form-data"}})
            .then((r) => {
                setPersonages((prevState) => [...prevState, r.data])
                setIsAddPersonage(false)
            })
    }

    const addPersonagesCategory = (name: string) => {
        AxiosClient.post(`/game/upload_category_personages`, {name})
            .then((r) => {
                setCategoryPersonages((prevState) => [...prevState, r.data])
                setIsAddCategoryPersonage(false)
            })
    }
    const addTexturesCategory = (name: string) => {
        AxiosClient.post(`/game/upload_category_textures`, {name})
            .then((r) => {
                setCategoryTextures((prevState) => [...prevState, r.data])
                setIsAddCategoryTexture(false)
            })
    }

    const updateTexture = (id: number, data: {name: string, category_texture_id: number | null}) => {
        AxiosClient.post(`/game/textures/update/${id}`, data)
            .then((r) => {
                setTextures((prevState) => prevState.map((el) => el.id === id ? r.data : el))
                setCategoryTextures((prevState) => {
                    return prevState.map((el) => {
                        if (data.category_texture_id !== selectTexture?.category_texture_id){
                            if (el.id === data.category_texture_id){
                                return {
                                    ...el,
                                    textures_count: el.textures_count + 1
                                }
                            }
                            if (el.id === selectTexture?.category_texture_id){
                                return {
                                    ...el,
                                    textures_count: el.textures_count - 1
                                }
                            }
                        }
                        return el
                    })
                })
                setIsUpdateTexture(false)
                setSelectTexture(null)
            })
            .catch((reason) => {
                setError({
                    show: true,
                    message: reason.response.data.message
                })
            })
    }
    const updatePersonages = (id: number, data: {name: string, category_personage_id: number | null}) => {
        AxiosClient.post(`/game/personages/update/${id}`, data)
            .then((r) => {
                setPersonages((prevState) => prevState.map((el) => el.id === id ? r.data : el))
                setCategoryPersonages((prevState) => {
                    return prevState.map((el) => {
                        if (data.category_personage_id !== selectPersonage?.category_personage_id){
                            if (el.id === data.category_personage_id){
                                return {
                                    ...el,
                                    personages_count: el.personages_count + 1
                                }
                            }
                            if (el.id === selectPersonage?.category_personage_id){
                                return {
                                    ...el,
                                    personages_count: el.personages_count - 1
                                }
                            }
                        }
                        return el
                    })
                })
                setIsUpdatePersonage(false)
                setSelectPersonage(null)
            })
            .catch((reason) => {
                setError({
                    show: true,
                    message: reason.response.data.message
                })
            })
    }
    const removeBackground = (id: number) => {
        AxiosClient.post(`/game/backgrounds/delete/${id}`)
            .then((r) => setBackgroundImages((prevState) => prevState.filter((el) => el.id !== id)))
            .catch((reason) => {
                setError({
                    show: true,
                    message: reason.response.data.message
                })
            })
    }

    const updateBackground = (id: number, data: {name: string, category_background_id: number | null}) => {
        AxiosClient.post(`/game/backgrounds/update/${id}`, data)
            .then((r) => {
                setBackgroundImages((prevState) => prevState.map((el) => el.id === id ? r.data : el))
                setCategoryBackgrounds((prevState) => {
                    return prevState.map((el) => {
                        if (data.category_background_id !== selectBackground?.category_background_id){
                            if (el.id === data.category_background_id){
                                return {
                                    ...el,
                                    backgrounds_count: el.backgrounds_count + 1
                                }
                            }
                            if (el.id === selectBackground?.category_background_id){
                                return {
                                    ...el,
                                    backgrounds_count: el.backgrounds_count - 1
                                }
                            }
                        }
                        return el
                    })
                })
                setIsUpdateBackground(false)
                setSelectBackground(null)
            })
            .catch((reason) => {
                setError({
                    show: true,
                    message: reason.response.data.message
                })
            })
    }

    const addBackground = (data: FormData) => {
        AxiosClient.post(`/game/upload_background_game`, data, {headers: {"Content-Type": "multipart/form-data"}})
            .then((r) => {
                setBackgroundImages((prevState) => [...prevState, r.data])
                setIsAddBackground(false)
            })
    }

    const addCategoryBackground = (name: string) => {
        AxiosClient.post(`/game/upload_category_background`, {name})
            .then((r) => {
                setCategoryBackgrounds((prevState) => [...prevState, r.data])
                setIsAddCategoryBackground(false)
            })
    }
    const removeBackgroundSound = (id: number) => {
        AxiosClient.post(`/game/backgroundSounds/delete/${id}`)
            .then((r) => setBackgroundSounds((prevState) => prevState.filter((el) => el.id !== id)))
            .catch((reason) => {
                setError({
                    show: true,
                    message: reason.response.data.message
                })
            })
    }

    const addBackgroundSound = (data: FormData) => {
        AxiosClient.post(`/game/upload_background_audio_game`, data, {headers: {"Content-Type": "multipart/form-data"}})
            .then((r) => {
                setBackgroundSounds((prevState) => [...prevState, r.data])
                setIsAddBackgroundSound(false)
            })
    }

    const addBackgroundSoundCategory = (name: string) => {
        AxiosClient.post(`/game/upload_category_background_sound`, {name})
            .then((r) => {
                setCategoryBackgroundSounds((prevState) => [...prevState, r.data])
                setIsAddCategoryBackgroundSound(false)
            })
    }

    const updateBackgroundSound = (id: number, data: {name: string, category_background_sound_id: number | null}) => {
        AxiosClient.post(`/game/backgroundAudio/update/${id}`, data)
            .then((r) => {
                setBackgroundSounds((prevState) => prevState.map((el) => el.id === id ? r.data : el))
                setCategoryBackgroundSounds((prevState) => {
                    return prevState.map((el) => {
                        if (data.category_background_sound_id !== selectBackgroundSound?.category_background_sound_id){
                            if (el.id === data.category_background_sound_id){
                                return {
                                    ...el,
                                    background_sounds_count: el.background_sounds_count + 1
                                }
                            }
                            if (el.id === selectBackgroundSound?.category_background_sound_id){
                                return {
                                    ...el,
                                    background_sounds_count: el.background_sounds_count - 1
                                }
                            }
                        }
                        return el
                    })
                })
                setIsUpdateBackgroundSound(false)
                setSelectBackgroundSound(null)

            })
            .catch((reason) => {
                setError({
                    show: true,
                    message: reason.response.data.message
                })
            })
    }

    const onChangeUseBackSound = (checked: boolean) => {
        const indexSelectShot = game.shots.findIndex(el => el.name === shot.name)
        if (indexSelectShot !== 0 && checked){
            const oldShot = game.shots[indexSelectShot - 1];
            setShot({
                ...shot,
                useSoundBack: checked,
                backgroundSound: oldShot.backgroundSound
            })
            let stop = false;
            const newGame: Omit<IGame, 'id'> = {
                ...game,
                shots: game.shots.map((el, index) => {
                    if (index > indexSelectShot && !stop) {
                        if (el.useSoundBack) {
                            return {
                                ...el,
                                backgroundSound: oldShot.backgroundSound
                            }
                        } else {
                            stop = true
                        }
                    }
                    return el
                })
            }
            setGame(newGame)
        }

        if (!checked){
            setShot({
                ...shot,
                useSoundBack: checked,
                backgroundSound: null
            })
        }
    }
    const handleKeyCommand = (command: string, editorState: EditorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };
    return (
        openSettings ?
            <div className="position-absolute top-0 start-0 h-100 w-100 bg-white col-xxl-3 p-0"
                 style={{overflowY: "scroll", scrollbarWidth: "none"}}>
                <div>
                    <i className="bx bx-x fs-1 cursor-pointer"
                       onClick={handleOpenSettings}>
                    </i>
                </div>
                <div className="mt-3">
                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                         role="tablist">
                        <NavItem>
                            <NavLink
                                className={classNames('p-2', {active: activeTab === "1"})}
                                onClick={() => {
                                    setActiveTab("1");
                                }}
                                type="button">
                                <i className="fas fa-home"></i>
                                Основная информация
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classNames('p-2', {active: activeTab === "2"})}
                                onClick={() => {
                                    setActiveTab("2");
                                }}
                                type="button">
                                <i className="fas fa-home"></i>
                                Персонажи
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classNames('p-2', {active: activeTab === "3"})}
                                onClick={() => {
                                    setActiveTab("3");
                                }}
                                type="button">
                                <i className="fas fa-home"></i>
                                Текстуры
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classNames('p-2', {active: activeTab === "4"})}
                                onClick={() => {
                                    setActiveTab("4");
                                }}
                                type="button">
                                <i className="fas fa-home"></i>
                                Задний фон
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classNames('p-2', {active: activeTab === "5"})}
                                onClick={() => {
                                    setActiveTab("5");
                                }}
                                type="button">
                                <i className="fas fa-home"></i>
                                Музыка заднего фона
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classNames('p-2', {active: activeTab === "6"})}
                                onClick={() => {
                                    setActiveTab("6");
                                }}
                                type="button">
                                <i className="fas fa-home"></i>
                                Настройки
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classNames('p-2', {active: activeTab === "7"})}
                                onClick={() => {
                                    setActiveTab("7");
                                }}
                                type="button">
                                <i className="fas fa-home"></i>
                                Загрузка текстур
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classNames('p-2', {active: activeTab === "8"})}
                                onClick={() => {
                                    setActiveTab("8");
                                }}
                                type="button">
                                <i className="fas fa-home"></i>
                                Текст
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <div>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <div className="p-2">
                                <div className="mt-2">
                                    <Row>
                                        <Col sm={12}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label htmlFor="name" className="form-label">Название кадра</label>
                                                    <input type="text" id="name" value={shot.name}
                                                           onChange={(event) => setShot({
                                                               ...shot,
                                                               name: event.target.value
                                                           })} name='name' className="form-control"/>
                                                </Col>
                                                <Col sm={12}>
                                                    <label htmlFor="inputWords" className="form-label">Вводное
                                                        слово</label>
                                                    <input type="text" id="inputWords" name="inputWords"
                                                           className="form-control" value={shot.text}
                                                           onChange={(event) => setShot({
                                                               ...shot,
                                                               text: event.target.value
                                                           })}/>
                                                </Col>
                                                <Col sm={12}>
                                                    <label htmlFor="hintWords" className="form-label">Подсказка</label>
                                                    <input type="text" id="hintWords" name="hintWords"
                                                           className="form-control" value={shot.hint}
                                                           onChange={(event) => setShot({
                                                               ...shot,
                                                               hint: event.target.value
                                                           })}/>
                                                </Col>
                                                <Col sm={12}>
                                                    <div className="d-flex gap-2">
                                                        <div>
                                                            <label htmlFor="wTextureWords" className="form-label">Ширина текстур</label>
                                                            <input type="number" id="hintWords" name="wTextureWords"
                                                                   className="form-control" value={wTexture}
                                                                   onChange={(event) => {
                                                                       setWTexture(+event.target.value)
                                                                   }}/>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="hTextureWords" className="form-label">Высота текстур</label>
                                                            <input type="text" id="hintWords" disabled  name="hTextureWords"
                                                                   className="form-control" value={wTexture}
                                                                   onChange={(event) => {
                                                                       setHTexture(+event.target.value)
                                                                   }}/>
                                                        </div>
                                                    </div>
                                                    <button className="btn btn-primary mt-2" onClick={() => updateWidthHeightTextures()}>
                                                        Применить размеры
                                                    </button>
                                                </Col>
                                                <Col sm={12}>
                                                    <label htmlFor="isChatGPT" className="form-label mt-1">Использовать
                                                        GPT</label>
                                                    <div>
                                                        <input type="checkbox" id="isChatGPT" name="isChatGPT"
                                                               className="form-check-input fs-3"
                                                               checked={shot.isGPTChat} onChange={(event) => setShot({
                                                            ...shot,
                                                            isGPTChat: event.target.checked
                                                        })}/>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div className="p-2 position-relative">
                                <div className="d-flex gap-2">
                                    <Button
                                        color="primary"
                                        outline
                                        onClick={() => onFilterPersonages('all')}
                                    >
                                        Все{' '}
                                        <Badge>
                                            {personages.length}
                                        </Badge>
                                    </Button>
                                    <Button
                                        color="primary"
                                        outline
                                        onClick={() => onFilterPersonages(null)}
                                    >
                                        Без категории{' '}
                                        <Badge>
                                            {personages.filter(el => el.category_personage_id === null).length}
                                        </Badge>
                                    </Button>
                                    {
                                        categoryPersonages.map((el) => (
                                            <Button
                                                key={el.id}
                                                color="primary"
                                                outline
                                                onClick={() => onFilterPersonages(el.id)}
                                            >
                                                {el.name}{' '}
                                                <Badge>
                                                    {
                                                        el.personages_count
                                                    }
                                                </Badge>
                                            </Button>
                                        ))
                                    }
                                </div>
                                <Row className="mt-4">
                                    {
                                        personagesFilter.map((personage) => (
                                            <Col xxl={1} lg={2} sm={3} xs={4}
                                                 className="position-relative d-flex justify-content-center"
                                                 key={personage.id}>
                                                {
                                                    shot.personages.find(el => el.id === personage.id) &&
                                                    <Badge color="success" style={{width: 30, height: 30}}
                                                           className="rounded-circle d-flex justify-content-center align-items-center position-absolute top-0 start-100 translate-middle"><i
                                                        className="bx bx-check fs-3"></i></Badge>
                                                }
                                                <img src={process.env.REACT_APP_API_BASE_URL + personage.path}
                                                     alt="user" className="cursor-pointer"
                                                     style={{height: 100, width: 100}} onClick={() => {
                                                    handleAddPersonage(personage)
                                                }}/>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </div>
                        </TabPane>
                        <TabPane tabId="3">
                            <div className="p-2">
                                <div className="d-flex gap-2">
                                    <Button
                                        color="primary"
                                        outline
                                        onClick={() => onFilterTextures('all')}
                                    >
                                        Все{' '}
                                        <Badge>
                                            {textures.length}
                                        </Badge>
                                    </Button>
                                    <Button
                                        color="primary"
                                        outline
                                        onClick={() => onFilterTextures(null)}
                                    >
                                        Без категории{' '}
                                        <Badge>
                                            {textures.filter(el => el.category_texture_id === null).length}
                                        </Badge>
                                    </Button>
                                    {
                                        categoryTextures.map((el) => (
                                            <Button
                                                key={el.id}
                                                color="primary"
                                                outline
                                                onClick={() => onFilterTextures(el.id)}
                                            >
                                                {el.name}{' '}
                                                <Badge>
                                                    {
                                                        el.textures_count
                                                    }
                                                </Badge>
                                            </Button>
                                        ))
                                    }
                                </div>
                                <Row className="mt-4">
                                    {
                                        texturesFilter.map((texture) => (
                                            <Col xxl={1} lg={2} sm={3} xs={4}
                                                 className="position-relative d-flex justify-content-center"
                                                 key={texture.id}>
                                                {
                                                    shot.textures.find(el => el.id === texture.id) &&
                                                    <Badge color="success" style={{width: 30, height: 30}}
                                                           className="rounded-circle d-flex justify-content-center align-items-center position-absolute top-0 start-100 translate-middle"><i
                                                        className="bx bx-check fs-3"></i></Badge>
                                                }
                                                <img src={process.env.REACT_APP_API_BASE_URL + texture.path}
                                                     alt="user" className="cursor-pointer"
                                                     style={{height: 100, width: 100}}
                                                     onClick={() => handleAddTexture(texture)}/>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </div>
                        </TabPane>
                        <TabPane tabId="4">
                            <div className="p-2">
                                <div className="d-flex gap-2">
                                    <Button
                                        color="primary"
                                        outline
                                        onClick={() => onFilterBackgrounds('all')}
                                    >
                                        Все{' '}
                                        <Badge>
                                            {backgroundImages.length}
                                        </Badge>
                                    </Button>
                                    <Button
                                        color="primary"
                                        outline
                                        onClick={() => onFilterBackgrounds(null)}
                                    >
                                        Без категории{' '}
                                        <Badge>
                                            {backgroundImages.filter(el => el.category_background_id === null).length}
                                        </Badge>
                                    </Button>
                                    {
                                        categoryBackgrounds.map((el) => (
                                            <Button
                                                key={el.id}
                                                color="primary"
                                                outline
                                                onClick={() => onFilterBackgrounds(el.id)}
                                            >
                                                {el.name}{' '}
                                                <Badge>
                                                    {
                                                        el.backgrounds_count
                                                    }
                                                </Badge>
                                            </Button>
                                        ))
                                    }
                                </div>
                                <Row className="mt-4 gap-2 px-3">
                                    {
                                        backgroundsFilter.map((backgroundImage) => (
                                            <Col xxl={2} lg={3} xs={12} className="position-relative mt-3 p-0"
                                                 key={backgroundImage.id}>
                                                {
                                                    shot.backgroundImage && shot.backgroundImage.id === backgroundImage.id &&
                                                    <Badge color="success" style={{width: 30, height: 30}}
                                                           className="rounded-circle d-flex justify-content-center align-items-center position-absolute top-0 start-100 translate-middle"><i
                                                        className="bx bx-check fs-3"></i></Badge>
                                                }
                                                <img src={process.env.REACT_APP_API_BASE_URL + backgroundImage.path}
                                                     alt="user" className="w-100 cursor-pointer"
                                                     onClick={() => handleAddBackgroundImage(backgroundImage)}/>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </div>
                        </TabPane>
                        <TabPane tabId="5">
                            <div className="p-2">
                                <div className="d-flex gap-2">
                                    <Button
                                        color="primary"
                                        outline
                                        onClick={() => onFilterBackgroundsSound('all')}
                                    >
                                        Все{' '}
                                        <Badge>
                                            {backgroundSounds.length}
                                        </Badge>
                                    </Button>
                                    <Button
                                        color="primary"
                                        outline
                                        onClick={() => onFilterBackgroundsSound(null)}
                                    >
                                        Без категории{' '}
                                        <Badge>
                                            {backgroundSounds.filter(el => el.category_background_sound_id === null).length}
                                        </Badge>
                                    </Button>
                                    {
                                        categoryBackgroundSounds.map((el) => (
                                            <Button
                                                key={el.id}
                                                color="primary"
                                                outline
                                                onClick={() => onFilterBackgroundsSound(el.id)}
                                            >
                                                {el.name}{' '}
                                                <Badge>
                                                    {
                                                        el.background_sounds_count
                                                    }
                                                </Badge>
                                            </Button>
                                        ))
                                    }
                                </div>
                                <Row>
                                    <Col sm={12}>
                                        <label htmlFor="useSoundBack" className="form-label mt-1">Использовать из прошлого кадра</label>
                                        <div>
                                            <input type="checkbox" id="useSoundBack" name="useSoundBack"
                                                   className="form-check-input fs-3"
                                                   checked={shot.useSoundBack} onChange={(event) => onChangeUseBackSound(event.target.checked)}/>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    {
                                        backgroundsSoundFilter.map((backgroundSound) => (
                                            <Col xxl={3} className="position-relative" key={backgroundSound.id}
                                                 style={{width: 310}}>
                                                {
                                                    shot.backgroundSound && shot.backgroundSound.id === backgroundSound.id &&
                                                    <Badge color="success" style={{width: 30, height: 30}}
                                                           className="rounded-circle d-flex justify-content-center align-items-center position-absolute top-0 start-100 translate-middle">
                                                        <i className="bx bx-check fs-3"></i>
                                                    </Badge>
                                                }
                                                <audio controls>
                                                    <source
                                                        src={process.env.REACT_APP_API_BASE_URL + backgroundSound.path}
                                                        type="audio/mp3" className="cursor-pointer"/>
                                                </audio>
                                                <label htmlFor="" className="w-100 text-center fw-bold fs-14"
                                                       onClick={() => handleAddBackgroundSound(backgroundSound)}>{backgroundSound.name}</label>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </div>
                        </TabPane>
                        <TabPane tabId="6">
                            <div className="p-2">
                                <div className="d-flex gap-2">
                                    <Button
                                        color="primary"
                                        outline={activeTabSettings !== "0"}
                                        onClick={() => handleActiveTabSettings("0")}
                                    >
                                        Нет настроек
                                    </Button>
                                    <Button
                                        color="primary"
                                        outline={activeTabSettings !== "1"}
                                        onClick={() => handleActiveTabSettings("1")}
                                    >
                                        Посчитать кол-во текстур
                                    </Button>
                                </div>
                                <TabContent activeTab={activeTabSettings}>
                                    <TabPane tabId="1">
                                        <Row className="mt-4 gap-4">
                                            <Col sm={12}>
                                                <label htmlFor="textTrueCountTexture" className="form-label">Текст
                                                    верного ответа</label>
                                                <input type="text" id="textTrueCountTexture"
                                                       value={shot.settings ? shot.settings.settings.textTrue : ''}
                                                       onChange={(event) => {
                                                           setShot((shot) => {
                                                               if (shot.settings) {
                                                                   return {
                                                                       ...shot,
                                                                       settings: {
                                                                           ...shot.settings,
                                                                           settings: {
                                                                               ...shot.settings.settings,
                                                                               textTrue: event.target.value
                                                                           }
                                                                       }
                                                                   }
                                                               }
                                                               return shot
                                                           })
                                                       }} className="form-control"/>
                                            </Col>
                                            <Col sm={12}>
                                                <label htmlFor="textFalseCountTexture" className="form-label">Текст не
                                                    верного ответа</label>
                                                <input type="text" id="textFalseCountTexture"
                                                       className="form-control"
                                                       value={shot.settings ? shot.settings.settings.textFalse : ''}
                                                       onChange={(event) => {
                                                           setShot((shot) => {
                                                               if (shot.settings) {
                                                                   return {
                                                                       ...shot,
                                                                       settings: {
                                                                           ...shot.settings,
                                                                           settings: {
                                                                               ...shot.settings.settings,
                                                                               textFalse: event.target.value
                                                                           }
                                                                       }
                                                                   }
                                                               }
                                                               return shot
                                                           })
                                                       }}/>
                                            </Col>
                                            <Col sm={12}>
                                                <label htmlFor="answerCountTexture" className="form-label">Ответ</label>
                                                <input type="number" id="answerCountTexture"
                                                       className="form-control"
                                                       value={shot.settings ? shot.settings.settings.answer : ''}
                                                       onChange={(event) => {
                                                           setShot((shot) => {
                                                               if (shot.settings) {
                                                                   return {
                                                                       ...shot,
                                                                       settings: {
                                                                           ...shot.settings,
                                                                           settings: {
                                                                               ...shot.settings.settings,
                                                                               answer: event.target.value
                                                                           }
                                                                       }
                                                                   }
                                                               }
                                                               return shot
                                                           })
                                                       }}/>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </TabPane>
                        <TabPane tabId="7">
                            <div className="p-2">
                                <div className="d-flex gap-2">
                                    <Button
                                        color="primary"
                                        outline={activeTabUploadTexture !== "1"}
                                        onClick={() => setActiveTabUploadTexture("1")}
                                    >
                                        Объекты
                                    </Button>
                                    <Button
                                        color="primary"
                                        outline={activeTabUploadTexture !== "2"}
                                        onClick={() => setActiveTabUploadTexture("2")}
                                    >
                                        Персонажи
                                    </Button>
                                    <Button
                                        color="primary"
                                        outline={activeTabUploadTexture !== "3"}
                                        onClick={() => setActiveTabUploadTexture("3")}
                                    >
                                        Задний фон
                                    </Button>
                                    <Button
                                        color="primary"
                                        outline={activeTabUploadTexture !== "4"}
                                        onClick={() => setActiveTabUploadTexture("4")}
                                    >
                                        Музыка заднего фона
                                    </Button>
                                </div>
                                <TabContent activeTab={activeTabUploadTexture} className="mt-3">
                                    <TabPane tabId="1">
                                        {
                                            error.show &&
                                            <Alert color="danger">{error.message}</Alert>
                                        }
                                        <Button color="primary" className="mb-3" onClick={() => setIsAddTexture(true)}>
                                            <i className="bx bx-plus-circle"></i> Добавить текстуру
                                        </Button>
                                        <Button color="primary" className="mb-3 mx-2"
                                                onClick={() => setIsAddCategoryTexture(true)}>
                                            <i className="bx bx-plus-circle"></i> Добавить категорию
                                        </Button>
                                        <TextureList onChangeSelectTexture={(texture) => {
                                            setSelectTexture(texture)
                                            setIsUpdateTexture(true)
                                        }} textures={textures} removeTexture={removeTexture}/>
                                        <ModalTextureAdd categoryTextures={categoryTextures} isAddTexture={isAddTexture}
                                                         toggleIsAddTexture={() => setIsAddTexture(!isAddTexture)}
                                                         addTexture={addTexture}/>
                                        <ModalTextureCategoryAdd isAddTextureCategory={isAddCategoryTexture} toggleIsAddTextureCategory={() => setIsAddCategoryTexture(!isAddCategoryTexture)} addTextureCategory={addTexturesCategory}/>
                                        <ModalTextureUpdate isUpdateTexture={isUpdateTexture} toggleIsUpdateTexture={() => setIsUpdateTexture(!isUpdateTexture)} updateTexture={updateTexture} selectTexture={selectTexture} categoryTextures={categoryTextures}/>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        {
                                            error.show &&
                                            <Alert color="danger">{error.message}</Alert>
                                        }
                                        <Button color="primary" className="mb-3"
                                                onClick={() => setIsAddPersonage(true)}>
                                            <i className="bx bx-plus-circle"></i> Добавить персонажа
                                        </Button>
                                        <Button color="primary" className="mb-3 mx-2"
                                                onClick={() => setIsAddCategoryPersonage(true)}>
                                            <i className="bx bx-plus-circle"></i> Добавить категорию
                                        </Button>
                                        <PersonageList onChangeSelectPersonage={(personage) => {
                                            setSelectPersonage(personage)
                                            setIsUpdatePersonage(true)
                                        }} personages={personages} removePersonage={removePersonage}/>
                                        <ModalPersonageAdd categoryPersonages={categoryPersonages} isAddPersonage={isAddPersonage}
                                                           toggleIsAddPersonage={() => setIsAddPersonage(!isAddPersonage)}
                                                           addPersonage={addPersonage}/>
                                        <ModalPersonageCategoryAdd isAddPersonageCategory={isAddCategoryPersonage} toggleIsAddPersonageCategory={() => setIsAddCategoryPersonage(!isAddCategoryPersonage)} addPersonageCategory={addPersonagesCategory}/>
                                        <ModalPersonageUpdate isUpdatePersonage={isUpdatePersonage} toggleIsUpdatePersonage={() => setIsUpdatePersonage(!isUpdatePersonage)} updatePersonage={updatePersonages} selectPersonage={selectPersonage} categoryPersonages={categoryPersonages}/>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        {
                                            error.show &&
                                            <Alert color="danger">{error.message}</Alert>
                                        }
                                        <Button color="primary" className="mb-3"
                                                onClick={() => setIsAddBackground(true)}>
                                            <i className="bx bx-plus-circle"></i> Добавить задний фон
                                        </Button>
                                        <Button color="primary" className="mb-3 mx-2"
                                                onClick={() => setIsAddCategoryBackground(true)}>
                                            <i className="bx bx-plus-circle"></i> Добавить категорию
                                        </Button>
                                        <BackgroundList background={backgroundImages}
                                                        removeBackground={removeBackground}
                                                        onChangeSelectBackground={(bg) => {
                                                            setSelectBackground(bg)
                                                            setIsUpdateBackground(true)
                                                        }}
                                        />
                                        <ModalBackgroundAdd isAddBackground={isAddBackground}
                                                            categoryBackgrounds={categoryBackgrounds}
                                                            toggleIsAddBackground={() => setIsAddBackground(!isAddBackground)}
                                                            addBackground={addBackground}/>
                                        <ModalBackgroundUpdate isUpdateBackground={isUpdateBackground} toggleIsUpdateBackground={() => setIsUpdateBackground(!isUpdateBackground)} updateBackground={updateBackground} selectBackground={selectBackground} categoryBackgrounds={categoryBackgrounds}/>
                                        <ModalBackgroundCategoryAdd isAddBackgroundCategory={isAddCategoryBackground}
                                                                    toggleIsAddBackgroundCategory={() => setIsAddCategoryBackground(!isAddCategoryBackground)}
                                                                    addBackgroundCategory={addCategoryBackground}/>
                                    </TabPane>
                                    <TabPane tabId="4">
                                        {
                                            error.show &&
                                            <Alert color="danger">{error.message}</Alert>
                                        }
                                        <Button color="primary" className="mb-3"
                                                onClick={() => setIsAddBackgroundSound(true)}>
                                            <i className="bx bx-plus-circle"></i> Добавить звук заднего фона
                                        </Button>
                                        <Button color="primary" className="mb-3 mx-2"
                                                onClick={() => setIsAddCategoryBackgroundSound(true)}>
                                            <i className="bx bx-plus-circle"></i> Добавить категорию
                                        </Button>
                                        <BackgroundSoundList sounds={backgroundSounds}
                                                             removeSound={removeBackgroundSound} onChangeSelectBackgroundSound={(sound) => {
                                            setSelectBackgroundSound(sound)
                                            setIsUpdateBackgroundSound(true)
                                        }}/>
                                        <ModalBackgroundSoundCategoryAdd isAddBackgroundSoundCategory={isAddCategoryBackgroundSound} toggleIsAddBackgroundSoundCategory={() => setIsAddCategoryBackgroundSound(isAddCategoryBackgroundSound)} addBackgroundSoundCategory={addBackgroundSoundCategory}/>
                                        <ModalBackgroundSoundAdd categoryBackgroundSounds={categoryBackgroundSounds} isAddSound={isAddBackgroundSound}
                                                                 toggleIsAddSound={() => setIsAddBackgroundSound(!isAddBackgroundSound)}
                                                                 addSound={addBackgroundSound}/>
                                        <ModalBackgroundSoundUpdate isUpdateBackgroundSound={isUpdateBackgroundSound} toggleIsUpdateBackgroundSound={() => setIsUpdateBackgroundSound(!isUpdateBackgroundSound)} updateBackgroundSound={updateBackgroundSound} selectBackgroundSound={selectBackgroundSound} categoryBackgroundSounds={categoryBackgroundSounds}/>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </TabPane>
                        <TabPane tabId="8">
                            <div className="p-2">
                                <Editor
                                    editorState={editorState}
                                    onChange={handleEditorChange}
                                    handleKeyCommand={handleKeyCommand}
                                    placeholder="Введите текст здесь"
                                />
                            </div>
                        </TabPane>
                    </TabContent>
                </div>
                {
                    activeTab !== '7' &&
                    <div className="mt-2 mx-2 position-sticky bottom-0 mb-2">
                        <button className="btn btn-primary" type="button" onClick={save}>Обновить кадр</button>
                    </div>
                }
            </div>
            :
            <></>
    );
};

export default SettingsShot;
