import React, {FC} from 'react';
import {Button, Col, ListGroup, ListGroupItem, Row} from "reactstrap";
import {IBackgroundSound} from "../../../../models/game/IBackgroundSound";

interface IProps {
    sounds: IBackgroundSound[],
    removeSound: (id: number) => void
    onChangeSelectBackgroundSound: (sound: IBackgroundSound) => void
}

const BackgroundSoundList: FC<IProps> = ({sounds, removeSound, onChangeSelectBackgroundSound}) => {
    return (
        <ListGroup>
            {sounds.map((sound, index) => (
                <ListGroupItem key={index} className="d-flex justify-content-between align-items-center">
                    <Row className="w-100">
                        <Col xs="6" className="d-flex justify-content-center align-items-center">
                            <audio controls>
                                <source src={process.env.REACT_APP_API_BASE_URL + sound.path} type="audio/wav" />
                                Your browser does not support the audio element.
                            </audio>
                        </Col>
                        <Col xs="2" className="d-flex align-items-center">
                            <span className="fs-5">{sound.name}</span>
                        </Col>
                        <Col xs="2" className="d-flex align-items-center">
                            <span className="fs-5">{sound.category_background_sound ? sound.category_background_sound.name : 'Нет категории'}</span>
                        </Col>
                        <Col xs="1" className="d-flex justify-content-center">
                            <Button color="info" onClick={() => onChangeSelectBackgroundSound(sound)} className="rounded-circle">
                                <i className="bx bx-pencil fs-3"></i>
                            </Button>
                        </Col>
                        <Col xs="1" className="d-flex justify-content-center">
                            <Button color="danger" onClick={() => removeSound(sound.id)} className="rounded-circle">
                                <i className="bx bx-trash fs-3"></i>
                            </Button>
                        </Col>
                    </Row>
                </ListGroupItem>
            ))}
        </ListGroup>
    );
};

export default BackgroundSoundList;
