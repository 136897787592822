import React, {useState} from 'react';
import {Col, Row, Spinner} from "reactstrap";
import cn from 'classnames'
import ModalNameGame from "./constructor/ModalNameGame";
import ListShots from "./constructor/ListShots";
import ModalAddShot from "./constructor/ModalAddShot";
import SettingsShot from "./constructor/SettingsShot";
import FullShot from "./constructor/FullShot";
import {IGame} from "../../models/game/IGame";
import {IShot} from "../../models/game/IShot";
import AxiosClient from "../../api/AxiosClient";
import {useNavigate} from "react-router-dom";
const Constructor = () => {
    const [isHoverCreate, setIsHoverCreate] = useState(false);
    const [isHoverSave, setIsHoverSave] = useState(false);
    const [isHoverAddShot, setIsHoverAddShot] = useState(false);
    const [modalGameName, setModalGameName] = useState(false);
    const [openSettings, setOpenSettings] = useState(false);
    const [openFullShot, setOpenFullShot] = useState(false);
    const [modalAddShot, setModalAddShot] = useState(false);
    const [selectSettingsShot, setSelectSettingsShot] = useState<IShot | null>(null);
    const [game, setGame] = useState<Omit<IGame, 'id'> | null>(null);
    const [savingGame, setSavingGame] = useState(false);
    const navigate = useNavigate()
    const toggle = () => setModalGameName(!modalGameName);
    const toggleSave = (game: Omit<IGame, 'id'>) => {
        setGame(game)
        setModalGameName(!modalGameName)
    };

    const handleOpenSettings = () => {
        setOpenSettings(!openSettings)
        setOpenFullShot(true)
    }

    const toggleAddShot = () => setModalAddShot(!modalAddShot);
    const toggleAddShotSave = (name: string) => {
        setGame(game => {
            if (game) {
                return {...game,
                    shots: [...game.shots, {id: game.shots.length + 1, useSoundBack: false, widthTextures: 200, heightTextures: 200, name: name, pathHint: '', text: '', hint: '', personages: [], textures: [], backgroundImage: null, backgroundSound: null, isGPTChat: false, settings: null }]}
            }
            return null
        })
        setModalAddShot(!modalAddShot)
    };

    const handleCloseShot = (shot: IShot) => {
        setGame((game) => {
            if (game) {
                return {
                    ...game,
                    shots: game?.shots.map(el => el.id === shot.id ? shot : el)
                }
            }
            return null
        })
        setOpenFullShot(false)
    }

    // console.log(game)

    const saveSettingsShot = (shot: IShot) => {
        setGame((game) => {
            if (game) {
                return {
                    ...game,
                    shots: game.shots.map((oldShot) => {
                        if (oldShot.id === shot.id){
                            return shot
                        }
                        return oldShot
                    })
                }
            }
            return null
        })
        setSelectSettingsShot(shot)
    }

    const saveGame = () => {
        if (game) {
            setSavingGame(true)
            const formData = new FormData()
            if (game?.file) {
                formData.append('file', game.file)
                formData.append('data', JSON.stringify(game))
            } else {
                formData.append('data', JSON.stringify(game))
            }
            AxiosClient.post('/game/create', formData, {headers: {"Content-Type": "multipart/form-data"}})
                .then(r => {
                    setSavingGame(false)
                    navigate('/games/teacher')
                })
                .catch(() => {
                    setSavingGame(false)
                })
        }
    }

    return (
        <div className="h-100 w-100 bg-primary bg-opacity-10 position-relative p-2" id="window">
            <Row>
                <Col sm={12}>
                    <Row>
                        <Col xxl={3}>
                            <div className="bg-white fs-1 d-flex align-items-center gap-2 rounded shadow">
                                <i className={cn("bx bx-folder-plus cursor-pointer", {
                                    'text-primary': isHoverCreate
                                })}
                                   onMouseEnter={() => setIsHoverCreate(true)}
                                   onMouseLeave={() => setIsHoverCreate(false)}
                                   onClick={toggle}
                                ></i>
                                <i className={cn("bx bx-save cursor-pointer", {
                                    'text-primary': isHoverSave
                                })}
                                   onMouseEnter={() => setIsHoverSave(true)}
                                   onMouseLeave={() => setIsHoverSave(false)}
                                   onClick={() => saveGame()}
                                ></i>
                            </div>
                        </Col>
                    </Row>
                    <ModalNameGame toggle={toggle} toggleSave={toggleSave} isOpen={modalGameName} dataGame={game}/>
                </Col>
                <Col sm={12} className="my-5">
                    <ListShots handleOpenSettings={handleOpenSettings} listShot={game?.shots ?? []} selectShot={(shot) => setSelectSettingsShot(shot)}/>
                </Col>
            </Row>
            {
                savingGame && <div className="d-flex justify-content-center align-items-center bg-dark bg-opacity-50 position-absolute top-0 start-0 w-100 h-100" style={{ zIndex: 9999 }}>
                    <div className="text-center">
                        <div className="text-light fs-3 mb-3">Сохранение</div>
                        <Spinner className="text-light" style={{ width: '3rem', height: '3rem' }} />
                    </div>
                </div>
            }
            {
                selectSettingsShot &&
                <FullShot shot={selectSettingsShot} openFullShot={openFullShot} handleCloseShot={handleCloseShot} openSettings={openSettings}/>
            }
            {
                selectSettingsShot && game &&
                <SettingsShot game={game} setGame={game => setGame(game)} openSettings={openSettings} handleOpenSettings={handleOpenSettings} selectedShot={selectSettingsShot} saveSettingsShot={(shot) => saveSettingsShot(shot)}/>
            }
            {
                !openSettings && !openFullShot && game &&
                <div className="position-absolute bottom-0 mb-2 end-0 bg-white fs-1 d-flex align-items-center gap-2 rounded mx-2 col-xxl-3 shadow">
                    <i className={cn("bx bx-plus cursor-pointer", {
                        'text-primary': isHoverAddShot
                    })}
                       onMouseEnter={() => setIsHoverAddShot(true)}
                       onMouseLeave={() => setIsHoverAddShot(false)}
                       onClick={toggleAddShot}
                    ></i>
                    <ModalAddShot toggle={toggleAddShot} isOpen={modalAddShot} toggleSave={(name) => toggleAddShotSave(name)}/>
                </div>
            }
        </div>
    );
};

export default Constructor;
