import React, {FC, useEffect, useState} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {addTerm} from "../../../store/reducers/admin/ActionCreator";
import {Card, CardBody, CardHeader, Col, Label, Nav, NavItem, NavLink, Row} from "reactstrap";
import TableSelectPage from "../term/TableSelectPage";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";
import {Axios} from "axios";
import AxiosClient from "../../../api/AxiosClient";
import Notification from "../../widgets/Notification";

interface IAddTypePortfolio {
    selectType: any
}

const AddTypePortfolio: FC <IAddTypePortfolio> = ({selectType}) => {
    const dispatch = useAppDispatch();
    const {pages} = useAppSelector(state => state.AdminReducer)
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');
    const [themes, setThemes] = useState<any[]>([]);

    useEffect(() => {
        AxiosClient.get(`theme_all/parent/portfolio/1`, {params: {}})
            .then(r => {
                setThemes(r.data)
            })
            .catch(() => console.log('error'))
    }, [])


    return (
        <>
            <Formik
                enableReinitialize
                initialValues={
                    {
                        name: selectType.name,
                        themeId: selectType.theme_page_portfolio_id,
                    }
                }
                validationSchema={
                    Yup.object(
                        {
                            name: Yup.string().required('Введите название темы'),
                        }
                    )
                }
                onSubmit={
                    (value, {resetForm}) => {
                        dispatch(setIsSend(true));
                        if(selectType.id) {
                            AxiosClient.post(`update/type/parent/portfolio/${selectType.id}`, { name: value.name, theme_page_portfolio_id: value.themeId })
                                .then(r => {
                                    setNotificationType('success');
                                    setNotificationMessage('Готово, тип страницы изменен!');
                                    setShowNotification(true);
                                    dispatch(setIsSend(false));
                                })
                                .catch(() => {
                                    console.log('error');
                                    setNotificationType('error');
                                    setNotificationMessage('Ошибка, проверьте корректность данных!');
                                    setShowNotification(true);
                                    dispatch(setIsSend(false));
                                });
                        }else {
                            AxiosClient.post('create/type/parent/portfolio', { name: value.name, theme_page_portfolio_id: value.themeId })
                                .then(r => {
                                    setNotificationType('success');
                                    setNotificationMessage('Готово, тип страницы добавлен!');
                                    setShowNotification(true);
                                    dispatch(setIsSend(false));
                                })
                                .catch(() => {
                                    console.log('error');
                                    setNotificationType('error');
                                    setNotificationMessage('Ошибка, проверьте корректность данных!');
                                    setShowNotification(true);
                                    dispatch(setIsSend(false));
                                });
                        }
                    }
                }
            >
                {({ values, setFieldValue }) => (
                    <Form encType="multipart/form-data">
                        <Card className="mx-n3">
                            <CardHeader>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                     role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className="active">
                                            <i className="fas fa-home"></i>
                                            Данные о типе
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <CardBody className="p-4">
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="themeSelect" className="form-label">Выберите тему</Label>
                                            <Field
                                                as="select"
                                                name="themeId"
                                                className="form-select"
                                                id="themeSelect"
                                                value={values.themeId}
                                                onChange={(e: any) => setFieldValue('themeId', e.target.value)}
                                            >
                                                <option value="">Выберите тему</option>
                                                {themes.map((theme: any) => (
                                                    <option key={theme.id} value={theme.id}>
                                                        {theme.name}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="themeId">{msg => <div className="text-danger">{msg}</div>}</ErrorMessage>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="themeInput" className="form-label">Тип</Label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                id="themeInput"
                                                name="name"
                                                value={values.name}
                                                onChange={(e: any) => setFieldValue('name', e.target.value)}
                                            />
                                            <ErrorMessage name="name">{(msg) => <div
                                                className="text-danger">{msg}</div>}</ErrorMessage>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} className="mt-3">
                                        <div className="hstack gap-2 justify-content-end">
                                            <SubmitWithLoading text="Сохранить" />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Form>
                )}
            </Formik>
            {showNotification && <Notification type={notificationType} message={notificationMessage} setShowNotification={(value: boolean) => setShowNotification(value)}/>}
        </>

    );
};

export default AddTypePortfolio;
