import React, {FC} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {
    Col,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";
import * as Yup from 'yup'
import {IBackgroundImage} from "../../../../models/game/IBackgroundImage";

interface IProps {
    isUpdateBackground: boolean,
    toggleIsUpdateBackground: () => void,
    updateBackground: (id:number, data: {name: string, category_background_id: number | null}) => void
    selectBackground: IBackgroundImage | null
    categoryBackgrounds: {id: number, name: string}[]
}
const ModalBackgroundUpdate: FC<IProps> = ({categoryBackgrounds, toggleIsUpdateBackground, isUpdateBackground, updateBackground, selectBackground}) => {
    return (
        <>
            <Modal isOpen={isUpdateBackground} toggle={toggleIsUpdateBackground} centered>
                <ModalHeader toggle={toggleIsUpdateBackground}>Добавление персонажа</ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={
                            {
                                name: selectBackground?.name ?? '',
                                category_background_id: selectBackground?.category_background_id ? selectBackground.category_background_id : 'null' ?? null
                            }
                        }
                        validationSchema={
                            Yup.object(
                                {
                                    name: Yup.string().required('Введите название заднего фона'),
                                }
                            )
                        }
                        onSubmit={
                            (values, {resetForm}) => {
                                if (selectBackground) {
                                    updateBackground(selectBackground.id, {name: values.name, category_background_id: values.category_background_id === 'null' ? null : +values.category_background_id})
                                    resetForm()
                                }
                            }
                        }
                    >
                        <Form>
                            <Row>
                                <Col>
                                    <div className="mb-3">
                                        <Label htmlFor="categoryInput"
                                               className="form-label">Категория</Label>
                                        <Field as="select" className="form-control" id="categoryInput"
                                               name="category_background_id">
                                            <option value={'null'}>Без категории</option>
                                            {
                                                categoryBackgrounds.map((el) => (
                                                    <option key={el.id} value={el.id}>{el.name}</option>
                                                ))
                                            }
                                        </Field>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="mb-3">
                                        <Label htmlFor="nameInput"
                                               className="form-label">Имя заднего фона</Label>
                                        <Field type="text" className="form-control" id="nameInput"
                                               name="name"
                                               placeholder="Введите имя заднего фона"/>
                                        <ErrorMessage name="name">{(msg) => <div
                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} className="mt-3">
                                    <div className="hstack gap-2 justify-content-end">
                                        <button type="submit"
                                                className="btn btn-primary">Обновить
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    );
};

export default ModalBackgroundUpdate;
