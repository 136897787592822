import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import AxiosClient from "../../api/AxiosClient";
import MenuThemePortfolio from "../../components/parent/perentPortfolio/MenuThemePortfolio";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import ListPagesPortfolio from "../../components/parent/perentPortfolio/ListPagesPortfolio";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import Select from "react-select";
import {customStyles} from "../../components/settings";
import EditTitlePage from "../../components/parent/perentPortfolio/addPortfolioPage/EditTitlePage";
import ChoiceAddTypePortfolioPage from "../../components/parent/perentPortfolio/ChoiceAddTypePortfolioPage";
import EditBigDescriptionPage from "../../components/parent/perentPortfolio/addPortfolioPage/EditBigDescriptionPage";
import EditAdvanceOrCreationOnePhoto from "../../components/parent/perentPortfolio/addPortfolioPage/EditAdvanceOrCreationOnePhoto";
import EditAboutMePortfolio from "../../components/parent/perentPortfolio/addPortfolioPage/EditAboutMePortfolio";
import EditBigDescriptionPageOnTwoRow
    from "../../components/parent/perentPortfolio/addPortfolioPage/EditBigDescriptionPageOnTwoRow";
import EditMyFamily from "../../components/parent/perentPortfolio/addPortfolioPage/EditMyFamily";
import EditOnePhotoLeftAndDescription
    from "../../components/parent/perentPortfolio/addPortfolioPage/EditOnePhotoLeftAndDescription";
import EditMyKindergarten from "../../components/parent/perentPortfolio/addPortfolioPage/EditMyKindergarten";
import EditTreeFamily from "../../components/parent/perentPortfolio/addPortfolioPage/EditTreeFamily";
import EditOnePhotoRightAndDescriptionOnTwoRow
    from "../../components/parent/perentPortfolio/addPortfolioPage/EditOnePhotoRightAndDescriptionOnTwoRow";
import EditMyGroupOverall from "../../components/parent/perentPortfolio/addPortfolioPage/EditMyGroupOverall";
import EditMyFriends from "../../components/parent/perentPortfolio/addPortfolioPage/EditMyFriends";
import EditTravelThreePhoto from "../../components/parent/perentPortfolio/addPortfolioPage/EditTravelThreePhoto";
import EditOtherDescription from "../../components/parent/perentPortfolio/addPortfolioPage/EditOtherDescription";
import ModalConfirmPattern from "../../components/parent/perentPortfolio/ModalConfirmPattern";
import {getGroups} from "../../store/reducers/widgets/ActionCreator";
import {getChildren} from "../../store/reducers/parent/ActionCreator";

const PerentPortfolioMore = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams(); //id ортфолио на которое перешли по нему достаем портфолио
    const {children} = useAppSelector(state => state.ParentReducer)
    const [portfolio, setPortfolio] = useState<any>(); // Портфолио со всмеми страницами
    const [photoPage, setPhotoPage] = useState<any>();
    const [selectPatternPortfolio, setSelectPatternPortfolio] = useState({value: '', label:''});
    const [selectThemePortfolio, setSelectThemePortfolio] = useState({value: '', label: ''});
    const [selectPagePortfolio, setSelectPagePortfolio] = useState<any>(); //ыбранная страница
    const [showModalConfirmPattern, setShowModalConfirmPattern] = useState<boolean>(false);
    const [selectShowPagePortfolio, setSelectShowPagePortfolio] = useState(false);
    const [selectEditPagePortfolio, setSelectEditPagePortfolio] = useState(false);
    const [typePortfolio, setTypePortfolio] = useState<any>();
    const [sortPagePortfolio, setSortPagePortfolio] = useState<any[]>([]); // список страниц по теме
    const [listPatternPortfolio, setListPatternPortfolio] = useState<any[]>([]); //ыбранная страница

    const [formAddPage, setFormAddPage] = useState<string>(''); // Значение для открытия какой формы добавлени
    const [openForm, setOpenForm] = useState<boolean>(true); // Открыть или закрыть форму добавления

    const [foundAccount, setFoundAccount] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getGroups([new Date(), new Date()])).then()
        dispatch(getChildren()).then()
    }, [dispatch]);

    useEffect(() => {
        const account = children?.find(account => account.account_uid === portfolio?.child_uid);
        setFoundAccount(!!account);
    }, [portfolio, children])

    function selectAddForm(name: string) {
        const addForm = {
            "": <></>,
            //"title1": <EditTitlePage addPerentsPortfolioPageTitle={formAddPage} setAddPerentsPortfolioPageTitle={(value) => (setFormAddPage(value))} openForm={openForm} setOpenForm={(value) => (setOpenForm(value))}/>,
            //"helloForm2": <EditTitlePage addPerentsPortfolioPageTitle={formAddPage} setAddPerentsPortfolioPageTitle={(value) => (setFormAddPage(value))} openForm={openForm} setOpenForm={(value) => (setOpenForm(value))}/>,
            //"aboutMe3": <EditTitlePage addPerentsPortfolioPageTitle={formAddPage} setAddPerentsPortfolioPageTitle={(value) => (setFormAddPage(value))} openForm={openForm} setOpenForm={(value) => (setOpenForm(value))}/>,

        }
        return addForm[name as keyof typeof addForm];
    }

    useEffect(() => { //ПОЛУЧЕНИЕ ПОРТФОЛИО И КОНТРУКТОРА
        AxiosClient.get('all/parent/portfolioforid', { params: { id: id } })
            .then(r => {
                // console.log(r.data);
                setPortfolio(r.data);
                setSelectPatternPortfolio( {value: r.data.pattern_page_portfolio_id, label: r.data.type_pattern_portfolio.name})
                setSelectThemePortfolio({value: '1', label: 'Титульник'});
                if (selectThemePortfolio) {
                    setSortPagePortfolio(r.data?.portfolio_pages.filter((value: any) => value.type_page_portfolio_id === selectThemePortfolio.value));
                }
            })
            .catch(error => { console.error("Ошибка при загрузке портфолио:", error) });
        AxiosClient.get('type_all/parent/portfolio', {params: {}})
            .then(r => {
                setTypePortfolio(r.data)
            })
            .catch(() => console.log('error'))
        AxiosClient.get('pattern_all/parent/portfolio')
            .then(r => { setListPatternPortfolio(r.data) })
            .catch(error => { console.error("Ошибка при загрузке названий шаблонов:", error) });
    }, [id])

    useEffect(() => { // СОРТИРОВКА ДЛЯ СПИСКА СТРАНИЦ
        if (portfolio && selectThemePortfolio.label === 'Титульник') {
            setSortPagePortfolio([{
                content: { last_name: portfolio?.last_name, middle_name: portfolio?.middle_name, name: portfolio?.name },
                created_at: portfolio?.created_at,
                id: 0,
                photo: '',
                portfolio_child_id: portfolio?.id,
                public: 1,
                type_page_portfolio_id: 1,
                type_page_portfolio: { name: 'Титульник' },
                updated_at: ''
            }]);
        } else if (portfolio && portfolio.portfolio_pages) {
            setSortPagePortfolio(portfolio.portfolio_pages.filter((value: any) => value.type_page_portfolio.theme_page_portfolio_id === selectThemePortfolio.value));
        }
    }, [selectThemePortfolio, portfolio]);


    return (
        <div className="page-content">
            <Container fluid>
                <Row sm={5} lg={3} xl={3} xxl={2} className="mt-2 mt-sm-0 mt-lg-0 mt-xl-0 mt-xxl-0">
                    <label className="w-100">Шаблон:
                        <Select
                            options = {listPatternPortfolio.length > 0
                                ? [...listPatternPortfolio.map((v: any) => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                            value={selectPatternPortfolio}
                            onChange={(change: any) => {
                                setSelectPatternPortfolio({ value: change.value, label: change.label })
                                setShowModalConfirmPattern(true)
                            }}
                            styles={customStyles}
                            placeholder="Поиск"
                            name="name"
                            noOptionsMessage={() => "Нет шаблонов"}
                        />
                    </label>
                </Row>
                <Row>
                    <Col lg={3} className="col">
                        <Row>
                            <MenuThemePortfolio idPortfolio={id} selectThemePortfolio={selectThemePortfolio} setSelectThemePortfolio={(value: any) => setSelectThemePortfolio(value)}></MenuThemePortfolio>
                        </Row>
                    </Col>
                    <Col className="col">
                        <Row>
                            <ListPagesPortfolio foundAccount={foundAccount} setPortfolio={(value) => setPortfolio(value)} idPortfolio={id} sortPagePortfolio={sortPagePortfolio} setSelectPagePortfolio={(value) => setSelectPagePortfolio(value)} setSelectShowPagePortfolio={(value) => setSelectShowPagePortfolio(value)} setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}></ListPagesPortfolio>
                        </Row>
                        {(selectThemePortfolio.label != 'Титульник' && foundAccount) && <ChoiceAddTypePortfolioPage portfolio={portfolio} setPortfolio={(value) => setPortfolio(value)} typePortfolio={typePortfolio} selectThemePortfolio={selectThemePortfolio} setFormAddPage={(value: any) => (setFormAddPage(value))} setOpenForm={(value: boolean) => (setOpenForm(value))}/>}
                        {(selectPagePortfolio?.type_page_portfolio.name === 'Титульник' && selectShowPagePortfolio  ) &&
                        <Row>
                            <Col>
                                <div>
                                    <Card className="team-box">
                                        <CardBody className="p-4">
                                            {portfolio?.photo_page &&
                                                <img
                                                    src={process.env.REACT_APP_API_BASE_URL+ portfolio?.photo_page}
                                                    className="img-thumbnail user-profile-image"
                                                    alt="page"/>
                                                // : <p>Внесите и сохраните фамилию, имя и фотографию ребенка</p>
                                            }

                                        </CardBody>
                                    </Card>
                                </div>
                            </Col>
                        </Row>}
                        {(selectPagePortfolio?.type_page_portfolio.name != 'Титульник' && selectShowPagePortfolio  ) &&
                            <Row>
                                <Col>
                                    <div>
                                        <Card className="team-box">
                                            <CardBody className="p-4">
                                                {selectPagePortfolio?.photo_page &&
                                                    <img
                                                        src={process.env.REACT_APP_API_BASE_URL+ selectPagePortfolio?.photo_page}
                                                        className="img-thumbnail user-profile-image"
                                                        alt="page"/>
                                                }
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Col>
                            </Row>}



                    </Col>
                </Row>
                {selectAddForm(formAddPage)}
                {((selectPagePortfolio?.type_page_portfolio.name === 'Приветственная форма') && selectEditPagePortfolio)? <EditAboutMePortfolio portfolio={portfolio} setPortfolio={(value) => setPortfolio(value)} selectPagePortfolio={selectPagePortfolio} selectEditPagePortfolio={selectEditPagePortfolio} setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}></EditAboutMePortfolio> : <></>}
                {((selectPagePortfolio?.type_page_portfolio.name === 'Обо мне' ||
                    selectPagePortfolio?.type_page_portfolio.name === 'Мой знак зодиак' ||
                    selectPagePortfolio?.type_page_portfolio.name === 'Тайна имени (описание)' ||
                    selectPagePortfolio?.type_page_portfolio.name === 'Когда я вырасту (описание)' ||
                    selectPagePortfolio?.type_page_portfolio.name === 'Обо мне мама' ||
                    selectPagePortfolio?.type_page_portfolio.name === 'Обо мне папа' ||
                    selectPagePortfolio?.type_page_portfolio.name === 'Мои увлечения (описание)' ||
                    selectPagePortfolio?.type_page_portfolio.name === 'Мои любимые книги'||
                    selectPagePortfolio?.type_page_portfolio.name === 'Мой режим дня'
                ) && selectEditPagePortfolio)? <EditBigDescriptionPage portfolio={portfolio} setPortfolio={(value) => setPortfolio(value)} selectPagePortfolio={selectPagePortfolio} selectEditPagePortfolio={selectEditPagePortfolio} setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}></EditBigDescriptionPage> : <></>}
                {((selectPagePortfolio?.type_page_portfolio.name === 'Обо мне воспитатель' ||
                    selectPagePortfolio?.type_page_portfolio.name === 'Моя маленькая родина (описание)' ||
                    selectPagePortfolio?.type_page_portfolio.name === 'Во что я играю (описание)'||
                    selectPagePortfolio?.type_page_portfolio.name === 'Мои любимые мультики'
                ) && selectEditPagePortfolio)? <EditBigDescriptionPageOnTwoRow portfolio={portfolio} setPortfolio={(value) => setPortfolio(value)} selectPagePortfolio={selectPagePortfolio} selectEditPagePortfolio={selectEditPagePortfolio} setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}></EditBigDescriptionPageOnTwoRow> : <></>}
                {((selectPagePortfolio?.type_page_portfolio.name === 'Тайна имени (1 фото)' ||
                    selectPagePortfolio?.type_page_portfolio.name === 'Когда я вырасту (1 фото)' ||
                    selectPagePortfolio?.type_page_portfolio.name === 'Мои увлечения (1 фото)'
                ) && selectEditPagePortfolio)? <EditOnePhotoLeftAndDescription portfolio={portfolio} setPortfolio={(value) => setPortfolio(value)} selectPagePortfolio={selectPagePortfolio} selectEditPagePortfolio={selectEditPagePortfolio} setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}></EditOnePhotoLeftAndDescription> : <></>}
                {((selectPagePortfolio?.type_page_portfolio.name === 'Моя маленькая родина (1 фото)' ||
                    selectPagePortfolio?.type_page_portfolio.name === 'Во что я играю (1 фото)'
                ) && selectEditPagePortfolio)? <EditOnePhotoRightAndDescriptionOnTwoRow portfolio={portfolio} setPortfolio={(value) => setPortfolio(value)} selectPagePortfolio={selectPagePortfolio} selectEditPagePortfolio={selectEditPagePortfolio} setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}></EditOnePhotoRightAndDescriptionOnTwoRow> : <></>}
                {((selectPagePortfolio?.type_page_portfolio.name === 'Моё творчество (1 фото)' ||
                    selectPagePortfolio?.type_page_portfolio.name === 'Мои достижения (1 фото)'
                ) && selectEditPagePortfolio)? <EditAdvanceOrCreationOnePhoto portfolio={portfolio} setPortfolio={(value) => setPortfolio(value)} selectPagePortfolio={selectPagePortfolio} selectEditPagePortfolio={selectEditPagePortfolio} setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}></EditAdvanceOrCreationOnePhoto> : <></>}

                {((selectPagePortfolio?.type_page_portfolio.name === 'Моя группа (1 фото)'
                ) && selectEditPagePortfolio)? <EditMyGroupOverall portfolio={portfolio} setPortfolio={(value) => setPortfolio(value)} selectPagePortfolio={selectPagePortfolio} selectEditPagePortfolio={selectEditPagePortfolio} setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}></EditMyGroupOverall> : <></>}

                {((selectPagePortfolio?.type_page_portfolio.name === 'Мои друзья'
                ) && selectEditPagePortfolio)? <EditMyFriends portfolio={portfolio} setPortfolio={(value) => setPortfolio(value)} selectPagePortfolio={selectPagePortfolio} selectEditPagePortfolio={selectEditPagePortfolio} setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}></EditMyFriends> : <></>}
                {((selectPagePortfolio?.type_page_portfolio.name === 'Где я путешествовал (3 фото)'
                ) && selectEditPagePortfolio)? <EditTravelThreePhoto portfolio={portfolio} setPortfolio={(value) => setPortfolio(value)} selectPagePortfolio={selectPagePortfolio} selectEditPagePortfolio={selectEditPagePortfolio} setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}></EditTravelThreePhoto> : <></>}

                {((selectPagePortfolio?.type_page_portfolio.name === 'Мой воспитатель' || selectPagePortfolio?.type_page_portfolio.name === 'Мой папа' || selectPagePortfolio?.type_page_portfolio.name === 'Моя мама' || selectPagePortfolio?.type_page_portfolio.name === 'Мой брат' || selectPagePortfolio?.type_page_portfolio.name === 'Моя сестра') && selectEditPagePortfolio)? <EditMyFamily portfolio={portfolio} setPortfolio={(value) => setPortfolio(value)} selectPagePortfolio={selectPagePortfolio} selectEditPagePortfolio={selectEditPagePortfolio} setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}></EditMyFamily> : <></>}
                {((selectPagePortfolio?.type_page_portfolio.name === 'Мой детский сад') && selectEditPagePortfolio)? <EditMyKindergarten portfolio={portfolio} setPortfolio={(value) => setPortfolio(value)} selectPagePortfolio={selectPagePortfolio} selectEditPagePortfolio={selectEditPagePortfolio} setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}></EditMyKindergarten> : <></>}
                {((selectPagePortfolio?.type_page_portfolio.name === 'Мое родословное дерево') && selectEditPagePortfolio)? <EditTreeFamily portfolio={portfolio} setPortfolio={(value) => setPortfolio(value)} selectPagePortfolio={selectPagePortfolio} selectEditPagePortfolio={selectEditPagePortfolio} setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}></EditTreeFamily> : <></>}
                {((selectPagePortfolio?.type_page_portfolio.name === 'Мои достижения' || selectPagePortfolio?.type_page_portfolio.name === 'Моё творчество') && selectEditPagePortfolio)? <EditAdvanceOrCreationOnePhoto portfolio={portfolio} setPortfolio={(value) => setPortfolio(value)} selectPagePortfolio={selectPagePortfolio} selectEditPagePortfolio={selectEditPagePortfolio} setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}></EditAdvanceOrCreationOnePhoto> : <></>}
                {(selectPagePortfolio?.type_page_portfolio.name === 'Титульник' && selectEditPagePortfolio)? <EditTitlePage portfolio={portfolio} setPortfolio={(value) => setPortfolio(value)} selectPagePortfolio={selectPagePortfolio} selectEditPagePortfolio={selectEditPagePortfolio} setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}></EditTitlePage> : <></>}
                {((selectPagePortfolio?.type_page_portfolio.name === 'Разное (описание)') && selectEditPagePortfolio)? <EditOtherDescription portfolio={portfolio} setPortfolio={(value) => setPortfolio(value)} selectPagePortfolio={selectPagePortfolio} selectEditPagePortfolio={selectEditPagePortfolio} setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}></EditOtherDescription> : <></>}
            </Container>
            <ModalConfirmPattern idPattern={selectPatternPortfolio.value} idPortfolio={id} showModalConfirmPattern={showModalConfirmPattern} setShowModalConfirmPattern={(value) => setShowModalConfirmPattern(value)} portfolio={portfolio} setPortfolio={(value) => setPortfolio(value)}/>
        </div>
    );
};

export default PerentPortfolioMore;
