import React, {useEffect, useRef} from 'react';
import {useParams} from "react-router-dom";
import {Container} from "reactstrap";
import {Helmet} from "react-helmet";

const GameUpdate = () => {
    const {id} = useParams()
    const ref = useRef<any>(null);

    useEffect(() => {

    }, [id]);

    return (
        <div className="page-content" style={{height: '100vh'}}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Обновление игры</title>
            </Helmet>

        </div>
    );
};

export default GameUpdate;
