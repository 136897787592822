import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import AxiosClient from "../../../api/AxiosClient";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";
import {Card, CardBody, CardHeader, Col, Label, Nav, NavItem, NavLink, Row} from "reactstrap";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import Notification from "../../widgets/Notification";

interface IAddPatternPortfolio {
    selectPattern: any
}

const AddPatternPortfolio : FC <IAddPatternPortfolio> = ({selectPattern}) => {
    const dispatch = useAppDispatch();
    const {pages} = useAppSelector(state => state.AdminReducer)
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={
                    {
                        name: selectPattern.name,
                        themeId: selectPattern.theme_page_portfolio_id,
                    }
                }
                validationSchema={
                    Yup.object(
                        {
                            name: Yup.string().required('Введите название темы'),
                        }
                    )
                }
                onSubmit={
                    (value, {resetForm}) => {
                        dispatch(setIsSend(true));
                        if(selectPattern.id) {
                            AxiosClient.post(`update/name/pattern/parent/portfolio/${selectPattern.id}`, { name: value.name})
                                .then(r => {
                                    setNotificationType('success');
                                    setNotificationMessage('Готово, тип страницы изменен!');
                                    setShowNotification(true);
                                    dispatch(setIsSend(false));
                                })
                                .catch(() => {
                                    console.log('error');
                                    setNotificationType('error');
                                    setNotificationMessage('Ошибка, проверьте корректность данных!');
                                    setShowNotification(true);
                                    dispatch(setIsSend(false));
                                });
                        }else {
                            AxiosClient.post('create/pattern/parent/portfolio', { name: value.name})
                                .then(r => {
                                    setNotificationType('success');
                                    setNotificationMessage('Готово, тип страницы добавлен!');
                                    setShowNotification(true);
                                    dispatch(setIsSend(false));
                                })
                                .catch(() => {
                                    console.log('error');
                                    setNotificationType('error');
                                    setNotificationMessage('Ошибка, проверьте корректность данных!');
                                    setShowNotification(true);
                                    dispatch(setIsSend(false));
                                });
                        }
                    }
                }
            >
                {({ values, setFieldValue }) => (
                    <Form encType="multipart/form-data">
                        <Card className="mx-n3">
                            <CardHeader>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                     role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className="active">
                                            <i className="fas fa-home"></i>
                                            Данные о шаблоне
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <CardBody className="p-4">
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="themeInput" className="form-label">Название шаблона</Label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                id="themeInput"
                                                name="name"
                                                value={values.name}
                                                onChange={(e: any) => setFieldValue('name', e.target.value)}
                                            />
                                            <ErrorMessage name="name">{(msg) => <div
                                                className="text-danger">{msg}</div>}</ErrorMessage>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} className="mt-3">
                                        <div className="hstack gap-2 justify-content-end">
                                            <SubmitWithLoading text="Сохранить" />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Form>
                )}
            </Formik>
            {showNotification && <Notification type={notificationType} message={notificationMessage} setShowNotification={(value: boolean) => setShowNotification(value)}/>}
        </>

    );
};

export default AddPatternPortfolio;
