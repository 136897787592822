import React, {FC, useCallback, useEffect, useState} from 'react';
import {useAppDispatch} from "../../../../hooks/redux";
import userDummayImage from "../../../../assets/images/users/user-dummy-img.jpg";
import ReactCrop, {Crop, PixelCrop} from "react-image-crop";
import {Col, Input, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import {setIsSend} from "../../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../../layouts/UI/SubmitWithLoading";
import MyFriends from "../portfolioPage/MyFriends";
import TravelThreePhoto from "../portfolioPage/TravelThreePhoto";
import ModalCropPhoto from "../ModalCropPhoto";

interface IEditTravelThreePhoto{
    portfolio: any,
    setPortfolio(value: any): any,
    selectPagePortfolio: any,
    selectEditPagePortfolio: boolean,
    setSelectEditPagePortfolio(value: boolean): any
}

const EditTravelThreePhoto: FC<IEditTravelThreePhoto> = ({ portfolio, setPortfolio, selectEditPagePortfolio, setSelectEditPagePortfolio, selectPagePortfolio }) => {
    const dispatch = useAppDispatch();
    const [selectModalCropPhoto, setSelectModalCropPhoto]  = useState<boolean>(false);
    const [isDownloadPortfolioPage, setIsDownloadPortfolioPage] = useState<boolean>(false);
    const [isSelectNumberDownloadPortfolioPage, setIsSelectNumberDownloadPortfolioPage] = useState<number>(0);

    const [page, setPage] = useState<any>();
    const [content, setContent] = useState(JSON.parse(selectPagePortfolio?.content));
    const [photoPathList, setPhotoPathList] = useState<any[]>(JSON.parse(selectPagePortfolio.photo.replace(/'/g, '"')));
    const [photoListBase64, setPhotoListBase64] = useState<any[]>(selectPagePortfolio.photoBase64);

    const [pathPhoto, setPathPhoto] = useState(photoPathList?.[0] ? process.env.REACT_APP_API_BASE_URL + photoPathList?.[0] : userDummayImage); //filePhoto, setFilePhoto
    const [downloadPhoto, setDownloadPhoto] = useState<Blob | null>(); //fileForm, setFileForm
    const [downloadPhoto64, setDownloadPhoto64] = useState<string | ArrayBuffer | null>(); //fileForm64, setFileForm64
    const [crop, setCrop] = useState<Crop>();
    const [cropPath, setCropPath] = useState<any>();
    const [croppedImage, setCroppedImage] = useState<string | null>(null);

    const [pathPhoto2, setPathPhoto2] = useState(photoPathList?.[1] ? process.env.REACT_APP_API_BASE_URL + photoPathList?.[1] : userDummayImage); //filePhoto, setFilePhoto
    const [downloadPhoto2, setDownloadPhoto2] = useState<Blob | null>(); //fileForm, setFileForm
    const [downloadPhoto642, setDownloadPhoto642] = useState<string | ArrayBuffer | null>(); //fileForm64, setFileForm64
    const [crop2, setCrop2] = useState<Crop>();
    const [cropPath2, setCropPath2] = useState<any>();
    const [croppedImage2, setCroppedImage2] = useState<string | null>(null);

    const [pathPhoto3, setPathPhoto3] = useState(photoPathList?.[2] ? process.env.REACT_APP_API_BASE_URL + photoPathList?.[2] : userDummayImage); //filePhoto, setFilePhoto
    const [downloadPhoto3, setDownloadPhoto3] = useState<Blob | null>(); //fileForm, setFileForm
    const [downloadPhoto643, setDownloadPhoto643] = useState<string | ArrayBuffer | null>(); //fileForm64, setFileForm64
    const [crop3, setCrop3] = useState<Crop>();
    const [cropPath3, setCropPath3] = useState<any>();
    const [croppedImage3, setCroppedImage3] = useState<string | null>(null);

    const [isEditPortfolioPage, setIsEditPortfolioPage] = useState<boolean>(false);


    const handleImageClick = (numberPhoto : number) => {
        if (isDownloadPortfolioPage) {
            if (numberPhoto == 1 ) document.getElementById('inputForFileTravelThreePhoto1')?.click();
            else if (numberPhoto == 2 ) document.getElementById('inputForFileTravelThreePhoto2')?.click();
            else if (numberPhoto == 3 ) document.getElementById('inputForFileTravelThreePhoto3')?.click();
            setIsDownloadPortfolioPage(false);
        }
    };

    useEffect(() => {
        if (isEditPortfolioPage) {
            document.getElementById('handleCombineImagesTravelThreePhoto')?.click();
            setIsEditPortfolioPage(false);
        }
    }, [isEditPortfolioPage]);

    return (
        <>
            <Modal id="createProjectModal" isOpen={selectEditPagePortfolio}
                   toggle={() => setSelectEditPagePortfolio(!selectEditPagePortfolio)}
                   modalClassName="zoomIn"  size="lg"  tabIndex={-1} centered >
                <ModalHeader toggle={() => setSelectEditPagePortfolio(!selectEditPagePortfolio)}
                             className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Изменение страницы «{selectPagePortfolio?.type_page_portfolio.name}»
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            name1: content.name1,
                            name2: content.name2,
                            name3: content.name3,
                            photo_download: downloadPhoto64 ? downloadPhoto64 : `data:image/png;base64,${portfolio?.photoBase64}`,
                        }}
                        onSubmit={(values) => {
                            dispatch(setIsSend(true));
                            setPage({
                                name1: values.name1,
                                name2: values.name2,
                                name3: values.name3,

                                uid: portfolio?.child_uid,
                                selectPage: selectPagePortfolio,
                                id_pattern: portfolio?.pattern_page_portfolio_id,
                                photo: downloadPhoto,
                                photo2: downloadPhoto2,
                                photo3: downloadPhoto3,
                                photo_download_base64: photoListBase64,
                                path_photo: photoPathList,
                                path_photo_page: selectPagePortfolio?.photo_page,
                            });
                            setIsEditPortfolioPage(true);
                        }}
                    >
                        {({ errors, touched, setFieldValue }) => (
                            <Form>
                                <Row>
                                    <Col>
                                        <Row className="mb-3">
                                            <Col>
                                                <label className="w-50">Загрузить фото №1:
                                                    <img
                                                        src={cropPath ? cropPath : pathPhoto}
                                                        alt="Загрузить фото"
                                                        className="user-profile-image w-100 h-auto"
                                                        onClick={() => {
                                                            handleImageClick(1)
                                                            setIsSelectNumberDownloadPortfolioPage(1)
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                    <Input
                                                        name="photo"
                                                        type="file"
                                                        id="inputForFileTravelThreePhoto1"
                                                        className={`form-control d-none`}
                                                        accept=".png, .jpg"
                                                        onChange={(e: any) => {
                                                            const file = e.target.files[0];

                                                            if (file) {
                                                                const reader = new FileReader();
                                                                reader.onloadend = () => {
                                                                    setDownloadPhoto64(reader.result);
                                                                    setPhotoListBase64((prevArrList: any) => {
                                                                        const newArrList : any = [...prevArrList];
                                                                        newArrList[0] = reader.result;
                                                                        return newArrList;
                                                                    });
                                                                    setPathPhoto(reader.result as string);
                                                                };
                                                                reader.readAsDataURL(file);
                                                                setSelectModalCropPhoto(true)
                                                            }
                                                        }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <label className="w-100">
                                                    Подпись к фото №1:
                                                    <Field name="name1" className={`form-control ${touched.name1 && errors.name1 ? 'is-invalid' : ''}`} />
                                                </label>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row className="mb-3">
                                            <Col>
                                                <label className="w-50">Загрузить фото №2:
                                                    <img
                                                        src={cropPath2 ? cropPath2 : pathPhoto2}
                                                        alt="Загрузить фото"
                                                        className="user-profile-image w-100 h-auto"
                                                        onClick={() => {
                                                            handleImageClick(2)
                                                            setIsSelectNumberDownloadPortfolioPage(2)
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                    <Input
                                                        name="photo"
                                                        type="file"
                                                        id="inputForFileTravelThreePhoto2"
                                                        className={`form-control d-none`}
                                                        accept=".png, .jpg"
                                                        onChange={(e: any) => {
                                                            const file = e.target.files[0];

                                                            if (file) {
                                                                const reader = new FileReader();
                                                                reader.onloadend = () => {
                                                                    setDownloadPhoto642(reader.result);
                                                                    setPhotoListBase64((prevArrList: any) => {
                                                                        const newArrList : any = [...prevArrList];
                                                                        newArrList[1] = reader.result;
                                                                        return newArrList;
                                                                    });
                                                                    setPathPhoto2(reader.result as string);
                                                                };
                                                                reader.readAsDataURL(file);
                                                                setSelectModalCropPhoto(true)
                                                            }
                                                        }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <label className="w-100">
                                                    Подпись к фото №2:
                                                    <Field name="name2" className={`form-control ${touched.name2 && errors.name2 ? 'is-invalid' : ''}`} />
                                                </label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row className="mb-3">
                                            <Col>
                                                <label className="w-50">Загрузить фото №3:
                                                    <img
                                                        src={cropPath3 ? cropPath3 : pathPhoto3}
                                                        alt="Загрузить фото"
                                                        className="user-profile-image w-100 h-auto"
                                                        onClick={() => {
                                                            handleImageClick(3)
                                                            setIsSelectNumberDownloadPortfolioPage(3)
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                    <Input
                                                        name="photo"
                                                        type="file"
                                                        id="inputForFileTravelThreePhoto3"
                                                        className={`form-control d-none`}
                                                        accept=".png, .jpg"
                                                        onChange={(e: any) => {
                                                            const file = e.target.files[0];

                                                            if (file) {
                                                                const reader = new FileReader();
                                                                reader.onloadend = () => {
                                                                    setDownloadPhoto643(reader.result);
                                                                    setPhotoListBase64((prevArrList: any) => {
                                                                        const newArrList : any = [...prevArrList];
                                                                        newArrList[2] = reader.result;
                                                                        return newArrList;
                                                                    });
                                                                    setPathPhoto3(reader.result as string);
                                                                };
                                                                reader.readAsDataURL(file);
                                                                setSelectModalCropPhoto(true)
                                                            }
                                                        }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <label className="w-100">
                                                    Подпись к фото №3:
                                                    <Field name="name3" className={`form-control ${touched.name3 && errors.name3 ? 'is-invalid' : ''}`} />
                                                </label>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                    </Col>
                                </Row>
                                <div className="hstack gap-2">
                                    <SubmitWithLoading text={'Готово'} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
            <TravelThreePhoto
                dispatch={(value) => dispatch(value)}
                page={page}
                setPortfolio={(value) => setPortfolio(value)}
                setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}
            />

            { isSelectNumberDownloadPortfolioPage === 1 &&
                <ModalCropPhoto portfolio={portfolio} setPortfolio={(value: any) => setPortfolio(value)}
                                selectPagePortfolio={selectPagePortfolio}
                                selectModalCropPhoto={selectModalCropPhoto} setSelectModalCropPhoto={(value: boolean) => setSelectModalCropPhoto(value)}
                                photoPathList={photoPathList} setPhotoListBase64={(value: any) => setPhotoListBase64(value)}
                                pathPhoto={pathPhoto} setDownloadPhoto={(value: any) => setDownloadPhoto(value)}
                                downloadPhoto64={downloadPhoto64} setDownloadPhoto64={(value: any) => setDownloadPhoto64(value)}
                                cropPath={cropPath} setCropPath={(value: any) => setCropPath(value)} indexPhoto={0}
                                relationsParties={2/3} croppedImage={croppedImage} />
            }
            { isSelectNumberDownloadPortfolioPage === 2 &&
                <ModalCropPhoto portfolio={portfolio} setPortfolio={(value: any) => setPortfolio(value)}
                                selectPagePortfolio={selectPagePortfolio}
                                selectModalCropPhoto={selectModalCropPhoto} setSelectModalCropPhoto={(value: boolean) => setSelectModalCropPhoto(value)}
                                photoPathList={photoPathList} setPhotoListBase64={(value: any) => setPhotoListBase64(value)}
                                pathPhoto={pathPhoto2} setDownloadPhoto={(value: any) => setDownloadPhoto2(value)}
                                downloadPhoto64={downloadPhoto642} setDownloadPhoto64={(value: any) => setDownloadPhoto642(value)}
                                cropPath={cropPath2} setCropPath={(value: any) => setCropPath2(value)} indexPhoto={1}
                                relationsParties={2/3} croppedImage={croppedImage} />
            }
            { isSelectNumberDownloadPortfolioPage === 3 &&
                <ModalCropPhoto portfolio={portfolio} setPortfolio={(value: any) => setPortfolio(value)}
                                selectPagePortfolio={selectPagePortfolio}
                                selectModalCropPhoto={selectModalCropPhoto} setSelectModalCropPhoto={(value: boolean) => setSelectModalCropPhoto(value)}
                                photoPathList={photoPathList} setPhotoListBase64={(value: any) => setPhotoListBase64(value)}
                                pathPhoto={pathPhoto3} setDownloadPhoto={(value: any) => setDownloadPhoto3(value)}
                                downloadPhoto64={downloadPhoto643} setDownloadPhoto64={(value: any) => setDownloadPhoto643(value)}
                                cropPath={cropPath3} setCropPath={(value: any) => setCropPath3(value)} indexPhoto={2}
                                relationsParties={2/3} croppedImage={croppedImage} />
            }

        </>

    );
};

export default EditTravelThreePhoto;
