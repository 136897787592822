import React, {FC} from 'react';
import {Button, Col, ListGroup, ListGroupItem, Row} from "reactstrap";
import {ITexture} from "../../../../models/game/ITexture";

interface IProps {
    textures: ITexture[],
    removeTexture: (id: number) => void,
    onChangeSelectTexture: (texture: ITexture) => void
}

const TextureList: FC<IProps> = ({textures, removeTexture, onChangeSelectTexture}) => {
    return (
        <ListGroup>
            {textures.map((texture, index) => (
                <ListGroupItem key={index} className="d-flex justify-content-between align-items-center">
                    <Row className="w-100">
                        <Col xs="2" className="d-flex justify-content-center align-items-center">
                            <img
                                src={process.env.REACT_APP_API_BASE_URL + texture.path}
                                alt={texture.name}
                                style={{width: '50px', height: '50px', objectFit: 'cover'}}
                            />
                        </Col>
                        <Col xs="4" className="d-flex align-items-center">
                            <span className="fs-5">{texture.name}</span>
                        </Col>
                        <Col xs="4" className="d-flex align-items-center">
                            <span className="fs-5">{texture.category_texture ? texture.category_texture.name : 'Нет категории'}</span>
                        </Col>
                        <Col xs="1" className="d-flex justify-content-center">
                            <Button color="info" onClick={() => onChangeSelectTexture(texture)} className="rounded-circle">
                                <i className="bx bx-pencil fs-3"></i>
                            </Button>
                        </Col>
                        <Col xs="1" className="d-flex justify-content-center">
                            <Button color="danger" onClick={() => removeTexture(texture.id)} className="rounded-circle">
                                <i className="bx bx-trash fs-3"></i>
                            </Button>
                        </Col>
                    </Row>
                </ListGroupItem>
            ))}
        </ListGroup>
    );
};

export default TextureList;
