import React, {FC} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {
    Col,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";
import * as Yup from 'yup'
import {ITexture} from "../../../../models/game/ITexture";

interface IProps {
    isUpdateTexture: boolean,
    toggleIsUpdateTexture: () => void,
    updateTexture: (id:number, data: {name: string, category_texture_id: number | null}) => void
    selectTexture: ITexture | null
    categoryTextures: {id: number, name: string}[]
}
const ModalTextureUpdate: FC<IProps> = ({categoryTextures, toggleIsUpdateTexture, isUpdateTexture, updateTexture, selectTexture}) => {
    return (
        <>
            <Modal isOpen={isUpdateTexture} toggle={toggleIsUpdateTexture} centered>
                <ModalHeader toggle={toggleIsUpdateTexture}>Обновление текстуры</ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={
                            {
                                name: selectTexture?.name ?? '',
                                category_texture_id: selectTexture?.category_texture_id ? selectTexture.category_texture_id : 'null' ?? null
                            }
                        }
                        validationSchema={
                            Yup.object(
                                {
                                    name: Yup.string().required('Введите название заднего фона'),
                                }
                            )
                        }
                        onSubmit={
                            (values, {resetForm}) => {
                                if (selectTexture) {
                                    updateTexture(selectTexture.id, {name: values.name, category_texture_id: values.category_texture_id === 'null' ? null : +values.category_texture_id})
                                    resetForm()
                                }
                            }
                        }
                    >
                        <Form>
                            <Row>
                                <Col>
                                    <div className="mb-3">
                                        <Label htmlFor="categoryInput"
                                               className="form-label">Категория</Label>
                                        <Field as="select" className="form-control" id="categoryInput"
                                               name="category_texture_id">
                                            <option value={'null'}>Без категории</option>
                                            {
                                                categoryTextures.map((el) => (
                                                    <option key={el.id} value={el.id}>{el.name}</option>
                                                ))
                                            }
                                        </Field>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="mb-3">
                                        <Label htmlFor="nameInput"
                                               className="form-label">Имя текстуры</Label>
                                        <Field type="text" className="form-control" id="nameInput"
                                               name="name"
                                               placeholder="Введите имя текстуры"/>
                                        <ErrorMessage name="name">{(msg) => <div
                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} className="mt-3">
                                    <div className="hstack gap-2 justify-content-end">
                                        <button type="submit"
                                                className="btn btn-primary">Обновить
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    );
};

export default ModalTextureUpdate;
