import React, {FC, useState} from 'react';
import {Card, CardBody, Col, Input, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {setIsSend} from "../../store/reducers/widgets/WidgetsSlice";
import AxiosClient from "../../api/AxiosClient";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import Select from "react-select";
import {customStyles} from "../settings";
import SubmitWithLoading from "../layouts/UI/SubmitWithLoading";
import moment from "moment/moment";
import {useStateContext} from "../../hooks/useStateContext";
import {useAppDispatch} from "../../hooks/redux";
import Notification from "../widgets/Notification";
import NewsDocs from "../news/NewsDocs";
import IconFile from "../news/IconFile";
import {Link} from "react-router-dom";

interface IFormAddFeedback {
    selectFeedback: any,
    showFeedback: boolean,
    setShowFeedback(value: boolean) : any,
}

const FormAddFeedback: FC <IFormAddFeedback> = ({selectFeedback, showFeedback, setShowFeedback}) => {
    const dispatch = useAppDispatch();
    const {user} = useStateContext();
    const [dateRecord, setDateRecord] = useState(selectFeedback.created_at);
    const [dateDecision, setDateDecision] = useState(moment().startOf('day').toDate());
    const [files, setFiles] = useState<File[]>([]);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFiles(Array.from(e.target.files));
        }
    };

    return (
        <>
            <Modal id="createProjectModal" isOpen={showFeedback}
                   toggle={() => setShowFeedback(!showFeedback)}
                   modalClassName="zoomIn modal-xl" tabIndex={-1} centered>
                <ModalHeader toggle={() => setShowFeedback(!showFeedback)}
                             className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Добавить обращение
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={
                            {
                                dateRecord: dateRecord,
                                FIO: selectFeedback.FIO_perent,
                                short_description: selectFeedback.short_description,
                                description: selectFeedback.description,
                                file: '',
                                date_decision: dateDecision,
                                FIO_decision: selectFeedback.FIO_decision ?  selectFeedback.FIO_decision : (user.term.name != 'parent') ? user.last_name+" "+user.name +" "+ user.middle_name : '',
                                description_decision: selectFeedback.description_decision?  selectFeedback.description_decision : '',
                            }
                        }
                        validationSchema={
                            Yup.object({
                                description_decision: Yup.string().required('Обязательное поле'),
                            })
                        }
                        onSubmit={(values) => {
                            console.log()
                            // dispatch(setIsSend(true));
                            AxiosClient.post(`/feedback/update/${selectFeedback.id}`, {
                                uid_decision: user.user1c,
                                name: user.term.name,
                                FIO_decision: values.FIO_decision,
                                description_decision: values.description_decision
                            })
                                .then((r) => {
                                    setShowFeedback(false);
                                    setNotificationType('success');
                                    setNotificationMessage('Готово, ответ отправлен!');
                                    setShowNotification(true);
                                    dispatch(setIsSend(false))
                                })
                                .catch((error) => {
                                    console.log(error);
                                    dispatch(setIsSend(false))
                                    setNotificationType('error');
                                    setNotificationMessage('Ошибка, проверьте корректность данных!');
                                    setShowNotification(true);
                                })
                        }}>
                        {
                            ({errors, touched, setFieldValue}) => (
                                <Form>
                                    <Row>
                                    <Col>
                                        <h4>Обращение</h4>
                                        <Card>
                                            <CardBody>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100" htmlFor="dateRecord" >Дата обращения:
                                                    <Flatpickr
                                                        name="dateRecord"
                                                        id="dateRecord"
                                                        className="form-control border-0 dash-filter-picker shadow"
                                                        value={dateRecord}
                                                        options={{
                                                            locale: Russian,
                                                            dateFormat: "d.m.Y"
                                                        }}
                                                        onChange={ (date) => setDateRecord(date[0])}
                                                        disabled={true}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">
                                                    ФИО:
                                                    <Field
                                                        name="FIO"
                                                        className={`form-control`}
                                                        disabled={true}
                                                        onChange={(e: any) => {
                                                            setFieldValue('FIO', e.target.value);
                                                        }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">
                                                    Краткое описание:
                                                    <Field
                                                        name="short_description"
                                                        className={`form-control`}
                                                        maxLength={200}
                                                        disabled={true}
                                                        onChange={(e: any) => {
                                                            setFieldValue('short_description', e.target.value);
                                                        }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">
                                                    Подробнее о проблеме:
                                                    <Field
                                                        name="description"
                                                        as="textarea"
                                                        className={`form-control`}
                                                        rows={6}
                                                        maxLength={3000}
                                                        disabled={true}
                                                        onChange={(e: any) => {
                                                            setFieldValue('description', e.target.value);
                                                        }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <label className="w-100" htmlFor="file">Файлы (.jpg, .png, .pdf):
                                                    <Input
                                                        name="file"
                                                        id="file"
                                                        type="file"
                                                        className="mb-2"
                                                        accept=".jpg, .png, .pdf"
                                                        multiple
                                                        disabled={true}
                                                        onChange={handleFileChange}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                                <Row>
                                                    {JSON.parse(selectFeedback.file).map((file: string, index: number) => (
                                                        <NewsDocs
                                                            key={index}
                                                            url={{
                                                                url: file,
                                                                name: file.split('/').pop()!
                                                            }}
                                                        />
                                                    ))}
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                        <Col>
                                            <h4>Ответ</h4>
                                            <Card>
                                                <CardBody>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100" htmlFor="dateRecord" >Дата решения:
                                                    <Flatpickr
                                                        name="date_decision"
                                                        id="dateDecision"
                                                        className="form-control border-0 dash-filter-picker shadow"
                                                        value={dateDecision}
                                                        options={{
                                                            locale: Russian,
                                                            dateFormat: "d.m.Y"
                                                        }}
                                                        onChange={ (date) => setDateDecision(date[0])}
                                                        disabled={true}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">
                                                    ФИО:
                                                    <Field
                                                        name="FIO_decision"
                                                        className={`form-control`}
                                                        disabled={true}
                                                        onChange={(e: any) => {
                                                            setFieldValue('FIO', e.target.value);
                                                        }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">
                                                    Ответ:
                                                    <Field
                                                        name="description_decision"
                                                        as="textarea"
                                                        className={`form-control`}
                                                        rows={6}
                                                        maxLength={3000}
                                                        disabled={(user.term.name == 'parent') ? true: false}
                                                        onChange={(e: any) => {
                                                            setFieldValue('description_decision', e.target.value);
                                                        }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                                </CardBody>
                                            </Card>
                                    </Col>
                                    </Row>
                                    {(user.term.name != 'parent') &&
                                    <Row>
                                        <div className="hstack gap-2">
                                            <SubmitWithLoading text={'Ответить'}/>
                                        </div>
                                    </Row>}
                                </Form>
                            )
                        }
                    </Formik>
                </ModalBody>
            </Modal>
            {showNotification && <Notification type={notificationType} message={notificationMessage} setShowNotification={(value: boolean) => setShowNotification(value)}/>}
        </>
    );
};

export default FormAddFeedback;
